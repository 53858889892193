import React, {Fragment} from 'react'
import {Dialog, Transition} from "@headlessui/react";
import {useTranslation} from "react-i18next";
import {LiaTimesSolid} from "react-icons/lia";
import {FaExternalLinkAlt} from "react-icons/fa";
// import gate from "../../assets/img/gate.png";
import uniswap from "../../assets/img/uniswap.svg";
import mexc from "../../assets/img/mexc.svg";
import lbank from "../../assets/img/lbank.svg";

export default function BuyModal({
    isOpened,
    closeModal,
  }) {
  const {t} = useTranslation();

  return (
    <Transition appear show={isOpened} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-primary bg-opacity-60 backdrop-blur-sm"/>
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-3xl bg-gradient-to-b from-primary-modalBlue to-primary-customDark p-12 gap-5 flex flex-col text-left align-middle shadow-xl transition-all">
                <button onClick={closeModal} className="absolute top-8 right-10 font-bold rounded-2xl border-2 border-white border-opacity-15 p-4 hover:border-opacity-30">
                  <LiaTimesSolid size={30} color="white"/>
                </button>
                <Dialog.Title as="h1" className="text-5xl font-passionOne text-white bg-clip-text uppercase mb-10">
                  {t('Buy.BuyModal.Title')}
                </Dialog.Title>
                {/* <div className="flex items-center justify-between text-primary-white text-xl font-passionOne gap-4 bg-primary-white bg-opacity-5 h-20 py-7 px-9 rounded-2xl ring-1 ring-slate-50 ring-opacity-20">
                  <img src={gate} alt="gate.io" className="w-52" />
                  <a href="https://www.gate.io/" className="flex uppercase underline items-center justify-center gap-2" target="_blank">
                    Visit
                    <FaExternalLinkAlt size={16} />
                  </a>
                </div> */}
                <div className="flex items-center justify-between text-primary-white text-xl font-passionOne gap-4 bg-primary-white bg-opacity-5 h-20 py-7 px-9 rounded-2xl ring-1 ring-slate-50 ring-opacity-20">
                  <img src={mexc} alt="mexc" className="w-52" />
                  {/* <a href="https://www.mexc.com/fr-FR/register?inviteCode=mexc-12N2PH" className="flex uppercase underline items-center justify-center gap-2" target="_blank"> */}
                  <a href="https://www.mexc.com/exchange/MLC_USDT?inviteCode=mexc-12N2PH" className="flex uppercase underline items-center justify-center gap-2" target="_blank">
                    Visit
                    <FaExternalLinkAlt size={16} />
                  </a>
                </div>
                <div className="flex items-center justify-between text-primary-white text-xl font-passionOne gap-4 bg-primary-white bg-opacity-5 h-20 py-7 px-9 rounded-2xl ring-1 ring-slate-50 ring-opacity-20">
                  <img src={lbank} alt="lbank" className="w-52" />
                  <a href="https://www.lbank.com/trade/mlc_usdt?icode=3TH25" className="flex uppercase underline items-center justify-center gap-2" target="_blank">
                    Visit
                    <FaExternalLinkAlt size={16} />
                  </a>
                </div>
                <div className="flex items-center justify-between text-primary-white text-xl font-passionOne gap-4 bg-primary-white bg-opacity-5 h-20 py-7 px-9 rounded-2xl ring-1 ring-slate-50 ring-opacity-20">
                  <img src={uniswap} alt="uniswap" className="w-52" />
                  <a href="https://app.uniswap.org/explore/tokens/polygon/0x0566C506477cD2d8dF4e0123512dBc344bD9D111" className="flex uppercase underline items-center justify-center gap-2" target="_blank">
                    Visit
                    <FaExternalLinkAlt size={16} />
                  </a>
                </div>
                {/* <p className='text-orange-400'>
                For those who trade on UNISWAP
                <br />
                If you are a beginner in crypto, please trade on CEX (LBANK and MEXC), it's much simpler.
                <br />
                Trading on UNISWAP will open up to 20 minutes after the official trading time on LBANK and MEXC, so it will be around 17h20 for Uniswap. This delay is due to the technical set-up.
                <br />
                <b>Always ensure that you are trading the correct official token by verifying the token contract address: ‘0x0566C506477cD2d8dF4e0123512dBc344bD9D111’ on Polygonscan. Thank you for your vigilance and support!</b>
                <br />
                If you have any doubt, go to our official website <a href="https://www.mylovelyplanet.org/" target='_blank'>https://www.mylovelyplanet.org</a> for the correct Uniswap link.</p> */}
                {/* <div className="flex items-center justify-between text-primary-white text-xl font-passionOne gap-4 bg-primary-white bg-opacity-5 h-20 py-7 px-9 rounded-2xl ring-1 ring-slate-50 ring-opacity-20">
                  <img src="https://assets.staticimg.com/g-biz/externals/2022-06-01/9bfcd9feaf2b8bed.svg" alt="kucoin" className="w-52" />
                  <a href="https://www.kucoin.com/" className="flex uppercase underline items-center justify-center gap-2" target="_blank">
                    Visit
                    <FaExternalLinkAlt size={16} />
                  </a>
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}