import React, {Fragment} from 'react'
import {Dialog, Transition} from "@headlessui/react";
import {useTranslation} from "react-i18next";
import {LiaTimesSolid} from "react-icons/lia";
import {BsFillExclamationDiamondFill} from "react-icons/bs";

export default function BuyModalDisabled({
    isOpened,
    closeModal,
  }) {
  const {t} = useTranslation();

  return (
    <Transition appear show={isOpened} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-primary bg-opacity-60 backdrop-blur-sm"/>
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-3xl bg-gradient-to-b from-primary-modalBlue to-primary-customDark p-12 gap-5 flex flex-col text-left align-middle shadow-xl transition-all">
                <button onClick={closeModal} className="absolute top-8 right-10 font-bold rounded-2xl border-2 border-white border-opacity-15 p-4 hover:border-opacity-30">
                  <LiaTimesSolid size={30} color="white"/>
                </button>
                <Dialog.Title as="h1" className="text-5xl font-passionOne text-white bg-clip-text uppercase mb-10">
                  {t('Balance.BuyDisabledModal.Title')}
                </Dialog.Title>
                <p className="flex text-primary-white text-xl font-passionOne items-center gap-4 bg-primary-dark bg-opacity-50 p-5 rounded-2xl">
                  <BsFillExclamationDiamondFill size={40} />
                  {t('Balance.BuyDisabledModal.BuyAvailableMessageDisabledPopup')}
                </p>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}