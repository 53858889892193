import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Card from "../Card/Card";
import {TbMoneybag} from "react-icons/tb";
import token3D from "../../assets/img/token_1_3D.png";
import TextWithLoader from "../TextWithLoader/TextWithLoader";
import Button from "../Button/Button";
import {BiMessageSquareError} from "react-icons/bi";
import DistributionCalendarModal from "../DistributionCalendarModal/DistributionCalendarModal";
import {auth, extMainApiUrl} from "../../firebase";
import axios from "axios";
import {UserContext} from "../../hooks/useUser";
import LockedTokenModal from "../LockedTokenModal/LockedTokenModal";
import BuyModal from "../BuyModal/BuyModal";
import BuyModalDisabled from "../BuyModalDisabled/BuyModalDisabled";

export default function QuestEligibilityButton({ checkEligLaunched, setCheckEligLaunched, checkEligInProgress, setCheckEligInProgress}) {
    const {t} = useTranslation();
    const {user, setUser, getRawUser, tokenAirdropStats, totalPurchased, fetchTokensBalances, mlcTokenPriceInUsd} = useContext(UserContext);

    const [qaUserTest, setQaUserTest] = useState('');

    const checkEligibility = async () => {
        if (!checkEligInProgress) {
            setCheckEligInProgress(true);
            // Refresh the current balance of bought and airdrop tokens
            await fetchTokensBalances('force');
            setCheckEligLaunched(true);
            setCheckEligInProgress(false);

            let rusr = await getRawUser()
            if (rusr && rusr.email) {
                setUser(rusr);
            }
        }
    }


    useEffect(() => {
        if (window.localStorage.getItem('zzqauserairdrop')) {
            setQaUserTest(window.localStorage.getItem('zzqauserairdrop'))
        } else {
            setQaUserTest('')
        }
    }, [qaUserTest, window.localStorage.getItem('zzqauserairdrop')]);



    // useEffect(() => {
    //     console.log({checkEligLaunched, checkEligInProgress});
    // }, [checkEligLaunched, checkEligInProgress]);

    return (
        <Card title='' withBorder withMinH={false}>
            {qaUserTest && qaUserTest.toString() !== '' &&
            <>
                <p className='text-red-500'>
                    QA Test user airdrop data : {qaUserTest}
                    <br />
                    <u className='pointer' onClick={() => window.localStorage.removeItem('zzqauserairdrop')}>Clear</u>
                </p>
            </>
            }
            <div className="flex flex-col w-full font-passionOne text-xl gap-10 items-center justify-center ">
                <p>{t('QuestEligibility.Msg1')}</p>
                <div className="flex flex-col w-full items-center justify-center gap-10">
                    <span className="group relative">
                        <span className="group relative flex justify-center">
                        <Button color="secondary" size="large" onClick={() => checkEligibility()}>
                            {t('QuestEligibility.CheckStatus')}{checkEligInProgress && 
                        <>..</>
                            }
                        </Button>
                        </span>
                    </span>
                </div>

            </div>
        </Card>
    )
}