import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Dialog, Transition} from "@headlessui/react";
import {useTranslation} from "react-i18next";
import {LiaTimesSolid} from "react-icons/lia";
import {BsFillExclamationDiamondFill} from "react-icons/bs";
import { UserContext } from '../../hooks/useUser';
import moment from "moment";
import 'moment/locale/fr';
import i18n from '../../i18n';

export default function DistributionCalendarModal({
    isOpened,
    closeModal,
  }) {
  const {t} = useTranslation();
  const {tokenStats} = useContext(UserContext);
  const [releaseData, setReleaseData] = useState([]);
  const [currentMomentLocale, setCurrentMomentLocale] = useState('en');

  const safeRound = (num) => {
    // return Number(parseFloat(num).toFixed(2));
    return Number(+(Math.round(num + "e+2")  + "e-2"));
  };

  useEffect(() => {
    if (i18n && i18n.language && i18n.language.toLowerCase() !== '' && i18n.language.toLowerCase() === 'fr') {
      setCurrentMomentLocale('fr')
    } else {
      setCurrentMomentLocale('en')
    }
}, [i18n.language]);

  useEffect(() => {
    let releaseCalendar = [];
    const dateStartAsStr = '2024-06-21T00:00:00.000Z';
    let indexMonth = 5;
    let nbMonth = 0;
    for (let index = 1; index <= 37; index++) {
      let year = 2024;
      if (index >= 8 && index < 20) {
        year = 2025;
      }
      else if (index >= 20 && index < 32) {
        year = 2026;
      }
      else if (index >= 32) {
        year = 2027;
      }
      indexMonth = new Date(year,indexMonth+1).getMonth();
      if (indexMonth === 0) {
        indexMonth = 12;
      }
      let currDateStr = dateStartAsStr.replace('2024-',`${year}-`);
      currDateStr = currDateStr.replace('-06-',`-${('0' + indexMonth).slice(-2)}-`);
      nbMonth++;
      let preprendDay = '20';
      if (index === 1) {
        preprendDay = '25';
      }
      // Append to Calendar
      releaseCalendar.push({
        date: new Date(currDateStr).toISOString(),
        month: `${preprendDay} ${moment(currDateStr).locale(currentMomentLocale).format('MMMM')} ${year}`,
        mlc: 0,
      });
    }
    // console.log(nbMonth);
    // console.log(releaseCalendar);
    if (tokenStats && Object.keys(tokenStats).length && (tokenStats.tge5 || tokenStats.tge20)) {
      // console.log({tokenStats});
      // Append MLC token by month
      for (let index = 0; index < releaseCalendar.length; index++) {
        if (index === 0) { // first month is TGE
          if (tokenStats.tge5.total) {
            releaseCalendar[index].mlc = safeRound(parseFloat(Number(parseFloat(releaseCalendar[index].mlc).toFixed(10)) + Number(parseFloat(Number(tokenStats.tge5.total) * 0.05).toFixed(10))));
          }
          if (tokenStats.tge20.total) {
            releaseCalendar[index].mlc = safeRound(parseFloat(Number(parseFloat(releaseCalendar[index].mlc).toFixed(10)) + Number(parseFloat(Number(tokenStats.tge20.total) * 0.20).toFixed(10))));
          }
        }
        // nothing for 3 months after tge month (3 months cliff)
        if (index > 3) {
            // calc chunk for each vest and each tge
            let totalMonthChunk = 0;
            let totalByVesting = {
              12: 0,
              18: 0,
              24: 0,
              30: 0,
              36: 0,
            };
            let totalChunkByVesting = {
              12: 0,
              18: 0,
              24: 0,
              30: 0,
              36: 0,
            };
            for (const vestDurationInMonths in totalByVesting) {
              if (tokenStats.tge5[`vest${vestDurationInMonths}`]) {
                totalByVesting[vestDurationInMonths] += Number(tokenStats.tge5[`vest${vestDurationInMonths}`] - (tokenStats.tge5[`vest${vestDurationInMonths}`] * 0.05));
              }
              if (tokenStats.tge20[`vest${vestDurationInMonths}`]) {
                totalByVesting[vestDurationInMonths] += Number(tokenStats.tge20[`vest${vestDurationInMonths}`] - (tokenStats.tge20[`vest${vestDurationInMonths}`] * 0.20));
              }
            }
            // Add token validatedReferreeToken in vesting 2 years / 24 months
            if (tokenStats.tge5.validatedReferreeToken) {
              totalByVesting[24] += tokenStats.tge5.validatedReferreeToken - (tokenStats.tge5.validatedReferreeToken * 0.05);
            }
            if (tokenStats.tge20.validatedReferreeToken) {
              totalByVesting[24] += tokenStats.tge20.validatedReferreeToken - (tokenStats.tge20.validatedReferreeToken * 0.20);
            }
            // console.log(totalByVesting);
            // Calc the chunk by vesting
            for (const vestDurationInMonths in totalByVesting) {
              totalChunkByVesting[vestDurationInMonths] = (totalByVesting[vestDurationInMonths] / (vestDurationInMonths - 3));
            }
            // Calc the chunk by month
            if (index <= 12) {
              totalMonthChunk += totalChunkByVesting[12] + totalChunkByVesting[18] + totalChunkByVesting[24] + totalChunkByVesting[30] + totalChunkByVesting[36];
            } else if (index > 12 && index <= 18) {
              totalMonthChunk += totalChunkByVesting[18] + totalChunkByVesting[24] + totalChunkByVesting[30] + totalChunkByVesting[36];
            } else if (index > 18 && index <= 24) {
              totalMonthChunk += totalChunkByVesting[24] + totalChunkByVesting[30] + totalChunkByVesting[36];
            } else if (index > 24 && index <= 30) {
              totalMonthChunk += totalChunkByVesting[30] + totalChunkByVesting[36];
            } else if (index > 30) {
              totalMonthChunk += totalChunkByVesting[36];
            }
            // console.log({totalChunkByVesting});
            releaseCalendar[index].mlc = safeRound(parseFloat(totalMonthChunk));
        }
    }
      
    }

    // Calc sum from calculated data
    let DistributionSum = 0;
    for (let index = 0; index < releaseCalendar.length; index++) {
      const item = releaseCalendar[index];
      if (item.mlc > 0) {
        DistributionSum += item.mlc
      }
    }
    console.log({DistributionSum});
    setReleaseData(releaseCalendar);
}, [tokenStats, currentMomentLocale])


  return (
    <Transition appear show={isOpened} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-primary bg-opacity-60 backdrop-blur-sm"/>
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-3xl bg-gradient-to-b from-primary-modalBlue to-primary-customDark p-12 gap-5 flex flex-col text-left align-middle shadow-xl transition-all">
                <button onClick={closeModal} className="absolute top-8 right-10 font-bold rounded-2xl border-2 border-white border-opacity-15 p-4 hover:border-opacity-30">
                  <LiaTimesSolid size={30} color="white"/>
                </button>
                <Dialog.Title as="h1" className="text-5xl font-passionOne text-white bg-clip-text uppercase mb-10">
                  {t('Balance.DistributionCalendarModal.Title')}
                </Dialog.Title>
                {/* <p className="flex text-primary-white text-xl font-passionOne items-center gap-4 bg-primary-dark bg-opacity-50 p-5 rounded-2xl">
                  <BsFillExclamationDiamondFill size={40} />
                  {t('Balance.DistributionCalendarModal.ClaimAvailableMessage')}
                </p> */}
                {/* <p className="flex text-orange-700 font-passionOne items-center gap-4 border-2 border-orange-200 bg-orange-300 border-opacity-30 py-2 px-5 rounded-2xl">
                  <BsFillExclamationDiamondFill size={20} />
                  {t('Balance.DistributionCalendarModal.ClaimAvailableMessage')}
                </p> */}
                <div className="table-row w-full gap-5 p-2 pb-5 bg-white bg-opacity-5 border-white border-opacity-10 rounded-3xl border-2" style={{ filter: "brightness(105%)", borderRadius: '2.33rem' }}>
                  <table className="w-full table-auto">
                    <tbody className="bg-transparent table w-full">
                      <tr className='table-row w-full text-left'>
                        <th scope="col"
                            className="font-thin py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-left text-xl text-white opacity-50 sm:pl-3 uppercase font-passionOne">
                          {t('Balance.DistributionCalendarModal.Month')}
                        </th>
                        <th scope="col"
                            className="font-thin py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-left text-xl text-white opacity-50 sm:pl-3 uppercase font-passionOne">
                          {t('Balance.DistributionCalendarModal.MLC_Released')}
                        </th>
                      </tr>
                      {releaseData && releaseData.map(({ month, mlc }, index) => (
                        <>
                        {index <= 3 &&
                        <>
                        <tr key={index} className='table-row w-full text-left'>
                          <td className='whitespace-nowrap py-2 px-2 md:py-2 md:pl-6 md:pr-3 text-sm lg:text-2xl font-passionOne text-white uppercase w-1/3'>
                            {month}
                          </td>
                          <td className='whitespace-nowrap py-2 px-2 md:py-2 md:pl-6 md:pr-3 text-sm lg:text-2xl font-passionOne text-white flex items-center'>
                            {!!index && index === 3 && 
                            <>
                            <span className="uppercase">{t('Common.CliffEnd')}*</span>
                            </>
                            }
                            {index !== 3 && 
                            <>
                            {mlc}
                            <span className="font-sofiaSans uppercase text-sm opacity-50 ml-1">$MLC</span>
                            </>
                            }
                          </td>
                        </tr>
                        </>}
                        </>
                      ))}
                    </tbody>
                  </table>
                  <p className='relative w-full table-row text-left font-passionOne text-white italic'>
                    {t('Common.CliffDetails')}
                  </p>


                  <table className="w-full table-auto">
                    <tbody className="bg-transparent table w-full">
                      {releaseData && releaseData.map(({ month, mlc }, index) => (
                        <>
                        {index > 3 &&
                        <>
                        <tr key={index} className='table-row w-full text-left'>
                          <td className='whitespace-nowrap py-2 px-2 md:py-2 md:pl-6 md:pr-3 text-sm lg:text-2xl font-passionOne text-white uppercase w-1/3'>
                            {month}
                          </td>
                          <td className='whitespace-nowrap py-2 px-2 md:py-2 md:pl-6 md:pr-3 text-sm lg:text-2xl font-passionOne text-white flex items-center'>
                            {!!index && index === 3 && 
                            <>
                            <span className="uppercase">{t('Common.CliffEnd')}*</span>
                            </>
                            }
                            {index !== 3 && 
                            <>
                            {mlc}
                            <span className="font-sofiaSans uppercase text-sm opacity-50 ml-1">$MLC</span>
                            </>
                            }
                          </td>
                        </tr>
                        </>}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}