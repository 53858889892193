import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import moment from "moment";
import 'moment/locale/fr';
import {UserContext} from "../../hooks/useUser";
import {FaCircle} from "react-icons/fa";



export default function Transactions(props) {
    const {t} = useTranslation();
    const [transactions, setTransactions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true)
    const paymentTypes = ['ETH', 'USDC', 'USDT', 'MATIC', 'BNB', 'XTZ']
    const {user, totalReferee} = useContext(UserContext);
    const validVestingsDurationInMonths = {
        1: 12,
        '1': 12,
        1.5: 18,
        '1.5': 18,
        '1,5': 18,
        2: 24,
        '2': 24,
        2.5: 30,
        '2.5': 30,
        '2,5': 30,
        3: 36,
        '3': 36,
    }

    useEffect(() => {
        setIsLoading(true)

        if (user.investments && Object.keys(user.investments).length > 0) {

            const tmpTransactions = []
            for (let key in user.investments) {
                if (user.investments[key].status && user.investments[key].status === 'validated') {
                    tmpTransactions.push({
                        ...user.investments[key],
                        id: key
                    })
                }
            }

            // Add a custom referrals transaction (token won by referree)
            if (user.referrals && totalReferee > 0) {
                tmpTransactions.push({
                    id: 'referral-bonus',
                    type: 'referral',
                    token_amount: totalReferee,
                    vestingDuration: 2,
                    status: 'validated',
                })
            }

            setTransactions(tmpTransactions)
        }
        setIsLoading(false)
    }, [user])

    const printTge = (tx, investments) => {
        let tgePercent = 5; // default 5%
        if (tx.launchPadNum && tx.launchPadNum.toString().toLowerCase() === '1') {
            tgePercent = 20;
        }
        return tgePercent;
    };

    const printVesting = (tx, investments) => {
        let vesting = 12; // default 1 year
        if (tx.type.toLowerCase() === 'purchaseprivate' || tx.type.toLowerCase() === 'referral - private') {
            vesting = 24;
        }
        // try to update default value by true direct tx value
        if (tx.vestingDuration && validVestingsDurationInMonths[tx.vestingDuration]) {
            vesting = validVestingsDurationInMonths[tx.vestingDuration]
        } else {
             // try to update default value by true direct tx value form transaction of reference
            if (tx.transactionRef && tx.transactionRef.toString() !== '') {
                for (const key in investments) {
                    // console.log(key);
                    if (key.toString() === tx.transactionRef.toString() && investments[key].vestingDuration && validVestingsDurationInMonths[investments[key].vestingDuration]) {
                        vesting = validVestingsDurationInMonths[investments[key].vestingDuration]
                        break
                    }
                }
            }
        }
        return vesting;
    };

    const getColorByVesting = (vesting) => {
        const level = vesting / 6;
        const levels = {
            1: '#00ff00',
            2: '#40bf00',
            3: '#6a9500',
            4: '#a0a000',
            5: '#f1c232',
            6: '#cf9f0e',
        };

        return levels[level];
    };

    if (isLoading === true) {
        return <h1 className="font-passionOne text-5xl text-white">&nbsp;</h1>
    }

    return (
        <div className="flex flex-col w-full bg-transparent gap-5 mt-20 md:mt-2">
            <h1 className="font-passionOne text-5xl uppercase text-white ">{t('Transactions.Title')}</h1>
            <p className="font-passionOne text-xl text-white">{t('Transactions.PageContentDescr')}</p>
            <div className="flex flex-col w-full gap-5 rounded-3xl bg-white bg-opacity-15 p-0 mt-5">
                <div className="relative rounded-3xl shadow-sm bg-white bg-opacity-10">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon className="h-8 w-8 text-white" aria-hidden="true"/>
                    </div>
                    <input
                        type="text"
                        name="searchQuery"
                        id="searchQuery"
                        className="w-full rounded-3xl pl-12 font-passionOne text-white opacity-80 placeholder-white bg-transparent p-6 text-xl uppercase"
                        placeholder={t('Transactions.Table.Search')}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>
            <div className="flex flex-col md:table-row w-full gap-5 rounded-3xl bg-white bg-opacity-15 p-0 overflow-hidden">
                <table className="w-full table-auto">
                    <tbody className="bg-transparent flex flex-col md:table w-full">
                    <tr className='hidden md:table-row w-full text-center md:text-left'>
                        <th scope="col"
                            className="font-thin py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-left text-xl text-white opacity-50 sm:pl-3 uppercase font-passionOne">
                            {t('Transactions.Table.ID')}
                        </th>
                        <th scope="col"
                            className="font-thin py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-left text-xl text-white opacity-50 sm:pl-3 uppercase font-passionOne">
                            {t('Transactions.Table.Tokens')}
                        </th>
                        <th scope="col"
                            className="font-thin py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-left text-xl text-white opacity-50 sm:pl-3 uppercase font-passionOne">
                            {t('Transactions.Table.Vesting')}
                        </th>
                        <th scope="col"
                            className="font-thin py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-left text-xl text-white opacity-50 sm:pl-3 uppercase font-passionOne">
                            {t('Transactions.Table.AtTge')}
                        </th>
                        <th scope="col"
                            className="hidden md:table-cell font-thin py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-left text-xl text-white opacity-50 sm:pl-3 uppercase font-passionOne">
                            {t('Transactions.Table.Paid')}
                        </th>
                        <th scope="col"
                            className="font-thin hidden md:table-cell py-2 px-2 md:py-4 md:pl-8 md:pr-3 text-left text-xl text-white opacity-50 sm:pl-3 uppercase font-passionOne">
                            {t('Transactions.Table.Type')}
                        </th>
                        <th scope="col"
                            className="font-thin hidden md:table-cell py-2 px-2 md:py-4 md:pl-8 md:pr-3 text-left text-xl text-white opacity-50 sm:pl-3 uppercase font-passionOne">
                            {t('Transactions.Table.Status')}
                        </th>
                    </tr>
                    {transactions.filter(txn => {
                        if (searchQuery !== '') {
                            if (txn.id.toString().toLowerCase().indexOf(searchQuery.toString().toLowerCase()) !== -1) {
                                return txn
                            }
                            if (txn.transactionRef && txn.transactionRef.toString().toLowerCase().indexOf(searchQuery.toString().toLowerCase()) !== -1) {
                                return txn
                            }
                            if (txn.currency && txn.currency.toString().toLowerCase().indexOf(searchQuery.toString().toLowerCase()) !== -1) {
                                return txn
                            }
                            if (txn.status && txn.status.toString().toLowerCase().indexOf(searchQuery.toString().toLowerCase()) !== -1) {
                                return txn
                            }
                            if (txn.type && txn.type.toString().toLowerCase().indexOf(searchQuery.toString().toLowerCase()) !== -1) {
                                return txn
                            }
                        } else {
                            return txn
                        }
                    }).map((txn, index) => (
                        <tr key={txn.id} className='flex flex-col md:table-row w-full text-center md:text-left'>
                            <td className={index % 2 === 0
                                ? 'relative whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white'
                                : 'relative whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white bg-white bg-opacity-15'}>
                                {txn.id}
                                {txn.created_at && 
                                <p className="font-sofiaSans uppercase text-sm opacity-50">{moment(txn.created_at).format('LL')} {txn.transactionRef ?  ' | ' + txn.transactionRef : ''}</p>}
                                &nbsp;
                            </td>
                            <td className={index % 2 === 0
                                ? 'whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white'
                                : 'whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white bg-white bg-opacity-15'}>
                                +{parseFloat(txn.token_amount).toFixed(0)}
                                <p className="font-sofiaSans uppercase text-sm opacity-50">$MLC</p>
                            </td>
                            <td className={index % 2 === 0
                              ? 'whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white'
                              : 'whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white bg-white bg-opacity-15'}>
                                <div className="flex items-center justify-center md:justify-start gap-1">
                                    <FaCircle size={10} color={getColorByVesting(printVesting(txn, user.investments))}/>
                                    {printVesting(txn, user.investments)}
                                </div>
                                <p className="font-sofiaSans uppercase text-sm opacity-50">{t("Common.Months")}</p>
                            </td>
                            <td className={index % 2 === 0
                              ? 'whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white'
                              : 'whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white bg-white bg-opacity-15'}>
                                <div className="flex items-center justify-center md:justify-start gap-1">
                                    {printTge(txn, user.investments)}%
                                </div>
                            </td>
                            <td className={index % 2 === 0
                              ? 'md:table-cell whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white'
                                : 'md:table-cell whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white bg-white bg-opacity-15'}>

                                {['purchase', 'PurchasePrivate'].includes(txn.type) && ['ETH'].includes(txn.currency) && parseFloat(txn.amountInWei / 1e18).toFixed(8)}
                                {['purchase', 'PurchasePrivate'].includes(txn.type) && ['XTZ'].includes(txn.currency) && parseFloat(txn.amountInWei).toFixed(8)}
                                {['purchase', 'PurchasePrivate'].includes(txn.type) && txn.currency === 'MATIC' && parseFloat(txn.amountInWei / 1e18).toFixed(8)}
                                {['purchase', 'PurchasePrivate'].includes(txn.type) && txn.currency === 'BNB' && parseFloat(txn.amountInWei / 1e18).toFixed(8)}
                                {['purchase', 'PurchasePrivate'].includes(txn.type) && (txn.currency === 'USDT' || txn.currency === 'USDC') && (parseFloat(txn.investmentInUSDT)).toFixed(2)}
                                {['purchase', 'PurchasePrivate'].includes(txn.type) && txn.currency !== 'USDT' && txn.currency !== 'USDC' && txn.currency !== 'ETH' && txn.currency !== 'MATIC' && txn.currency !== 'BNB' && txn.currency !== 'XTZ' && txn.investment}
                                {['purchase', 'PurchasePrivate'].includes(txn.type) && (' ' + txn.currency)}
                            </td>
                            <td className={index % 2 === 0
                                ? 'md:table-cell whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white'
                                : 'md:table-cell whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white bg-white bg-opacity-15'}>
                                {['purchase', 'PurchasePrivate'].includes(txn.type) && (
                                    <span
                                        className="rounded-full px-2 py-1 md:px-4 md:py-2 font-passionOne uppercase bg-amber-50 bg-opacity-40 text-sm">{t('Transactions.Table.' + txn.type)}</span>
                                )}

                                {['referral', 'bonus'].includes(txn.type) && (
                                    <div>
                                        <p className="rounded-full px-2 py-1 md:px-4 md:py-2 font-passionOne uppercase bg-opacity-0 text-sm">{t('Transactions.Table.' + txn.type)}</p>
                                    </div>
                                )}

                                {!['referral', 'bonus', 'purchase', 'PurchasePrivate'].includes(txn.type) && (
                                    <div>
                                        <p className="rounded-full px-2 py-1 md:px-4 md:py-2 font-passionOne uppercase bg-opacity-0 text-sm">{txn.type}</p>
                                    </div>
                                )}
                            </td>
                            <td className={index % 2 === 0
                                ? 'whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white'
                                : 'whitespace-nowrap py-2 px-2 md:py-4 md:pl-6 md:pr-3 text-sm xl:text-xl font-passionOne text-white bg-white bg-opacity-15'}>


                                <span className={txn.status === 'validated'
                                    ? "rounded-full px-2 py-1 md:px-4 md:py-2 font-passionOne uppercase bg-secondary-otherGreen bg-opacity-40 text-sm"
                                    : txn.status === 'draft'
                                    ? "rounded-full px-2 py-1 md:px-4 md:py-2 font-passionOne uppercase bg-white bg-opacity-15 text-sm"
                                    : "rounded-full px-2 py-1 md:px-4 md:py-2 font-passionOne uppercase bg-secondary-otherRed bg-opacity-40 text-sm"
                                }>
                                    {(txn.status === 'draft') && 'draft'}
                                    {(paymentTypes.includes(txn.currency) && txn.status === 'pending' && (!txn.transactionHash || (txn.transactionHash && txn.transactionHash.toString() === ''))) && t('Transactions.Table.MissingTransactionHash')}
                                    {(paymentTypes.includes(txn.currency) && txn.status === 'pending' && !(!txn.transactionHash || (txn.transactionHash && txn.transactionHash.toString() === ''))) && t('Transactions.Table.ConfirmingByCrypto')}
                                    {(txn.status === 'refused') && t('Transactions.Table.cancelled')}
                                    {(txn.status === 'cancelled') && t('Transactions.Table.cancelled')}
                                    {(txn.status === 'rejected') && t('Transactions.Table.cancelled')}
                                    {(txn.status === 'rejected by user') && t('Transactions.Table.RejectedByUser')}
                                    {(txn.currency === '€' && txn.status === 'pending') && t('Transactions.Table.ConfirmingByEuro')}
                                    {(txn.currency === 'euros' && txn.status === 'pending') && t('Transactions.Table.ConfirmingByEuro')}
                                    {(txn.currency === 'EUR' && txn.status === 'pending') && t('Transactions.Table.ConfirmingByEuro')}
                                    {txn.paidBy === 'bonus' && txn.status === 'pending' && t('Transactions.Table.ConfirmingByEuro')}
                                    {txn.paidBy === 'bonus' && txn.status === 'cancelled' && t('Transactions.Table.cancelled')}
                                    {txn.paidBy === 'bonus' && txn.status === 'rejected by user' && t('Transactions.Table.RejectedByUser')}
                                    {(txn.status === 'validated') && txn.status}
                                    {(!txn.status) && t('Transactions.Table.cancelled')}
                                </span>
                            </td>
                        </tr>
                    ))}
                    {transactions.filter(txn => {
                        if (searchQuery !== '') {
                            if (txn.id.toString().toLowerCase().indexOf(searchQuery.toString().toLowerCase()) !== -1) {
                                return txn
                            }
                            if (txn.transactionRef && txn.transactionRef.toString().toLowerCase().indexOf(searchQuery.toString().toLowerCase()) !== -1) {
                                return txn
                            }
                            if (txn.currency && txn.currency.toString().toLowerCase().indexOf(searchQuery.toString().toLowerCase()) !== -1) {
                                return txn
                            }
                            if (txn.status && txn.status.toString().toLowerCase().indexOf(searchQuery.toString().toLowerCase()) !== -1) {
                                return txn
                            }
                            if (txn.type && txn.type.toString().toLowerCase().indexOf(searchQuery.toString().toLowerCase()) !== -1) {
                                return txn
                            }
                        } else {
                            return txn
                        }
                    }).length === 0 &&
                    <tr className='flex flex-col md:table-row w-full text-center'>
                        <td colSpan={5} className='w-full text-xl font-passionOne text-white opacity-50 py-3'>{t('Transactions.Table.empty')}</td>
                    </tr>
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
