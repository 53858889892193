import React, {Fragment, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {useTranslation} from "react-i18next";

const VersionUpdate = ({isOpen, closeModal}) => {

    const {t} = useTranslation();

    
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black-primary bg-opacity-60 backdrop-blur-sm"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-8 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="w-full max-w-4xl transform overflow-hidden rounded-3xl bg-gradient-to-b from-primary-modalBlue to-primary-dark p-12 gap-5 flex flex-col text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h1"
                                    className="text-5xl text-center font-passionOne bg-gradient-to-b from-gradients-yellowTwo to-gradients-yellowOne text-transparent bg-clip-text uppercase"
                                >
                                    {/* {t('RecoveryAddress.Auth.Title')} */}
                                </Dialog.Title>

                                <div className="flex flex-col w-full gap-5">
                                    <h3 className="text-white text-opacity-50 uppercase font-passionOne text-xl">{t('VersionUpdate.Message')}</h3>
                                    {/* <h2 className="text-white uppercase font-passionOne text-3xl">{t('RecoveryAddress.Auth.Question')}</h2> */}
                                </div>
                                <div className="mt-2 flex flex-col w-full gap-5">
                                    {<button
                                        onClick={closeModal}
                                        className="md:inline-flex items-center justify-center gap-3 bg-primary-lightBlue p-2 py-6 w-full rounded-2xl text-white font-passionOne text-xl">
                                        {t('SessionExpired.Reload')}
                                    </button>}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default VersionUpdate;
