import {TbShoppingBag} from "react-icons/tb";
import BuyModal from "../BuyModal/BuyModal";
import SeeTokenModal from "../BuyModal/SeeTokenModal";
import React, {useEffect, useContext, useState} from "react";
import {Link} from 'react-router-dom';
import Card from "../Card/Card";
import token3D from "../../assets/img/token_1_3D.png";
import TextWithLoader from "../TextWithLoader/TextWithLoader";
import Button from "../Button/Button";
import {useTranslation} from "react-i18next";
import { UserContext } from "../../hooks/useUser";
import {FaRegCopy} from "react-icons/fa";
import { auth } from "../../firebase";
import routes from "../../route";


// info airdrop claim du message ouvre l'onglet 


// Dashboard 
// Plus que profile avec nom prenom avatar Email VerifyEmailModal
// Quest avec bouton buy et bouton airdrop? qui renvoi vers onglet


export default function Quest() {
    const {t} = useTranslation();
    const [buyModalOpened, setBuyModalOpened] = useState(false);
    const [seeTokenOpened, setSeeTokenOpened] = useState(false);
    const [isClaimFeatureDisabled, setIsClaimFeatureDisabled] = useState(true);
    const [coinBalance, setCoinBalance] = useState(-1);
    const [coinBalanceTxt, setCoinBalanceTxt] = useState('?');
    const [coinBalanceTxtError, setCoinBalanceTxtError] = useState();
    const {user, web3TokenBalance, siteFeatureClaimToken} = useContext(UserContext);


    const getCoinBalance = async () => {
        setTimeout(() => {
            if (web3TokenBalance === -2) {
                setCoinBalanceTxtError('loading error, fund are safe')
                setCoinBalance(0)
            } else {
                setCoinBalanceTxt(web3TokenBalance.toString())
                setCoinBalance(web3TokenBalance)
            }
        }, 500);
    };


    const openAirdropPage = () => {
        window.open('https://whitepaper.mylovelyplanet.org/lovely-family-mlc-investors/how-to-claim-your-usdmlc-available-on-june-25');
    }


    useEffect(() => {
        getCoinBalance()
    }, [coinBalance, web3TokenBalance]);

    useEffect(() => {
        if (siteFeatureClaimToken && siteFeatureClaimToken === '1') {
            setIsClaimFeatureDisabled(false);
        } else {
            setIsClaimFeatureDisabled(true);
        }
    }, [user, auth, siteFeatureClaimToken]);


    return (
        <Card title={t('Dashboard.BuyTokenForAll')} icon={<TbShoppingBag size={40}/>} withMinH={false} withBorder>

            <BuyModal
                isOpened={buyModalOpened}
                closeModal={() => setBuyModalOpened(false)}
            />
            <SeeTokenModal
                isOpened={seeTokenOpened}
                closeModal={() => setSeeTokenOpened(false)}
            />
            
            <Button onClick={() => setBuyModalOpened(true)} color="secondary" size="large">
                {t('Buy.Buy')}
            </Button>
            <Link
                key="goToAirdrops"
                to={routes.AIRDROPS}
            >
                <Button color="secondary" size="large">
                    {t('Buy.AirdropMark')}
                </Button>
            </Link>
            {/* <p className="text-xl text-slate-300 font-passionOne" >
            {t('Dashboard.TokenAddr')} : <br />
                <span onClick={() => copyAddr(MLCTokenContract)} className="bg-primary-white whitespace-nowrap bg-opacity-20 px-3 py-1 rounded-xl ml-2 text-white font-sans text-sm cursor-pointer">
                    {MLCTokenContract}
                    <FaRegCopy className="inline ml-3 mb-0.5" />
                </span>
            </p> */}
            {/* <span>
                <Button onClick={() => setSeeTokenOpened(true)} color="primary" size="large">
                    {t('Buy.HowToSeeMyToken')}
                </Button>
            </span> */}
        </Card>
    );
}