export default function TextWithLoader({ children, isLoading, errorMessage }) {
  return (
      <span className={isLoading ? "custom-loader" : ""}>
        {!isLoading && (
            <>
              {errorMessage && (<div className="bg-red-100 py-1 px-2 bg-opacity-10 ring-1 ring-red-500 font-light font-sofiaSans rounded-xl text-sm break-words text-red-400 max-w-80 [text-shadow:1px_1px_2px_var(--tw-shadow-color)] shadow-red-700">{errorMessage}</div>)}
              {!errorMessage && children}
            </>
        )}
      </span>
  );
}