import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import './i18n';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {isProd, isStaging} from "./firebase";

// Default to local env
Bugsnag.releaseStage = 'local';

if (isProd) {
    Bugsnag.releaseStage = 'production';
} else if (isStaging) {
    Bugsnag.releaseStage = 'staging';
}

// Send logs except for local env
Bugsnag.notifyReleaseStages = ['production', 'staging'];

Bugsnag.start({
    apiKey: '812a01760f2a73c6fa33b2192dade00b',
    plugins: [new BugsnagPluginReact()]
})

// http://localhost:3000/admin?pso42=force
// console.log('searchParams =', document.location.search);
const search = document.location.search;
if (search && search.indexOf('?pso42') !== -1) {
  window.localStorage.setItem('addWalletStep', 5);
  window.localStorage.setItem('addWalletStepValidity', new Date().getTime());
}
if (search && search.indexOf('?zzqauserairdrop=') !== -1) {
  window.localStorage.setItem('zzqauserairdrop', search.replace('?zzqauserairdrop=',''));
}

const root = ReactDOM.createRoot(document.getElementById('root'));

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)


root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ErrorBoundary>
                <App/>
            </ErrorBoundary>
        </BrowserRouter>
    </React.StrictMode>
);
