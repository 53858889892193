import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import {customAlertProps, MLCVestingAggregatorContract, VESTAGGAbi, auth, extMainApiUrl, validPolygonChainId} from "../../firebase";
import Swal from "sweetalert2";
import token3D from "../../assets/img/token_1_3D.png";
import {UserContext} from "../../hooks/useUser";
import Card from "../Card/Card";
import {TbLock} from "react-icons/tb";
import {TbRefresh} from "react-icons/tb";
import Button from "../Button/Button";
import TextWithLoader from "../TextWithLoader/TextWithLoader";
import ClaimModal from "../ClaimModal/ClaimModal";
import LockedTokenModal from "../LockedTokenModal/LockedTokenModal";
import {FaMoneyBillTrendUp} from "react-icons/fa6";
import { useWeb3Modal, useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import axios from "axios";

export default function Balance() {
    const {t} = useTranslation();
    const [isRefresh, setIsRefresh] = useState(false);
    const [isClaimFeatureOnlyDisabled, setIsClaimFeatureOnlyDisabled] = useState(true);
    const [isClaimFeatureDisabled, setIsClaimFeatureDisabled] = useState(true);
    const [isClaimFeatureDisabledKycReason, setIsClaimFeatureDisabledKycReason] = useState(true);
    const [isClaimDisabled, setIsClaimDisabled] = useState(true);
    const [isClaimInProgress, setIsClaimInProgress] = useState(false);
    const [coinBalanceToClaim, setCoinBalanceToClaim] = useState(-1);
    const [coinBalanceToClaimTxt, setCoinBalanceToClaimTxt] = useState('?');
    const [coinBalanceToClaimTxtError, setCoinBalanceToClaimTxtError] = useState();
    const [totalVestedCoinBalance, setTotalVestedCoinBalance] = useState(-1);
    const [totalVestedCoinBalanceTxt, setTotalVestedCoinBalanceTxt] = useState('?');
    const [totalVestedCoinBalanceTxtError, setTotalVestedCoinBalanceTxtError] = useState();
    const [claimModalOpened, setClaimModalOpened] = useState(false);
    const [lockedModalOpened, setLockedModalOpened] = useState(false);
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    // const [loading, setLoading] = useState(true);
    const {user, siteFeatureClaimToken, fetchTokensBalances, web3TokenClaimable, web3TokenRemainingLocked, totalPurchased} = useContext(UserContext)
    const { walletProvider } = useWeb3ModalProvider()
    const { open: openWeb3WalletConnect } = useWeb3Modal({ view: 'Connect' })

    const getCoinBalanceToClaim = async () => {
        // TODO Handle it with RPC call if back-end failed TBD ?
        setTimeout(() => {
            setIsClaimDisabled(true)
            if (web3TokenClaimable === -2) {
                setCoinBalanceToClaimTxtError(t('Common.TokenSafeError'))
                setCoinBalanceToClaim(0)
            } else {
                setCoinBalanceToClaimTxt(web3TokenClaimable.toString())
                setCoinBalanceToClaim(web3TokenClaimable)
                if (Number (web3TokenClaimable) >= 1) {
                    setIsClaimDisabled(false)
                }
            }
        }, 500);
    };

    const getVestedCoinBalance = async () => {
        // Value equals to totalPurchased - coinBalanceToClaim
        setTimeout(() => {
            if (web3TokenRemainingLocked === -2) {
                setTotalVestedCoinBalanceTxtError(t('Common.TokenSafeError'))
                setTotalVestedCoinBalance(0)
            } else {
                setTotalVestedCoinBalanceTxt(web3TokenRemainingLocked.toString())
                setTotalVestedCoinBalance(web3TokenRemainingLocked)
            }
        }, 500);
    };

    const isDateAfterListing = () => {
        const now = new Date().getTime();
        // console.log(`${now} > 1719340000000`);
        return now > 1719340000000; // if current timestamp > than 25/06/2024 20:26:40 timestamp
    }
 
    const handleClaim = async () => {
        if (isClaimFeatureDisabled) {
            return;
        }
        if (isClaimDisabled) {
            // setClaimModalOpened(true)
            Swal.fire({
                title: '',
                text: t('ClaimToken.NotEnoughToken'),
                icon: 'warning',
                confirmButtonText: 'Ok',
                ...customAlertProps,
            });
            return;
        }
        if (isClaimInProgress) {
            Swal.fire({
                title: '',
                text: t('ClaimToken.AlreadyInProgress'),
                icon: 'warning',
                confirmButtonText: 'Ok',
                ...customAlertProps,
            });
            return;
        }
        if (isConnected && Number(chainId) === validPolygonChainId && walletProvider && user?.connectedWallet?.publicAddress && address && user?.connectedWallet?.publicAddress.toString().toLowerCase() === address.toString().toLowerCase()) {
            let txClaimWatcher;
            let txWatcherCounter = 0;
            try {
                const ethersProvider = new BrowserProvider(walletProvider)
                const signer = await ethersProvider.getSigner()
                const MLCContract = new Contract(MLCVestingAggregatorContract, VESTAGGAbi, signer)
                console.log({address})
                setIsClaimInProgress(true);
                const aggClaim = await MLCContract.aggregatedClaim(address)
                // console.log({aggClaim})
                if (aggClaim && aggClaim.hash) {
                    // check get tx status each 2 seconds and accept 60 fails max - after 2 min display fail
                    txClaimWatcher = setInterval(async () => {
                        // const url = `${extMainApiUrl}/checkTx?hash=${'0x7aabd3adbf2fef7869712aa9373a0b707571bd755b77ebe025b3ad4d19839e08'}` // fail example
                        const url = `${extMainApiUrl}/checkTx?hash=${aggClaim.hash}`
                        txWatcherCounter = txWatcherCounter + 1;
                        await axios.get(url).then(res => {
                            if (res.data?.status === 1) {
                                clearInterval(txClaimWatcher);
                                txWatcherCounter = 0;
                                setIsClaimInProgress(false);
                                Swal.fire({
                                    title: 'Good!',
                                    text: t('ClaimToken.SuccessClaim'),
                                    icon: 'success',
                                    confirmButtonText: 'Cool',
                                    ...customAlertProps,
                                });
                                // Refresh the current balance
                                fetchTokensBalances('force');
                            }
                        });
                        if (txWatcherCounter >= 100) {
                            clearInterval(txClaimWatcher);
                            txWatcherCounter = 0;
                            setIsClaimInProgress(false);
                            console.error('Invalid or too long transaction');
                            Swal.fire({
                                title: 'Oups!',
                                text: t('ClaimToken.ErrorClaimTooLong'),
                                icon: 'error',
                                confirmButtonText: 'Ok',
                                ...customAlertProps,
                            });
                        }
                    }, 2000);
                } else {
                    console.error('missing hash in aggClaim');
                    Swal.fire({
                        title: 'Oups!',
                        text: t('ClaimToken.ErrorClaim'),
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        ...customAlertProps,
                    });
                }
            } catch (error) {
                clearInterval(txClaimWatcher);
                txWatcherCounter = 0;
                setIsClaimInProgress(false);
                console.error(error);
                if (error && error.code && error.code === 'ACTION_REJECTED') {
                    // ignore user cancelled action
                    return;
                }
                Swal.fire({
                    title: 'Oups!',
                    text: t('ClaimToken.ErrorClaim'),
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    ...customAlertProps,
                });
            }
        } else {
            openWeb3WalletConnect()
            // Swal.fire({
            //     title: '',
            //     text: t('ClaimToken.WalletNotConnected'),
            //     icon: 'warning',
            //     confirmButtonText: 'Ok',
            //     ...customAlertProps,
            // });
            return;
        }

    };

    const handleClaimModalDisabled = async () => {
        if (isClaimFeatureOnlyDisabled) {
            setClaimModalOpened(true);
        }
    };

    const handleRefresh = async () => {
        if (!isRefresh) {
            setIsRefresh(true);
            await fetchTokensBalances();
            setIsRefresh(false);
        }
    };

    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
            getVestedCoinBalance()
            getCoinBalanceToClaim()
        }
        const KYCisDone = user && user.KYC_STATUS && user.KYC_STATUS === 'VALIDATED';
        if (siteFeatureClaimToken && siteFeatureClaimToken === '1') {
            setIsClaimFeatureOnlyDisabled(false);
        } else {
            setIsClaimFeatureOnlyDisabled(true);
        }
        if (siteFeatureClaimToken && siteFeatureClaimToken === '1' && KYCisDone) {
            setIsClaimFeatureDisabled(false);
        } else {
            setIsClaimFeatureDisabled(true);
        }
        if (KYCisDone || !totalPurchased) {
            setIsClaimFeatureDisabledKycReason(false);
        } else {
            setIsClaimFeatureDisabledKycReason(true);
        }
    }, [user, auth, totalPurchased, isClaimDisabled, isClaimFeatureDisabledKycReason, web3TokenClaimable, web3TokenRemainingLocked, siteFeatureClaimToken]);

    return (<Card title={t('Dashboard.TokensBalance')} icon={<FaMoneyBillTrendUp size={35}/>} withBorder>
        <ClaimModal
            isOpened={claimModalOpened}
            closeModal={() => setClaimModalOpened(false)}
        />
        <LockedTokenModal
            isOpened={lockedModalOpened}
            closeModal={() => setLockedModalOpened(false)}
        />
        <div className="flex justify-start items-center gap-5 mb-5 relative">
            <img src={token3D} alt="" className="w-24"/>
            <div className="flex gap-2 flex-wrap">
                <div className="flex gap-2">
                    <p className={"text-xl text-white uppercase font-passionOne " + (coinBalanceToClaimTxt.length > 8 ? 'lg:text-3xl' : 'lg:text-5xl')}
                       style={coinBalanceToClaim > 0 ? {color: 'rgb(64, 191, 0)'} : {}}>
                        {coinBalanceToClaim > 0 ? '+' : ''}<TextWithLoader
                        isLoading={coinBalanceToClaim === -1} errorMessage={coinBalanceToClaimTxtError}>{coinBalanceToClaimTxt}</TextWithLoader>
                    </p>
                    <p className="text-xl text-white opacity-50 lg:text-base uppercase font-passionOne">$MLC</p>
                </div>
                <div className="cursor-pointer bg-primary-dark p-1.5 rounded-full h-fit w-fit ml-5"
                     onClick={() => handleRefresh()}>
                    <TbRefresh size={32} color="rgba(255, 255, 255, 0.7)" className={isRefresh ? 'rotate' : ''}/>
                </div>
            </div>
        </div>
        <span className="hidden lg:block">
            {!isClaimFeatureDisabled && !isClaimInProgress &&
            <Button onClick={() => handleClaim()}>
              {t('General.Claim')}
            </Button>}
            {isClaimFeatureDisabled &&
            <span className="group relative flex justify-center">
                <Button onClick={() => handleClaimModalDisabled()}>
                {t('General.Claim')}
                </Button>
                {!isClaimFeatureDisabledKycReason && !isClaimFeatureOnlyDisabled && <>
                    {!isDateAfterListing() && <>
                        <span
                            className="group-hover:flex group-focus:flex group-active:flex hidden bg-gray-800 px-3 py-2 text-xl text-gray-100 rounded-md absolute translate-y-[-120%]">
                        {t('Balance.ClaimModal.ClaimAvailableMessage')}
                        </span>
                    </>}
                    {/* {isDateAfterListing() && !isClaimFeatureOnlyDisabled && <>
                        <span
                            className="group-hover:flex group-focus:flex group-active:flex hidden bg-gray-800 px-3 py-2 text-xl text-gray-100 rounded-md absolute translate-y-[-120%]">
                        {t('Balance.ClaimModal.ClaimAvailableMessageDefault')}
                        </span>
                    </>} */}
                </>}
                {isClaimFeatureDisabledKycReason && !isClaimFeatureOnlyDisabled && <>
                    <span
                        className="group-hover:flex group-focus:flex group-active:flex hidden bg-gray-800 px-3 py-2 text-xl text-gray-100 rounded-md absolute translate-y-[-120%]">
                    {t('Balance.ClaimModal.ClaimAvailableMessageNoKYC')}
                    </span>
                </>}
            </span>
            }
            {isClaimInProgress &&
            <div className='relative'>
                <Button disabled={true}>
                <div className="custom-loader absolute right-0 z-50 mr-4 mt-1" style={{ zoom: '100%' }}></div>
                {t('General.ClaimInProgress')}
                </Button>
            </div>
            }
        </span>
        <span className="group relative lg:hidden flex justify-center">
            <Button disabled={true}>
              {t('General.Claim')}
            </Button>
            <span
                className="group-hover:flex group-focus:flex group-active:flex hidden bg-gray-800 px-3 py-2 text-xl text-gray-100 rounded-md absolute translate-y-[-120%]">
              {t('Buy.BuyDisableMessage')}
            </span>
        </span>

        <div className="cursor-pointer flex flex-col w-full" onClick={() => setLockedModalOpened(true)}>
            <div className="text-white opacity-50 font-passionOne text-xl mt-4 uppercase flex items-center gap-1">
                <TbLock size={23}/>
                {t('Balance.TokensLockedVesting')}
            </div>
            <div className="text-white font-passionOne text-2xl uppercase flex items-center gap-1 pl-1.5">
                <TextWithLoader isLoading={totalVestedCoinBalance === -1} errorMessage={totalVestedCoinBalanceTxtError}>
                    {totalVestedCoinBalanceTxt}
                </TextWithLoader> $MLC
            </div>
        </div>
    </Card>)
}