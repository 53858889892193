import {createContext, useState} from "react";
import {auth, db} from "../firebase";
import Bugsnag from "@bugsnag/js";
import axios from "axios";
import {extMainApiUrl} from "../firebase";

export const UserContext = createContext({
    user: {},
    updateUser: async () => {
    },
    getUser: async () => {
    },
    getRawUser: async () => {
    },
    getToken: async () => {
    },
    fetchTokensBalances: async (mode) => {
    },
    setUser: () => {
    },
    setToken: () => {
    },
    sendKycTrueSendinblue: () => {
    },
    sendUserToSendinbluePEHMPC: () => {
    },
    sendUserToSendinbluePPC: () => {
    },
    checkKyc: () => {
    },
})

export function UserContextProvider({children}) {
    const [user, setUser] = useState({});
    const [token, setToken] = useState("");
    const [totalReferee, setTotalReferee] = useState(0);
    const [totalPurchased, setTotalPurchased] = useState(0);
    const [web3TokenBalance, setWeb3TokenBalance] = useState(-1);
    const [web3TokenClaimable, setWeb3TokenClaimable] = useState(-1);
    const [web3TokenRemainingLocked, setWeb3TokenRemainingLocked] = useState(-1);
    const [siteFeatureClaimToken, setSiteFeatureClaimToken] = useState('0');
    const [mlcTokenPriceInUsd, setMlcTokenPriceInUsd] = useState(0.1);
    const [tokenStats, setTokenStats] = useState({});
    const [tokenAirdropStats, setTokenAirdropStats] = useState({});

    const getRawUser = async () => {
        try {
            const tmpToken = await auth.currentUser.getIdToken()
            if (tmpToken) {
                const userId = auth.currentUser.uid;
                const res = await axios.get(`${db}Users/${userId}.json?auth=${tmpToken}`);
                if (res.data) {
                    return res.data;
                }
            } else {
                throw new Error('missing IdToken')
            }
        } catch (error) {
            console.error(error);
        }
        return false;
    };

    const getUser = async () => {
        // console.log('-- UsrLoad --', JSON.stringify(user))
        if (auth && auth.currentUser && auth.currentUser.uid) {
            Bugsnag.setUser(auth.currentUser.uid, auth.currentUser.email, auth.currentUser.displayName);
        }
        return user;
    };

    const updateUser = async (dataToUpdate) => {
        const tmpToken = await auth.currentUser.getIdToken()
        await axios.patch(`${extMainApiUrl}/user/safe-update?userId=${auth.currentUser.uid}&auth=${tmpToken}`, dataToUpdate)
        const rusr = await getRawUser()
        setUser(rusr)
    }

    const fetchTokensBalances = async (mode) => {
        if (!mode || (mode && mode !== 'fetch' && mode !== 'force')) {
            mode = 'fetch'
        }
        let overrideAirdropUser = window.localStorage.getItem('zzqauserairdrop') || '';
        if (!overrideAirdropUser || (overrideAirdropUser && overrideAirdropUser.toString() === '')) {
            overrideAirdropUser = auth.currentUser.uid;
        }
        let totalTokenBoughtCurrent = -2;
        let tokenBalanceCurrent = -2;
        let tokenClaimableCurrent = -2;
        let tokenRemainingLockedCurrent = -2;
        let tokenReleasedCurrent = -2;
        try {
            // const url = `${extMainApiUrl}/stats/userAirdropToken?userId=h5y2EQ9pKOOfy8SuQS9Yy0PpcxL2&web3=${mode}`
            // use this URL test account prod http://localhost:3000/admin?zzqauserairdrop=RtQk3ayTa6MoOr5syhjAxswVOqY2
            // use this URL example xK token http://localhost:3000/admin?zzqauserairdrop=syTN6oczmwdeC2Zagpk1h93VaYR2
            // use this URL http://localhost:3000/admin?zzqauserairdrop=h5y2EQ9pKOOfy8SuQS9Yy0PpcxL2
            // use this URL http://localhost:3000/admin?zzqauserairdrop=0LJgIfEoCOUW1lsoP3Y4S5Yp4sp2
            const url = `${extMainApiUrl}/stats/userToken?userId=${overrideAirdropUser}&web3=${mode}`
            await axios.get(url).then(res => {
                if (res.data) {
                    if (res.data.siteFeatureClaimToken && res.data.siteFeatureClaimToken.toString() === '1') {
                        setSiteFeatureClaimToken(res.data.siteFeatureClaimToken.toString());
                    }
                    if (res.data.mlcTokenPriceInUsd && res.data.mlcTokenPriceInUsd.toString() != '') {
                        setMlcTokenPriceInUsd(parseFloat(parseFloat(res.data.mlcTokenPriceInUsd).toFixed(2)));
                    }
                     setTokenStats(res.data);
                    // used by Transactions page
                    setTotalReferee((res.data?.tge5?.validatedReferreeToken || 0) + (res.data?.tge20?.validatedReferreeToken || 0))
                    // used by local component
                    if (!isNaN(res.data.totalTokenBought) && res.data.totalTokenBought >= 0) {
                        totalTokenBoughtCurrent = res.data.totalTokenBought;
                    }
                    // set data used by other components
                    if (res.data.web3 && !isNaN(res.data.web3.tokenBalance) && res.data.web3.tokenBalance > -1) {
                        tokenBalanceCurrent = res.data.web3.tokenBalance;
                    }
                    if (res.data.web3 && !isNaN(res.data.web3.tokenClaimable) && res.data.web3.tokenClaimable > -1) {
                        tokenClaimableCurrent = res.data.web3.tokenClaimable;
                    }
                    if (tokenClaimableCurrent !== -2 && res.data.web3 && !isNaN(res.data.web3.tokenReleased) && res.data.web3.tokenReleased > -1) {
                        tokenReleasedCurrent = res.data.web3.tokenReleased;
                        if (totalTokenBoughtCurrent >= 0 && tokenReleasedCurrent >= 0) {
                            tokenRemainingLockedCurrent = Number(parseFloat(totalTokenBoughtCurrent - tokenReleasedCurrent - tokenClaimableCurrent).toFixed(2));
                        }
                    }
                }
                // Set data in user context
                setTotalPurchased(totalTokenBoughtCurrent);
                // If wallet not connected replace error by 0 value
                if (tokenBalanceCurrent === -2 && !user.connectedWallet) {
                    tokenBalanceCurrent = 0;
                }
                if (tokenClaimableCurrent === -2 && !user.connectedWallet) {
                    tokenClaimableCurrent = 0;
                }
                if (tokenRemainingLockedCurrent === -2 && !user.connectedWallet) {
                    tokenRemainingLockedCurrent = 0;
                }
                setWeb3TokenBalance(tokenBalanceCurrent);
                setWeb3TokenClaimable(tokenClaimableCurrent);
                if (tokenRemainingLockedCurrent < 2) {
                    tokenRemainingLockedCurrent = 0;
                }
                setWeb3TokenRemainingLocked(tokenRemainingLockedCurrent);
                // Fetch error test
                // setTotalPurchased(-2);
                // setWeb3TokenBalance(-2);
                // setWeb3TokenClaimable(-2);
                // setWeb3TokenRemainingLocked(-2);
            });
        } catch (error) {
            console.error(error);
            // Set data in user context
            setTotalPurchased(totalTokenBoughtCurrent);
            setWeb3TokenBalance(tokenBalanceCurrent);
            setWeb3TokenClaimable(tokenClaimableCurrent);
            setWeb3TokenRemainingLocked(tokenRemainingLockedCurrent);
        }
        // airdrop token balance
        const defaultError = {
            classic: {
                    error: "cannot get data",
                    tgeScAddress: "",
                    address: ""
                },
            tw: {
                error: "cannot get data",
                tgeScAddress: "",
                address: ""
            },
            totalTokenAirdrop: 0,
            questsUpdatedAt: "2024-07-02T11:14:31.328Z"
        };
        try {
            const url = `${extMainApiUrl}/stats/userAirdropToken?userId=${overrideAirdropUser}&web3=${mode}`
            await axios.get(url).then(res => {
                if (res.data) {
                     setTokenAirdropStats(res.data);
                } else {
                    setTokenAirdropStats(defaultError);
                }
            });
        } catch (error) {
            console.error('userAirdropToken', error);
            // Set data in user context
            setTokenAirdropStats(defaultError);
        }
    }

    const getToken = async () => {
        let tmpToken = token
        try {
            tmpToken = await auth.currentUser.getIdToken()
            if (tmpToken) {
                setToken(tmpToken)
            }
        } catch (error) {
            console.error('getToken failed', error);
        }
        return tmpToken
    }

    const sendKycTrueSendinblue = async () => {
        await axios.post(`${extMainApiUrl}/sendKycTrueSendinblue`, {
            user: {
                ...user,
                id: auth.currentUser.uid,
                email: auth.currentUser.email
            }
        })
    }

    const sendUserToSendinbluePEHMPC = async (amount, receivedAmount) => {
        await axios.post(`${extMainApiUrl}/sendUserToSendinBluePEHMPC`, {
            email: auth.currentUser.email,
            uid: auth.currentUser.uid,
            amount: amount,
            currency: '€',
            receivedAmount: receivedAmount,
        })
    }

    const sendUserToSendinbluePPC = async (newTransactionId, selectedPaymentMethod, amount, receivedAmount) => {
        await axios.post(`${extMainApiUrl}/sendUserToSendinBluePPC`, {
            email: auth.currentUser.email,
            uid: auth.currentUser.uid,
            paymentId: newTransactionId,
            amount: amount,
            currency: selectedPaymentMethod.currency,
            receivedAmount: receivedAmount,
        })
    }


    const checkKyc = async () => {
        // if (user.email) { // No email ecorded with social auth
        if ((user.IDENTITY !== 'VALIDATED' || user.LIVENESS !== 'VALIDATED' || user.RESIDENCY !== 'VALIDATED' || user.KYC_STATUS !== 'VALIDATED') && !user.KYC_COUNTDOWN && user.investments && Object.keys(user.investments) > 0) {
            const currentDate = new Date();
            const kycCountdown = new Date(currentDate.setDate(currentDate.getDate() + 21));
            const updatedUser = {
                KYC_COUNTDOWN: kycCountdown.toISOString()
            }
            await updateUser(updatedUser)
        }
    }

    const synapsSessionChecker = async () => {
        const userId = auth.currentUser.uid;
        let tmpToken = token
        tmpToken = await getToken()
        if (userId) {
            await axios.get(`${db}Users/${userId}.json?auth=${tmpToken}`).then(async response => {
                if (response.data && !response.data.synapsSessions) {
                    const CLIENT_ID = "SBEAB39EF297AD99";
                    const API_KEY = "6f7uPndHItizspICgDspwJQSAvuu8kYJ";
                    const config = {
                        headers: {
                            'Client-Id': CLIENT_ID,
                            'Api-Key': API_KEY,
                        },
                    };
                    await axios.post('https://individual-api.synaps.io/v3/session/init', {}, config).then(async (res) => {
                        const dataToUpdate = {synapsSessions: res.data.session_id,}
                        updateUser(dataToUpdate)
                        setUser({...response.data, dataToUpdate})
                        Bugsnag.setUser(response.data.uid, response.data.email, response.data.displayName);
                    });
                }
            })
        }
    }

    return <UserContext.Provider value={{
        user,
        updateUser,
        getUser,
        getRawUser,
        getToken,
        totalReferee,
        setTotalReferee,
        totalPurchased,
        setTotalPurchased,
        siteFeatureClaimToken,
        mlcTokenPriceInUsd,
        web3TokenBalance,
        setWeb3TokenBalance,
        web3TokenClaimable,
        setWeb3TokenClaimable,
        web3TokenRemainingLocked,
        setWeb3TokenRemainingLocked,
        tokenStats,
        setTokenStats,
        tokenAirdropStats,
        setTokenAirdropStats,
        sendKycTrueSendinblue,
        sendUserToSendinbluePEHMPC,
        sendUserToSendinbluePPC,
        checkKyc,
        token,
        synapsSessionChecker,
        setUser,
        setToken,
        fetchTokensBalances,
    }}>
        {children}
    </UserContext.Provider>
}


