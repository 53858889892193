import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

export default function Button(props) {
  const { children, to = false, onClick = () => {}, color = 'primary', size = 'medium', disabled, iconbefore, iconafter, blockclass = 'w-full' } = props;
  const [hover, setHover] = useState(false);
  const [style, setStyle] = useState({});
  const [className, setClassName] = useState('');

  useEffect(() => {
    let backgroundColor = '';
    let boxShadowColor = '';

    switch(color) {
      default:
      case 'primary':
        backgroundColor = 'bg-primary-lightBlue';
        boxShadowColor = 'rgba(7,84,167)';
        break;
      case 'secondary':
        backgroundColor = 'bg-gradient-to-b from-gradients-yellowButtonOne to-gradients-yellowButtonTwo';
        boxShadowColor = 'rgba(156,64,2)';
        break;
      case 'dark':
        backgroundColor = 'bg-black-primary bg-opacity-70';
        boxShadowColor = 'transparent';
        break;
    }

    let shadowSize = '';
    let fontSize = '';
    let padding = '';
    switch(size) {
      case 'small':
        fontSize = 'text-lg';
        padding = 'py-2 px-4';
        shadowSize = 2;
      break;
      default:
      case 'medium':
        fontSize = 'text-xl';
        padding = 'py-3 px-6';
        shadowSize = 4;
        break;
      case 'large':
        fontSize = 'text-3xl';
        padding = 'py-3 px-8';
        shadowSize = 6;
        break;
    }

    const width = blockclass;

    const style = {
      boxShadow: `0px ${shadowSize}px 0px 0px ${boxShadowColor}`,
    };
    const styleHover = {
      ...style,
      boxShadow: `0px 2px 0px 0px ${boxShadowColor}`,
      transform: `translateY(${shadowSize - 2}px)`,
    };

    setStyle(hover ? styleHover : style);
    setClassName(`${disabled ? 'opacity-40' : ''} ${fontSize} flex flex-wrap justify-center gap-3 items-center rounded-2xl ${padding} font-medium text-slate-100 font-passionOne ${width} ${backgroundColor}`);
  }, [hover]);

  if (to) {
    return (
      <Link
        {...props}
        to={to}
        className={className}
        style={style}
        onMouseEnter={()=>{
          setHover(true);
        }}
        onMouseLeave={()=>{
          setHover(false);
        }}
      >
        {iconbefore && (<span>{iconbefore}</span>)}
        <span>{children}</span>
        {iconafter && (<span>{iconafter}</span>)}
      </Link>
    );
  }

  return (
    <button
      {...props}
      onClick={onClick}
      className={className}
      style={style}
      onMouseEnter={()=>{
        setHover(true);
      }}
      onMouseLeave={()=>{
        setHover(false);
      }}
    >
      {iconbefore && (<span>{iconbefore}</span>)}
      <span>{children}</span>
      {iconafter && (<span>{iconafter}</span>)}
    </button>
  );
};