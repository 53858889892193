// libraries
import {useContext, useEffect, useState} from 'react';

import Balance from '../../components/Balance/Balance'
// components
import {useTranslation} from 'react-i18next';
import Alert from "../../components/Alert/Alert";
import Purchased from "../../components/Purchased/Purchased";
import StepsToDo from "../../components/StepsToDo/StepsToDo";
import Buy from "../../components/Buy/Buy";
import Help from "../../components/Help/Help";
import Quest from "../../components/Quest/Quest";
import Status from "../../components/Profile/Status/Status"
import SynapsModal from "../../components/Synaps/SynapsModal";
import {Link} from 'react-router-dom';
import Bugsnag from "@bugsnag/js";
import {UserContext} from "../../hooks/useUser";
import routes from '../../route';

export default function Dashboard() {
    const SERVICE = "individual";
    const LANG = "fr";
    const [synapsModalOpened, setSynapsModalOpened] = useState(false);
    const {user,totalPurchased, sendKycTrueSendinblue, synapsSessionChecker} = useContext(UserContext);
    const {t} = useTranslation();

    useEffect(() => {
        // ensure synapsSessions is present for modal
    }, [user]);

    const handleOpenKycModal = async () => {
        // ensure a synapsSessionId is present
        await synapsSessionChecker()
        // open synaps modal with iframe
        setSynapsModalOpened(true)
    };

    const handleQuestLink = async () => {
        window.open('https://whitepaper.mylovelyplanet.org/about-us/airdrop-quest/step-1-mail-validation');
    };

    const handleProcessFinished = async (a, b, c) => {
        try {
            await sendKycTrueSendinblue()
        } catch (error) {
            Bugsnag.notify(error)
        }
    };

    return (
        <>
            <div className="flex flex-col bg-transparent gap-5 overflow-y-auto">
                <h1 className="font-passionOne text-xl md:text-5xl uppercase text-white mb-5">{t('Dashboard.Title')}</h1>
                {user && user.investments && totalPurchased > 0 && user.KYC_STATUS && user.KYC_STATUS === 'NOT SUBMITTED' && (
                    <Alert otherCssClass="bg-primary-orange" onClick={handleOpenKycModal} message={t('Profile.KYC.Desc')} button={t('Profile.KYC.Proceed')} />
                )}
                {user && user.investments && totalPurchased > 0 && user.KYC_STATUS && user.KYC_STATUS === 'SUBMITTED' && (
                    <Alert otherCssClass="bg-primary-orange" onClick={handleOpenKycModal} message={t('Profile.KYC.Submitted')}/>
                )}
                {user && user.investments && totalPurchased > 0 && user.KYC_STATUS && user.KYC_STATUS === 'REJECTED' && (
                    <Alert otherCssClass="bg-primary-orange" onClick={handleOpenKycModal} message={t('Profile.KYC.Rejected')} button={t('Profile.KYC.Proceed')}/>
                )}
                {user && totalPurchased === 0 && (

                    <Link
                        key="goToAirdrops"
                        to={routes.AIRDROPS}
                    >
                    <Alert otherCssClass="bg-primary-orange" message={t('Profile.Quest.MessageAlert')} button={t('Profile.Quest.Link')}/>
                    </Link>
                )}
                {/* {user && user.investments && totalPurchased > 0 && user.KYC_STATUS && user.KYC_STATUS === 'VALIDATED' && (
                    <Alert otherCssClass="bg-secondary-green" message={t('Profile.KYC.Validated')}/>
                )} */}
                {user && user.investments && totalPurchased > 0 && user.KYC_STATUS && user.KYC_STATUS === 'VALIDATED' && !user.connectedWallet && (
                    <Alert otherCssClass="bg-primary-orange" message={t('Profile.WalletConnectedInfo.Desc')} />
                )}
                <div className="flex flex-col xl:flex-row w-full gap-10">
                    <div className="flex flex-col w-full xl:w-1/2 2xl:w-2/3 gap-10">
                        <div className="w-full flex flex-col 2xl:flex-row gap-10">
                            <div className="flex flex-col w-full 2xl:w-1/2 gap-10 h-fit">
                                {totalPurchased > 0 && <Purchased />}
                                {totalPurchased > 0 && <StepsToDo handleOpenKycModal={handleOpenKycModal} />}
                                {totalPurchased == 0 && <Quest />}
                            </div>
                            <div className="flex flex-col w-full 2xl:w-1/2 gap-10 h-fit">
                                {totalPurchased > 0 && <Balance/>}
                                {totalPurchased > 0 && <Buy />}
                                {totalPurchased > 0 && <Help />}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-full xl:w-1/2 2xl:w-1/3">
                        <Status />
                    </div>
                </div>
                <SynapsModal
                  isOpened={synapsModalOpened}
                  closeModal={() => setSynapsModalOpened(false)}
                  sessionId={user?.synapsSessions || ''}
                  service={SERVICE}
                  lang={LANG}
                  finishButton={true}
                  handleProcessFinished={handleProcessFinished}
                />
            </div>
        </>

    )
}