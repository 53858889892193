
export const checkValidity = (value, rules) => {
    let isValid = true;

    if (rules.required) {
        isValid = value.trim() !== '' && isValid
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.email) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid;
    }

    return isValid;
}

export const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const makeIntId = (length) => {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const toDecimalString = (num, digits) => {
    const n = Number(num);
    const fixed = n.toFixed(digits); // Utilisez 18 décimales pour une précision suffisante
    return fixed.replace(/\.?0+$/, ""); // Supprimez les zéros inutiles à droite
};

export const ERC20_ABI = [
    {
        constant: true,
        inputs: [],
        name: "name",
        outputs: [{name: "", type: "string"}],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{name: "", type: "uint8"}],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [{name: "", type: "string"}],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: true,
        inputs: [{name: "owner", type: "address"}],
        name: "balanceOf",
        outputs: [{name: "balance", type: "uint256"}],
        payable: false,
        stateMutability: "view",
        type: "function",
    },
    {
        constant: false,
        inputs: [
            {name: "to", type: "address"},
            {name: "value", type: "uint256"},
        ],
        name: "transfer",
        outputs: [{name: "success", type: "bool"}],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
    },
];

/**
 * Delete spaces from string passed in params
 * @param string
 * @returns {*}
 */
export const stringDeleteSpace = (string) => {
    return string.replace(/\s/g, '');
}

/**
 * Replace multiple spaces by single one from string passed in params
 * @param string
 * @returns {*}
 */
export const stringReplaceDoubleSpaceBySimple = (string) => {
    return string.replace(/  +/g, ' ');
}