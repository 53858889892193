import React from "react";

export default function Card({ children, title, icon, withBorder = false, withMinH = true }) {
  return (
    <div className={`flex flex-col gap-5 w-full bg-white bg-opacity-5 border-white border-opacity-10 overflow-hidden rounded-3xl p-10 text-white ${withMinH ? 'min-h-[490px]' : ''} ${withBorder ? 'border-2' : 'border-0'}`} style={{ filter: "brightness(105%)", borderRadius: '2.33rem' }}>
      {title && (
        <div className="flex gap-3 mb-6 items-center">
          {icon}
          <h1 className="uppercase font-passionOne text-white md:text-[30px]">{title}</h1>
        </div>
      )}
      {children}
    </div>
  );
};