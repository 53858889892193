import React, {Fragment} from 'react'
import {Dialog, Transition} from "@headlessui/react";
import {useTranslation} from "react-i18next";
import {LiaTimesSolid} from "react-icons/lia";
import Button from "../Button/Button";
import {BsFillExclamationDiamondFill} from "react-icons/bs";
import {FaExternalLinkAlt} from "react-icons/fa";
import {FaRegCopy} from "react-icons/fa";
import { MLCTokenContract } from "../../firebase";

export default function SeeTokenModal({
    isOpened,
    closeModal,
  }) {
  const {t} = useTranslation();

  const copyAddr = (id) => {
    navigator.clipboard.writeText(id);
    alert("Token copied!");
};


const goToHowToAddToken = () => {
  window.open('https://whitepaper.mylovelyplanet.org/lovely-family-mlc-investors/view-your-usdmlc-on-metamask');
};

  return (
    <Transition appear show={isOpened} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-primary bg-opacity-60 backdrop-blur-sm"/>
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-3xl bg-gradient-to-b from-primary-modalBlue to-primary-customDark p-12 gap-5 flex flex-col text-left align-middle shadow-xl transition-all">
                <button onClick={closeModal} className="absolute top-8 right-10 font-bold rounded-2xl border-2 border-white border-opacity-15 p-4 hover:border-opacity-30">
                  <LiaTimesSolid size={30} color="white"/>
                </button>
                
                <Dialog.Title as="h1" className="text-5xl font-passionOne text-white bg-clip-text uppercase mb-1">
                  &nbsp;
                </Dialog.Title>
                <p className="flex text-primary-white text-xl font-passionOne items-center">
                  {t('Buy.HowToSeeMyTokenMsg1')}
                </p>

                <p className="text-xl text-slate-300 font-passionOne" >
            {t('Dashboard.TokenAddr')} : <br />
                <span onClick={() => copyAddr(MLCTokenContract)} className="bg-primary-white whitespace-nowrap bg-opacity-20 px-3 py-1 rounded-xl ml-2 text-white font-sans text-sm cursor-pointer">
                    {MLCTokenContract}
                    <FaRegCopy className="inline ml-3 mb-0.5" />
                </span>
            </p>

                <p className="flex text-primary-white text-xl font-passionOne items-center">
                  {t('Buy.HowToSeeMyTokenMsg2')}
                </p>
                
                <div className="mt-2 flex w-full gap-5 justify-center">
                    <Button onClick={goToHowToAddToken} color="dark" size="large" iconafter={<FaExternalLinkAlt size={16} />} blockclass='w-fit'>
                      {t('Buy.HowToSeeMyTokenMsgLink')}
                  </Button>
                </div>
                {/* <div className="flex items-center justify-between text-primary-white text-xl font-passionOne gap-4 bg-primary-white bg-opacity-5 py-7 px-9 rounded-2xl ring-1 ring-slate-50 ring-opacity-20">
                  <img src="https://assets.staticimg.com/g-biz/externals/2022-06-01/9bfcd9feaf2b8bed.svg" alt="kucoin" className="w-52" />
                  <a href="https://www.kucoin.com/" className="flex uppercase underline items-center justify-center gap-2" target="_blank">
                    Visit
                    <FaExternalLinkAlt size={16} />
                  </a>
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}