import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';

export default function Countdown(props) {

    const {t} = useTranslation();
    const [timerDays, setTimerDays] = useState();
    const [timerHours, setTimerHours] = useState();
    const [timerMin, setTimerMin] = useState();
    const [timerSec, setTimerSec] = useState();
    const [countDownDate, setCountDownDate] = useState("");

    let interval;

    const startTimer = () => {
        // console.log(props.date);
        setCountDownDate(new Date(props.date).getTime())
        
        if (!isNaN(countDownDate)) {
            interval = setInterval(() => {
                const now = new Date().getTime()
                const distance = countDownDate - now
                // console.log({distance, date : new Date(props.date)});

                const days = Math.floor(distance / (24 * 60 * 60 * 1000))
                const hours = Math.floor((distance % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000))
                const minutes = Math.floor((distance % (60 * 60 * 1000)) / (60 * 1000))
                const seconds = Math.floor((distance % (60 * 1000)) / 1000)

                if (distance < 0) {
                    clearInterval(interval.current)
                } else {
                    setTimerDays(days)
                    setTimerHours(hours)
                    setTimerMin(minutes)
                    setTimerSec(seconds)
                    // console.log({timerDays,timerHours,timerMin,timerSec})
                }
            })
        }
    }

    useEffect(() => {

        startTimer()
    }, [countDownDate]);

    if (props.kyc) {
        if (timerDays !== undefined && timerHours !== undefined && timerMin !== undefined && timerSec !== undefined) {
            return (
                <p className="font-passionOne text-xl text-white">
                    {t('General.DaysLeft') + " " + timerDays + " " + t('General.Days') + " " + timerHours + " " + t('General.Hours') + " " + timerMin + " " + t('General.Min') + " " + timerSec + " " + t('General.Sec') + " " + t('General.DaysLeftAfter')}
                </p>
            )
        } else {
            return (
                <span></span>
            )
        }
    }

    if (props.airdrop) {
        if (timerDays !== undefined && timerHours !== undefined && timerMin !== undefined && timerSec !== undefined && (timerDays > 0 || timerHours > 0 || timerMin > 0 || timerSec > 0)) {
            return (
                <p className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                    <span>{t('General.DaysLeft')} <span className="text-orange-400"> {timerDays} {t('General.Days')} {timerHours} {t('General.Hours')} {timerMin} {t('General.Min')} {timerSec} {t('General.Sec')}</span> {t('QuestCountDown.MsgAlert')}</span>
                </p>
            )
        } else {
            return (
                <span></span>
            )
        }
    }

    return (
        <div className="flex gap-2 w-full p-2 text-white">
            <div className="flex flex-col bg-white bg-opacity-15 rounded-3xl w-1/4 p-2">
                <p className="text-white font-passionOne text-xl md:text-3xl text-center">{timerDays}</p>
                <p className="text-white opacity-50 font-passionOne text-lg md:text-xl uppercase text-center">{t('General.Days')}</p>
            </div>
            <div className="flex flex-col bg-white bg-opacity-15 rounded-3xl w-1/4 p-2">
                <p className="text-white font-passionOne text-xl md:text-3xl text-center">{timerHours}</p>
                <p className="text-white opacity-50 font-passionOne text-lg md:text-xl uppercase text-center">{t('General.Hours')}</p>
            </div>
            <div className="flex flex-col bg-white bg-opacity-15 rounded-3xl w-1/4 p-2">
                <p className="text-white font-passionOne text-xl md:text-3xl text-center">{timerMin}</p>
                <p className="text-white opacity-50 font-passionOne text-lg md:text-xl uppercase text-center">{t('General.Min')}</p>
            </div>
            <div className="flex flex-col bg-white bg-opacity-15 rounded-3xl w-1/4 p-2">
                <p className="text-white font-passionOne text-xl md:text-3xl text-center">{timerSec}</p>
                <p className="text-white opacity-50 font-passionOne text-lg md:text-xl uppercase text-center">{t('General.Sec')}</p>
            </div>

        </div>
    )
}