import {TbCoins} from "react-icons/tb";
import BuyModal from "../BuyModal/BuyModal";
import SeeTokenModal from "../BuyModal/SeeTokenModal";
import React, {useEffect, useContext, useState} from "react";
import Card from "../Card/Card";
import token3D from "../../assets/img/token_1_3D.png";
import TextWithLoader from "../TextWithLoader/TextWithLoader";
import Button from "../Button/Button";
import {useTranslation} from "react-i18next";
import { UserContext } from "../../hooks/useUser";
import {FaRegCopy} from "react-icons/fa";
import { auth } from "../../firebase";

export default function Buy() {
    const {t} = useTranslation();
    const [buyModalOpened, setBuyModalOpened] = useState(false);
    const [seeTokenOpened, setSeeTokenOpened] = useState(false);
    const [isClaimFeatureDisabled, setIsClaimFeatureDisabled] = useState(true);
    const [coinBalance, setCoinBalance] = useState(-1);
    const [coinBalanceTxt, setCoinBalanceTxt] = useState('?');
    const [coinBalanceTxtError, setCoinBalanceTxtError] = useState();
    const {user, web3TokenBalance, siteFeatureClaimToken} = useContext(UserContext);


    const getCoinBalance = async () => {
        setTimeout(() => {
            if (web3TokenBalance === -2) {
                setCoinBalanceTxtError('loading error, fund are safe')
                setCoinBalance(0)
            } else {
                setCoinBalanceTxt(web3TokenBalance.toString())
                setCoinBalance(web3TokenBalance)
            }
        }, 500);
    };


    const copyAddr = (id) => {
        navigator.clipboard.writeText(id);
        alert("Token copied!");
    };

    const isDateAfterListing = () => {
        const now = new Date().getTime();
        // console.log(`${now} > 1719340000000`);
        return now > 1719340000000; // if current timestamp > than 25/06/2024 20:26:40 timestamp
    }

    useEffect(() => {
        getCoinBalance()
    }, [coinBalance, web3TokenBalance]);

    useEffect(() => {
        if (siteFeatureClaimToken && siteFeatureClaimToken === '1') {
            setIsClaimFeatureDisabled(false);
        } else {
            setIsClaimFeatureDisabled(true);
        }
    }, [user, auth, siteFeatureClaimToken]);


    return (
        <Card title={t('Dashboard.BuyTokens')} icon={<TbCoins size={40}/>} withMinH={false} withBorder>
            <BuyModal
                isOpened={buyModalOpened}
                closeModal={() => setBuyModalOpened(false)}
            />
            <SeeTokenModal
                isOpened={seeTokenOpened}
                closeModal={() => setSeeTokenOpened(false)}
            />
            <div className="flex justify-start items-center gap-5">
                <img src={token3D} alt="" className="w-24"/>
                <div className="flex gap-2">
                    <p className={"text-xl text-white uppercase font-passionOne " + (coinBalanceTxt.length > 8 ? 'lg:text-3xl' : 'lg:text-5xl')}>
                        <TextWithLoader isLoading={coinBalance === -1} errorMessage={coinBalanceTxtError}>{coinBalanceTxt}</TextWithLoader>
                    </p>
                    <p className="text-xl text-white opacity-50 lg:text-base uppercase font-passionOne">$MLC</p>
                </div>
            </div>
            {/* <p className="text-xl text-slate-300 font-passionOne" >
            {t('Dashboard.TokenAddr')} : <br />
                <span onClick={() => copyAddr(MLCTokenContract)} className="bg-primary-white whitespace-nowrap bg-opacity-20 px-3 py-1 rounded-xl ml-2 text-white font-sans text-sm cursor-pointer">
                    {MLCTokenContract}
                    <FaRegCopy className="inline ml-3 mb-0.5" />
                </span>
            </p> */}
            {/* <span>
                <Button onClick={() => setSeeTokenOpened(true)} color="primary" size="large">
                    {t('Buy.HowToSeeMyToken')}
                </Button>
            </span> */}
        </Card>
    );
}