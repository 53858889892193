// libraries
import {useContext, useEffect, useState} from 'react';
// components
import {useTranslation} from 'react-i18next';
import Help from "../../components/Help/Help";
import QuestCountDown from "../../components/QuestCountDown/QuestCountDown";
import {UserContext} from "../../hooks/useUser";
import QuestEligibility from '../../components/QuestEligibility/QuestEligibility';
import Button from "../../components/Button/Button";
import QuestEligibilityButton from '../../components/QuestEligibilityButton/QuestEligibilityButton';


export default function Dashboard() {
    const [checkEligInProgress, setCheckEligInProgress] = useState(false);
    const [checkEligLaunched, setCheckEligLaunched] = useState(false);
    const {t} = useTranslation();
;


    return (
        <>
            <div className="flex flex-col bg-transparent gap-5 overflow-y-auto">
                <h1 className="font-passionOne text-xl md:text-5xl uppercase text-white mb-5">{t('AirDrops.Title')}</h1>
                <div className="flex flex-col xl:flex-row w-full gap-10">
                    <QuestEligibilityButton
                    checkEligLaunched={checkEligLaunched}
                    setCheckEligLaunched={setCheckEligLaunched}
                    checkEligInProgress={checkEligInProgress}
                    setCheckEligInProgress={setCheckEligInProgress}
                    />
                </div>
                {checkEligLaunched &&
                <div className="flex flex-col xl:flex-row w-full gap-10">
                    <div className="flex flex-col w-full w-1/2 gap-10">
                       <QuestEligibility subquest='classic' />
                    </div>
                    <div className="flex flex-col w-full w-1/2">
                        <QuestEligibility subquest='tw' />
                        {/* <QuestCountDown /> */}
                    </div>
                </div>
                }
            </div>
        </>

    )
}