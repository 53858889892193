// Libraries
import React, {useContext, useEffect, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {onAuthStateChanged, indexedDBLocalPersistence} from "@firebase/auth";
import {MLCTokenContract, auth, db, isProd, validPolygonChainId} from "./firebase";
import axios from "axios";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// Components
import MenuLayout from './MenuLayout/MenuLayout';
import AppContainer from "./containers/AppContainer";
import Dashboard from "./containers/Dashboard/Dashboard";
import Signin from "./containers/Signin/Signin";
import Signup from "./containers/Signup/Signup";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import routes from "./route";
import Profile from "./containers/Profile/Profile";
import Transactions from "./containers/Transactions/Transactions";
import AirDrops from "./containers/AirDrops/AirDrops";
import {UserContextProvider} from "./hooks/useUser";
import {UserContext} from "./hooks/useUser";
import SessionExpired from "./SessionExpired";
import VersionUpdate from "./VersionUpdate";
import {extMainApiUrl} from "./firebase";
import './App.css';
import ResetPassword from './containers/ResetPassword/ResetPassword';

export default function App(props) {
    let navigate = useNavigate()
    const location = useLocation();
    const {getToken} = useContext(UserContext);
    const [authChecker, setAuthChecker] = useState();
    const [versionChecker, setVersionChecker] = useState();
    const [sessionExpiredIsOpened, setSessionExpiredIsOpened] = useState(false);
    const [versionUpdateIsOpened, setVersionUpdateIsOpened] = useState(false);
    const [selectedLang, setSelectedLang] = useState("US");

    const handleCloseModal = async () => {
        setSessionExpiredIsOpened(false)
        setVersionUpdateIsOpened(false)
        document.location.reload(true)
    }

    useEffect(() => {
      // app version checker
      const locVerInt = setInterval(() => {
          try {
              axios.get(`${extMainApiUrl}/getVersions`).then(async res => {
                  if (res && res.data && res.data.siteAppPubSalesVersion && res.data.siteAppPubSalesVersion.toString() !== '') {
                      const siteAppPubSalesVersion = res.data.siteAppPubSalesVersion.toString();
                      let currentBrowserVersion = localStorage.getItem('siteAppPubSalesVersion') || '';
                      if (currentBrowserVersion) {
                          currentBrowserVersion = currentBrowserVersion.toString();
                      }
                      if (currentBrowserVersion === '') {
                          currentBrowserVersion = siteAppPubSalesVersion;
                          localStorage.setItem('siteAppPubSalesVersion', siteAppPubSalesVersion);
                      } else {
                          if (siteAppPubSalesVersion !== currentBrowserVersion && Number(siteAppPubSalesVersion.replace('.','')) > Number(currentBrowserVersion.replace('.',''))) {
                              // open modal
                              setVersionChecker(null);
                              localStorage.setItem('siteAppPubSalesVersion', siteAppPubSalesVersion);
                              setVersionUpdateIsOpened(true);
                              clearInterval(versionChecker);
                          }
                      }
                  }
                }).catch((err) => {
                console.error(err);
              });
          } catch (error) {
            console.error(error);
          }
      }, 15000);

      // auth user auto refresh
      let userFailureCnt = 0;
      const locAuthInt = setInterval(async () => {
      if (!authChecker && auth && auth.currentUser && auth.currentUser.uid) {
              console.log('%cUsrAuthCheck ','color: #FF00FF');
              await getToken()
              let localUser = null;
              try {
                  const tmpToken = await auth.currentUser.getIdToken()
                  if (tmpToken) {
                      // refresh it in the store
                      await getToken()
                      const userId = auth.currentUser.uid;
                      const res = await axios.get(`${db}Users/${userId}.json?auth=${tmpToken}`);
                      if (res.data) {
                          localUser = res.data;
                      }
                  }
              } catch (error) {
                  console.error(error);
              }
              // console.log({localUser});
              if (!localUser) {
                  userFailureCnt++;
                  if (userFailureCnt > 2) {
                    // open modal
                    setSessionExpiredIsOpened(true);
                    clearInterval(authChecker);
                    setAuthChecker(null);
                  }
              } else {
                userFailureCnt = 0;
                setAuthChecker(locAuthInt);
              }
            }
          }, 60000);

          // Handle auth state change
          onAuthStateChanged(auth, async (data) => {
          console.log('onAuthStateChanged', {data})
          // Use indexedDB storage
          await auth.setPersistence(indexedDBLocalPersistence);
          if (data && data.uid) {
            if (location.pathname !== '/reset-password') {
              navigate(routes.DASHBOARD)
            }
          } else {
              if (location.pathname !== '/register' && location.pathname !== '/reset-password') {
                  navigate(routes.LOGIN)
              }
            }
      })

      return () => {
        clearInterval(locAuthInt)
        clearInterval(locVerInt)
      }
      
    }, []);
  
    // Web3Modal setup
    // 1. Get projectId at https://cloud.walletconnect.com
    const projectId = '73618b682785f1b2692e0e633db5f4fa'

    // 2. Set chains
    // const mainnet = {
    //   chainId: 1,
    //   name: 'Ethereum',
    //   currency: 'ETH',
    //   explorerUrl: 'https://etherscan.io',
    //   rpcUrl: 'https://cloudflare-eth.com'
    // }
    const mainnet = {
      chainId: validPolygonChainId,
      name: 'Polygon',
      currency: 'MATIC',
      explorerUrl: 'https://polygonscan.com/',
      rpcUrl: 'https://polygon-rpc.com/',
    }
    // const testnet = {
    //   chainId: validPolygonChainId,
    //   name: 'Polygon Testnet (Mumbai)', // mumbai is deprecated
    //   currency: 'MATIC',
    //   explorerUrl: 'https://mumbai.polygonscan.com/',
    //   rpcUrl: 'https://polygon-mumbai-bor-rpc.publicnode.com',
    //   // rpcUrl: 'https://endpoints.omniatech.io/v1/matic/mumbai/public',
    // }
    const testnet = {
      chainId: validPolygonChainId,
      name: 'Polygon Testnet (Amoy)',
      currency: 'MATIC',
      explorerUrl: 'https://www.oklink.com/amoy',
      rpcUrl: 'https://polygon-amoy-bor-rpc.publicnode.com',
      // rpcUrl: 'https://rpc-amoy.polygon.technology/',
    }

    let currentNetwork = mainnet;
    if (!isProd) {
      // currentNetwork = testnet;
      currentNetwork = mainnet;
    }
    // 3. Create modal
    const metadata = {
      name: 'My Lovely Planet',
      description: 'MLP Web3 Connect',
      url: 'https://dashboard.mylovelyplanet.org/', // origin must match your domain & subdomain - localhost always authorized
      icons: ['https://avatars.mywebsite.com/']
    }

    createWeb3Modal({
      ethersConfig: defaultConfig({ metadata }),
      chains: [currentNetwork],
      projectId,
      enableAnalytics: true, // Optional - defaults to your Cloud configuration
      allWallets: 'HIDE',
      tokens: {
        validPolygonChainId: {
          address: MLCTokenContract,
          image: 'https://api.mylovelyplanet.org/fstaticzz/mlc.png',
        },
      },
      includeWalletIds: [
        'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b', // walletConnect
        // 'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // MetaMask
        // '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // TrustWallet
      ],
      // excludeWalletIds: [
      //   '225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f', //SafeWallet
      //   '',
      //   '',
      //   '',
      // ]
      themeVariables: {
        '--w3m-color-mix': '#5A47CA',
        '--w3m-accent': '#FFFFFF',
        '--w3m-color-mix-strength': 30,
        '--w3m-font-family': 'Passion One',
        '--w3m-font-size-master': 30,
      }
    })

    return (
        <UserContextProvider>
          <AppContainer setSelectedLang={setSelectedLang}>
            <Routes>
                {auth.currentUser &&
                    (<Route path="/admin" element={
                      <MenuLayout
                        selectedLang={selectedLang}
                        setSelectedLang={setSelectedLang}
                      />
                    }>
                        <Route index element={<Dashboard/>}></Route>
                        <Route path={routes.PROFILE} element={<Profile/>}></Route>
                        <Route path={routes.TRANSACTIONS} element={<Transactions/>}></Route>
                        <Route path={routes.AIRDROPS} element={<AirDrops/>}></Route>
                    </Route>)}
                <Route path={routes.LOGIN} element={<Signin/>}></Route>
                <Route path={routes.REGISTER} element={<Signup/>}></Route>
                <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword/>}></Route>
                <Route path={routes.RESET_PASSWORD} element={<ResetPassword/>}></Route>
                <Route render={() => <h1>404</h1>}></Route>
            </Routes>
            <SessionExpired
                isOpen={sessionExpiredIsOpened}
                closeModal={handleCloseModal}
            />
            <VersionUpdate
                isOpen={versionUpdateIsOpened}
                closeModal={handleCloseModal}
            />
          </AppContainer>
        </UserContextProvider>
    )
}