import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {useTranslation} from "react-i18next";
import {UserContext} from "../../hooks/useUser";
import {extMainApiUrl} from "../../firebase";
import {auth, customAlertProps} from "../../firebase";
import Swal from "sweetalert2";
import Bugsnag from "@bugsnag/js";
import axios from "axios";
import Button from '../Button/Button';
import {LiaTimesSolid} from "react-icons/lia";

const VerifyEmailModal = ({isOpen, closeModal}) => {

    const {t} = useTranslation();
    const [isSent, setIsSent] = useState(false);
    const [code, setCode] = useState("");
    const [isLoadingEmailCheck, setIsLoadingEmailCheck] = useState(false);
    const {user, updateUser} = useContext(UserContext)
    
    const handleCloseModal = () => {
        closeModal()
    };

    const handleSendEmail = async () => {
        setIsLoadingEmailCheck(true);
        try {
            await axios.post(`${extMainApiUrl}/sendVerifyCode`, {
                userId: auth.currentUser.uid,
            });
            setIsSent(true);
            Swal.fire({
                title: 'Good!',
                text: t('Profile.EmailSent'),
                icon: 'success',
                confirmButtonText: 'Cool',
                ...customAlertProps,
            });
        } catch (error) {
            Bugsnag.notify(error);
        }
        setIsLoadingEmailCheck(false);
    }

    const handleCheckCode = async () => {
        setIsLoadingEmailCheck(true);
        if (code && code.length === 6) {
            try {
                const response = await axios.post(`${extMainApiUrl}/checkVerifyCode`, {
                    userId: auth.currentUser.uid,
                    code: code,
                });

                if (response.data === 'OK') {
                    // reload the user with up to date data
                    await updateUser({emailVerified: true});
                    // give time to loader to be displayed
                    setTimeout(() => {
                        handleCloseModal()
                    }, 2000);
                } else {
                    Swal.fire({
                        title: 'Oups!',
                        text: "Code is wrong",
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        ...customAlertProps,
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: 'Oups!',
                    text: "Code is wrong",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    ...customAlertProps,
                });
            }
        } else {
            Swal.fire({
                title: 'Oups!',
                text: "Code is wrong",
                icon: 'error',
                confirmButtonText: 'Ok',
                ...customAlertProps,
            });
        }
        setIsLoadingEmailCheck(false)
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black-primary bg-opacity-60 backdrop-blur-sm"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-8 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                              className="w-full max-w-4xl transform overflow-hidden rounded-3xl bg-gradient-to-b from-primary-modalBlue to-primary-customDark p-12 gap-5 flex flex-col text-left align-middle shadow-xl transition-all">
                                <button onClick={() => handleCloseModal()} className="absolute top-8 right-10 font-bold rounded-2xl border-2 border-white border-opacity-15 p-4 hover:border-opacity-30">
                                    <LiaTimesSolid size={30} color="white"/>
                                </button>

                                <Dialog.Title as="h1" className="text-5xl font-passionOne text-white bg-clip-text uppercase mb-10">
                                    {t('RecoveryAddress.MsgContent.EmailVerification')}
                                </Dialog.Title>


                                      <div className="flex flex-col w-full gap-5">
                                          <div>
                                              {/* <h2
                                                className="text-white uppercase font-passionOne text-3xl">{t('RecoveryAddress.MsgContent.EmailVerification')}</h2> */}
                                              <h2
                                                className="w-100 text-left text-white text-opacity-50 uppercase font-passionOne text-xl">{t('RecoveryAddress.MsgContent.EmailVerificationExplanationOnly')}</h2>
                                          </div>
                                      </div>
                                      <div className={"mt-2 flex w-full gap-5 justify-center items-center " + (isSent ? "flex-col" : "")}>
                                          {isSent &&
                                            (
                                              <input
                                                type="text"
                                                name="code"
                                                id="code"
                                                value={code}
                                                onChange={(e) => setCode(e.target.value)}
                                                className="w-full text-white font-passionOne px-6 py-4 bg-primary-dark bg-opacity-30 rounded-xl xl:text-xl text-center"
                                                placeholder={t('RecoveryAddress.Auth.InputPlaceholder')}
                                              />
                                            )
                                          }
                                          {!isSent &&
                                            <Button onClick={handleSendEmail} disabled={isLoadingEmailCheck} color="dark" size="large" blockclass='w-fit'>
                                                {isLoadingEmailCheck && t('RecoveryAddress.Auth.SendEmailInProgress')}
                                                {!isLoadingEmailCheck && t('RecoveryAddress.Auth.SendEmailButton')}
                                            </Button>
                                          }

                                          {isSent &&
                                            <Button onClick={handleCheckCode} disabled={isLoadingEmailCheck} color="dark" size="large" blockclass='w-fit'>
                                                {isLoadingEmailCheck && t('RecoveryAddress.Auth.ValidationInProgress')}
                                                {!isLoadingEmailCheck && t('RecoveryAddress.Auth.ValidateCodeButton')}
                                            </Button>
                                          }
                                      </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default VerifyEmailModal;
