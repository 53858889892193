import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {useTranslation} from "react-i18next";
import {UserContext} from "./../../hooks/useUser";
import {extMainApiUrl, extMainApiUrlForced, validPolygonChainId} from "../../firebase";
import {auth, customAlertProps} from "../../firebase";
import Swal from "sweetalert2";
import Bugsnag from "@bugsnag/js";
import axios from "axios";
import Button from '../Button/Button';
import { useWeb3Modal, useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import mlc3D from "../../assets/img/token_1_3D.png"
import {LiaTimesSolid} from "react-icons/lia";
import {HiArrowSmRight, HiArrowSmLeft} from "react-icons/hi";
import {FaCircle} from "react-icons/fa";
import {TbInfoHexagon} from "react-icons/tb";
import i18n from '../../i18n';

const AddWalletAddress = ({isOpen, closeModal, step, openTutorialNoWallet}) => {

    const {t} = useTranslation();
    const videoByLocale = {
        us: 'https://www.youtube.com/embed/OZrQg61Edvo?si=D-Vz7eZDRNT8Kn68',
        fr: 'https://www.youtube.com/embed/TKhnIRx1_i4?si=s5YlEHBRwZmo7LEl',
    };
    const [currentVideoLocale, setCurrentVideoLocale] = useState('us');
    const [isSent, setIsSent] = useState(false);
    const [consenStep1, setConsenStep1] = useState(false);
    const [consenStep2, setConsenStep2] = useState(false);
    const [consenStep41, setConsenStep41] = useState(false);
    const [consenStep42, setConsenStep42] = useState(false);
    const [consenStep43, setConsenStep43] = useState(false);
    const [code, setCode] = useState("");
    const [isLoadingEmailCheck, setIsLoadingEmailCheck] = useState(false);
    const [isLoadingEmailCode, setIsLoadingEmailCode] = useState(false);
    const [isLoadingAddressLinking, setIsLoadingAddressLinking] = useState(false);
    const {user, setUser} = useContext(UserContext)
    // Web3Modal states
    const [browserWalletIsConnected, setBrowserWalletIsConnected] = useState(false);
    const [chainIdIsValid, setChainIdIsValid] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [linkedAddressIsValid, setLinkedAddressIsValid] = useState(false);
    const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { open: openWeb3WalletConnect } = useWeb3Modal({ view: 'Connect' })
    const { open: openWeb3WalletAccount } = useWeb3Modal({ view: 'Account' })
    const { open: openWeb3WalletNetwork } = useWeb3Modal({ view: 'Networks' })
    const { walletProvider } = useWeb3ModalProvider()

    
    useEffect(() => {
        if (i18n && i18n.language && i18n.language.toLowerCase() !== '') {
            setCurrentVideoLocale(i18n.language.toLowerCase())
        }
        // Modal step default
        if (step || !isNaN(step)) {
            if (currentStep && step > currentStep) {
                window.localStorage.setItem('addWalletStep', step)
                window.localStorage.setItem('addWalletStepValidity', new Date().getTime())
            }
        }
        if (window.localStorage.getItem('addWalletStep') && window.localStorage.getItem('addWalletStep') >= 1) {
            // preset the step only of last know step is not greater than 15 minutes (900000)
           if (window.localStorage.getItem('addWalletStepValidity') && (new Date().getTime() - window.localStorage.getItem('addWalletStepValidity')) < 900000) {
            setCurrentStep(Number(window.localStorage.getItem('addWalletStep')))
        } else {
            setCurrentStep(1)
            window.localStorage.setItem('addWalletStep', 1)
            window.localStorage.setItem('addWalletStepValidity', new Date().getTime())
           }
        }
        setBrowserWalletIsConnected(isConnected)
        if (Number(chainId) === validPolygonChainId) { // Polygon network
          setChainIdIsValid(true)
        } else {
          setChainIdIsValid(false)
        }
    }, [i18n.language, isOpen, auth, user, isConnected, chainId, address, walletProvider, currentStep, window.localStorage.getItem('addWalletStep')]);

    const goBack = () => {
        window.localStorage.setItem('addWalletStep', currentStep - 1)
        window.localStorage.setItem('addWalletStepValidity', new Date().getTime())
        setCurrentStep(currentStep - 1)
    };
    const goNext = () => {
        if (currentStep === 2) {
            if (!consenStep1) {
                Swal.fire({
                    title: '',
                    text: t('RecoveryAddress.MsgContent.ErrorStep1'),
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    ...customAlertProps,
                });
                return;
            }
        }
        if (currentStep === 3) {
            if (!consenStep2) {
                Swal.fire({
                    title: '',
                    text: t('RecoveryAddress.MsgContent.ErrorStep2'),
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    ...customAlertProps,
                });
                return;
            }
        }
        if (currentStep === 5) {
            if (!consenStep41 || !consenStep42 || !consenStep43) {
                Swal.fire({
                    title: '',
                    text: t('RecoveryAddress.MsgContent.ErrorStep4'),
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    ...customAlertProps,
                });
                return;
            }
        }
        window.localStorage.setItem('addWalletStep', currentStep + 1)
        window.localStorage.setItem('addWalletStepValidity', new Date().getTime())
        setCurrentStep(currentStep + 1)
    };

    const handleCloseModal = () => {
        closeModal()
        // let effect of close run
        setTimeout(() => {
            // reset current step
            // setCurrentStep(1)
        }, 500);
    };

    const forceRefresh = () => {
        window.location.reload();
    };

    const handleTutorialNoWalletModal = () => {
        closeModal()
        // let effect of close run
        setTimeout(() => {
            window.localStorage.setItem('addWalletStep', currentStep + 1)
            window.localStorage.setItem('addWalletStepValidity', new Date().getTime())
            setCurrentStep(currentStep + 1)
        }, 500);
        openTutorialNoWallet()
    };
    const wrappedModalOpen = (modalFuncName, forceRetry) => {
        try {
            if (modalFuncName === 'openWeb3WalletConnect') {
                openWeb3WalletConnect();
            }
            else if (modalFuncName === 'openWeb3WalletAccount') {
                openWeb3WalletAccount();
            }
        } catch (error) {
            console.error('modalOpenError', error)
            if (forceRetry === true) {
                // try again
                wrappedModalOpen(modalFuncName)
            }
        }
    };

    const linkWalletWithSignature = async () => {
        let linkingInProgress = false
        let signature = ''
        try {
            const message = t('RecoveryAddress.Step2Metamask.MessageToSign')
            // doc https://github.com/WalletConnect/web3modal/blob/5f444615cc35b31e0e295eb19e2c7587f30a52f4/packages/ethers/src/client.ts#L279
            if (walletProvider && browserWalletIsConnected && chainIdIsValid && address && address.toString() !== '') {
              const ethersProvider = new BrowserProvider(walletProvider)
              const signer = await ethersProvider.getSigner()
              signature = await signer.signMessage(message)
              linkingInProgress = true
            }
        } catch (error) {
            console.error(error);
            setIsLoadingAddressLinking(false)
            Swal.fire({
                title: 'Oups!',
                text: t('RecoveryAddress.MsgContent.ErrorStep5'),
                icon: 'error',
                confirmButtonText: 'Ok',
                ...customAlertProps,
            });
        }
        
        if (linkingInProgress === true && signature) {
            setIsLoadingAddressLinking(true)
            //   // test loader no backend call - handle success or error case (example test)
            //   setTimeout(() => {
            //     setIsLoadingAddressLinking(false)
            //     Swal.fire({
            //         title: 'Oups!',
            //         text: t('RecoveryAddress.MsgContent.ErrorStep5'),
            //         icon: 'error',
            //         confirmButtonText: 'Ok',
            //         ...customAlertProps,
            //     });
            //     // handleCloseModal()
            // }, 4000);
              // if 200 status, address is sucessfully linked or handle erre and go back to last step
              const url = `${extMainApiUrlForced}/user/signature-check?userId=${auth.currentUser.uid}&signature=${signature}&wallet=${address}`
              await axios.get(url).then(res => {
                  if (res.data) {
                    // reload the user with up to date data
                    setUser(res.data);
                    // give time to loader to be displayed
                    setTimeout(() => {
                        setIsLoadingAddressLinking(false)
                        handleCloseModal()
                    }, 2000);
                  } else {
                    setIsLoadingAddressLinking(false)
                    Swal.fire({
                        title: 'Oups!',
                        text: t('RecoveryAddress.MsgContent.ErrorStep5'),
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        ...customAlertProps,
                    });
                  }
              }).catch((error) => {
                console.error({error})
                let errorDetail = ''
                    if (error.response && error.response.data && error.response.data.error && error.response.data.error.toString() !== '') {
                        errorDetail = ` (${error.response.data.error.toString()})`
                    }
                    setIsLoadingAddressLinking(false)
                    Swal.fire({
                        title: 'Oups!',
                        text: t('RecoveryAddress.MsgContent.ErrorStep5') + errorDetail,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        ...customAlertProps,
                    });
              });
        }
      };

    const handleSendEmail = async () => {
        setIsLoadingEmailCode(true);
        try {
            await axios.post(`${extMainApiUrl}/sendVerifyCode`, {
                userId: auth.currentUser.uid,
                wallet: true,
            });
            setIsSent(true);
            Swal.fire({
                title: 'Good!',
                text: t('Profile.EmailSent'),
                icon: 'success',
                confirmButtonText: 'Cool',
                ...customAlertProps,
            });
        } catch (error) {
            Bugsnag.notify(error);
        }
        setIsLoadingEmailCode(false);
    }

    const handleCheckCode = async () => {
        setIsLoadingEmailCheck(true);
        if (code && code.length === 6) {
            try {
                const response = await axios.post(`${extMainApiUrl}/checkVerifyCode`, {
                    userId: auth.currentUser.uid,
                    code: code,
                    wallet: true,
                });

                if (response.data === 'OK') {
                    goNext()
                } else {
                    Swal.fire({
                        title: 'Oups!',
                        text: "Code is wrong",
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        ...customAlertProps,
                    });
                }
            } catch (error) {
                Swal.fire({
                    title: 'Oups!',
                    text: "Code is wrong",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    ...customAlertProps,
                });
            }
        } else {
            Swal.fire({
                title: 'Oups!',
                text: "Code is wrong",
                icon: 'error',
                confirmButtonText: 'Ok',
                ...customAlertProps,
            });
        }
        setIsLoadingEmailCheck(false)
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black-primary bg-opacity-60 backdrop-blur-sm"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-8 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                              className="w-full max-w-4xl transform overflow-hidden rounded-3xl bg-gradient-to-b from-primary-modalBlue to-primary-customDark p-12 gap-5 flex flex-col text-left align-middle shadow-xl transition-all">
                                <button onClick={() => handleCloseModal()} className="absolute top-8 right-10 font-bold rounded-2xl border-2 border-white border-opacity-15 p-4 hover:border-opacity-30">
                                    <LiaTimesSolid size={30} color="white"/>
                                </button>

                                <Dialog.Title as="h1" className="text-5xl font-passionOne text-white bg-clip-text uppercase mb-10">
                                    {t('RecoveryAddress.Auth.Title')}
                                </Dialog.Title>

                                {currentStep === 1 &&
                                  <>

                                      <div className="flex flex-col w-full gap-5">
                                          
                                          <div className="mt-2 flex w-full gap-5 justify-center">
                                          <Button onClick={() => handleTutorialNoWalletModal()} color="dark" size="large" blockclass='w-fit'>
                                                    {t('Profile.NoWallet')}
                                                </Button>
                                          <Button onClick={goNext} color="dark" size="large" blockclass='w-fit'>
                                              {t('Profile.IHaveAWallet')}
                                          </Button>
                                      </div>
                                    </div>
                                  </>
                                }

                                {currentStep === 2 &&
                                  <>

                                      <div className="flex flex-col w-full gap-5">
                                          <div>
                                              <h2
                                                className="text-white text-opacity-50 uppercase font-passionOne">{t('Common.Step2')}</h2>
                                              <h2
                                                className="text-white uppercase font-passionOne text-3xl">{t('RecoveryAddress.MsgContent.ImportantExplanation')}</h2>
                                          </div>
                                          {currentVideoLocale === 'fr' &&
                                          <div className="w-full aspect-w-16 aspect-h-9">
                                              <iframe src={videoByLocale['fr']}
                                                      frameBorder="0"
                                                      height="400"
                                                      className="w-full"
                                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                      allowFullScreen></iframe>
                                          </div>}
                                          {currentVideoLocale === 'us' &&
                                          <div className="w-full aspect-w-16 aspect-h-9">
                                              <iframe src={videoByLocale['us']}
                                                      frameBorder="0"
                                                      height="400"
                                                      className="w-full"
                                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                      allowFullScreen></iframe>
                                          </div>}
                                      </div>
                                      <div className="block">
                                          <div className="mt-2">
                                              <label className="inline-flex items-center">
                                                  <input type="checkbox"
                                                         className="w-8 h-8 rounded-xl bg-white"
                                                         checked={consenStep1}
                                                         onChange={(e) => setConsenStep1(e.target.checked)}
                                                  />
                                                  <span
                                                    className="ml-2 text-white text-lg md:text-xl xl:text-2xl font-passionOne uppercase">{t('RecoveryAddress.MsgContent.CheckVideoWatched')}</span>
                                              </label>
                                          </div>
                                      </div>
                                      <div className="mt-2 flex w-full gap-5 justify-center">
                                          <Button onClick={goBack} color="dark" size="large" iconbefore={<HiArrowSmLeft />} blockclass='w-fit'>
                                              {t('Common.PrevStep')}
                                          </Button>
                                          <Button onClick={goNext} color="dark" size="large" iconafter={<HiArrowSmRight />} blockclass='w-fit'>
                                              {t('Common.Nextstep')}
                                          </Button>
                                      </div>
                                  </>
                                }

                                {currentStep === 3 &&
                                  <>
                                      <div className="flex flex-col w-full gap-5">
                                          <div>
                                              <h2
                                                className="text-white text-opacity-50 uppercase font-passionOne">{t('Common.Step3')}</h2>
                                              <h2
                                                className="text-white uppercase font-passionOne text-3xl">{t('RecoveryAddress.MsgContent.Disclaimer')}</h2>
                                              <div
                                                className="w-full p-4 flex text-left text-sm rounded-2xl bg-primary-orange">
                                                  <div className="w-full mx-auto">
                                                    <span className="mr-6 font-sofiaSans font-bold mb-2">
                                                    {t('RecoveryAddress.MsgContent.DisclaimerDetails1')}
                                                        <br/>
                                                        {t('RecoveryAddress.MsgContent.DisclaimerDetails2')}
                                                    </span>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="block">
                                          <div className="mt-2">
                                              <label className="inline-flex items-center">
                                                  <input type="checkbox"
                                                         className="w-8 h-8 rounded-xl bg-white"
                                                         checked={consenStep2}
                                                         onChange={(e) => setConsenStep2(e.target.checked)}
                                                  />
                                                  <span
                                                    className="ml-2 text-white text-lg md:text-xl xl:text-2xl font-passionOne uppercase">{t('RecoveryAddress.MsgContent.CheckDisclaimer')}</span>
                                              </label>
                                          </div>
                                      </div>
                                      <div className="mt-2 flex w-full gap-5 justify-center">
                                          <Button onClick={goNext} color="dark" size="large" iconafter={<HiArrowSmRight />} blockclass='w-fit'>
                                              {t('Common.Nextstep')}
                                          </Button>
                                      </div>
                                  </>
                                }

                                {currentStep === 4 &&
                                  <>

                                      <div className="flex flex-col w-full gap-5">
                                          <div>
                                              <h2
                                                className="text-white text-opacity-50 uppercase font-passionOne">{t('Common.Step4')}</h2>
                                              <h2
                                                className="text-white uppercase font-passionOne text-3xl">{t('RecoveryAddress.MsgContent.EmailVerification')}</h2>
                                              <h2
                                                className="w-100 text-left text-white text-opacity-50 uppercase font-passionOne text-xl">{t('RecoveryAddress.MsgContent.EmailVerificationExplanation')}</h2>
                                          </div>
                                      </div>
                                      <div className={"mt-2 flex w-full gap-5 justify-center items-center " + (isSent ? "flex-col" : "")}>
                                          {isSent &&
                                            (
                                                <>
                                                <input
                                                    type="text"
                                                    name="code"
                                                    id="code"
                                                    value={code}
                                                    onChange={(e) => setCode(e.target.value)}
                                                    className="w-full text-white font-passionOne px-6 py-4 bg-primary-dark bg-opacity-30 rounded-xl xl:text-xl text-center"
                                                    placeholder={t('RecoveryAddress.Auth.InputPlaceholder')}
                                                />
                                                <Button onClick={handleCheckCode} disabled={isLoadingEmailCode} color="dark" size="large" blockclass='w-fit'>
                                                    {/* {isLoadingEmailCode && t('RecoveryAddress.Auth.ValidationInProgress')} */}
                                                    {/* {!isLoadingEmailCode && t('RecoveryAddress.Auth.ValidateCodeButton')} */}
                                                    {t('RecoveryAddress.Auth.ValidateCodeButton')}
                                                </Button>
                                                </>
                                            )
                                          }
                                          {!isSent &&
                                            <Button onClick={handleSendEmail} disabled={isLoadingEmailCheck} color="dark" size="large" blockclass='w-fit'>
                                                {/* {isLoadingEmailCheck && t('RecoveryAddress.Auth.SendEmailInProgress')} */}
                                                {/* {!isLoadingEmailCheck && t('RecoveryAddress.Auth.SendEmailButton')} */}
                                                {t('RecoveryAddress.Auth.SendEmailButton')}
                                            </Button>
                                          }

                                      </div>

{/* 
                                      <div className="mt-2 flex w-full gap-5 justify-center">
                                          <Button onClick={goNext} color="dark" size="large" blockclass='w-fit'>
                                              GO NEXT BYPASS TO DELETE
                                          </Button>
                                      </div> */}

                                  </>
                                }

                                {currentStep === 5 &&
                                  <>
                                      <div className="flex flex-col w-full gap-5">
                                          <div>
                                              <h2 className="text-white text-opacity-50 uppercase font-passionOne">{t('Common.Step5')}</h2>
                                              <h2 className="text-white uppercase font-passionOne text-3xl">{t('RecoveryAddress.MsgContent.LastDisclaimer')}</h2>
                                          </div>
                                      </div>
                                      <div className="block">
                                          <div className="mt-3">
                                              <label className="inline-flex items-center">
                                                  <input type="checkbox"
                                                         className="w-8 h-8 rounded-xl bg-white"
                                                         checked={consenStep41}
                                                         onChange={(e) => setConsenStep41(e.target.checked)}
                                                  />
                                                  <span
                                                    className="ml-2 text-white text-lg xl:text-xl font-passionOne uppercase">{t('RecoveryAddress.MsgContent.CheckLastDis1')}</span>
                                              </label>
                                          </div>
                                          <div className="mt-3">
                                              <label className="inline-flex items-center">
                                                  <input type="checkbox"
                                                         className="w-8 h-8 rounded-xl bg-white"
                                                         checked={consenStep42}
                                                         onChange={(e) => setConsenStep42(e.target.checked)}
                                                  />
                                                  <span
                                                    className="ml-2 text-white text-lg xl:text-xl font-passionOne uppercase">{t('RecoveryAddress.MsgContent.CheckLastDis2')}</span>
                                              </label>
                                          </div>
                                          <div className="mt-3">
                                              <label className="inline-flex items-center">
                                                  <input type="checkbox"
                                                         className="w-8 h-8 rounded-xl bg-white"
                                                         checked={consenStep43}
                                                         onChange={(e) => setConsenStep43(e.target.checked)}
                                                  />
                                                  <span
                                                    className="ml-2 text-white text-lg xl:text-xl font-passionOne uppercase">{t('RecoveryAddress.MsgContent.CheckLastDis3')}</span>
                                              </label>
                                          </div>
                                      </div>
                                      <div className="mt-2 flex w-full gap-5 justify-center">
                                          <Button onClick={goNext} disabled={isLoadingEmailCheck} color="dark" iconafter={<HiArrowSmRight />} size="large" blockclass='w-fit'>
                                              {t('Common.Nextstep')}
                                          </Button>
                                      </div>
                                  </>
                                }

                                {currentStep === 6 && !isLoadingAddressLinking &&
                                  <>
                                      <div className="flex flex-col w-full gap-5">
                                          <div>
                                              <h2
                                                className="text-white text-opacity-50 uppercase font-passionOne">{t('Common.Step6')}</h2>
                                              <h2
                                                className="text-white uppercase font-passionOne text-3xl">{t('RecoveryAddress.MsgContent.WalletLinking')}</h2>
                                              <h2
                                                className="w-100 text-left text-white text-opacity-50 uppercase font-passionOne text-xl">{t('RecoveryAddress.MsgContent.WalletLinkingExplanation')}</h2>
                                              {/* <h2
                                                className="w-100 text-left text-white text-opacity-50 uppercase font-passionOne text-xl mt-1">{t('RecoveryAddress.MsgContent.WalletLinkingExplanation2')}</h2>
                                              <h2
                                                className="w-100 text-left text-white text-opacity-50 uppercase font-passionOne text-xl mt-1">{t('RecoveryAddress.MsgContent.WalletLinkingExplanation3')}</h2> */}
                                          </div>
                                      </div>

                                      <div className="flex flex-col w-full gap-3 items-center">
                                          {(!browserWalletIsConnected) && (
                                            <>
                                                <Button onClick={() => wrappedModalOpen('openWeb3WalletConnect', true)} color="dark" size="large" blockclass='w-fit'>
                                                    {t('Profile.WalletStatusToConnect')}
                                                </Button>
                                                <Button onClick={() => forceRefresh()} color="dark" size="large" blockclass='w-fit'>
                                                    {t('Profile.IdontSeeMyWallet')}
                                                </Button>
                                            </>
                                          )}
                                          {(browserWalletIsConnected && !chainIdIsValid) && (
                                            <>
                                                <Button onClick={() => openWeb3WalletNetwork()} color="dark"
                                                        size="large" iconbefore={<FaCircle color="red" size={27}/>}
                                                        blockclass='w-fit'>
                                                    {t('Profile.WalletStatusChangeNetwork')}
                                                </Button>
                                                <div
                                                  className="font-sofiaSans py-2 font-bold text-slate-400 flex items-center justify-center gap-1 text-sm">
                                                    <TbInfoHexagon size={20}/>
                                                    <span className="pt-0.5">{t('Profile.SupportedWallets')}</span>
                                                </div>
                                            </>
                                          )}
                                      </div>

                                      {(walletProvider && browserWalletIsConnected && chainIdIsValid && address && address.toString() !== '') && (
                                        <div className="flex flex-col w-full gap-3 items-center">
                                        <input
                                              type="text"
                                              name="wallet"
                                              id="wallet"
                                              readOnly
                                              className="w-full text-white placeholder-white font-passionOne px-6 py-4 bg-primary-dark rounded-xl xl:text-lg text-center"
                                              placeholder={t('Wallet.NoAdressConnected')}
                                              value={address}
                                            />
                                            <div className="flex flex-col items-center w-full gap-2">
                                                <Button onClick={linkWalletWithSignature} color="dark" size="large" blockclass='w-fit'>
                                                    {t('Profile.WalletStatusConfirm')}
                                                </Button>
                                                <Button onClick={() => wrappedModalOpen('openWeb3WalletConnect', true)} color="dark" size="large" blockclass='w-fit'>
                                                    {t('Profile.Switch')}
                                                </Button>
                                                {/* <Button onClick={() => forceRefresh()} color="dark" size="large" blockclass='w-fit'>
                                                    {t('Profile.IdontSeeMyWallet')}
                                                </Button> */}
                                                <div className="font-sofiaSans py-2 font-bold text-slate-400 flex items-center justify-center gap-1 text-sm">
                                                    <TbInfoHexagon size={20}/>
                                                    <span className="pt-0.5">{t('Profile.SupportedWallets')}</span>
                                                </div>
                                            </div>
                                        </div>
                                      )}
                                  </>
                                }

                                {currentStep === 6 && isLoadingAddressLinking &&
                                  <>
                                      <div className="flex flex-col w-full gap-5">
                                          <h1 className="text-white font-passionOne text-4xl uppercase text-center">{t('Common.Processing')}...</h1>
                                          <div className="w-48 p-3 rounded-full mx-auto bg-white bg-opacity-5 relative my-10">
                                              <svg width="250" height="250" viewBox="0 0 250 250" className="circular-progress">
                                                  <circle className="bg"></circle>
                                                  <circle className="fg"></circle>
                                              </svg>
                                              <img src={mlc3D} alt="" className="w-full p-8"/>
                                          </div>
                                          <h2
                                            className="text-white font-passionOne text-3xl uppercase text-center">{t('RecoveryAddress.MsgContent.WalletLinkingInProgress')}</h2>
                                      </div>
                                  </>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default AddWalletAddress;
