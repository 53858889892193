import React, {useContext, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom'
import {db} from "../../firebase";
// see https://stackoverflow.com/questions/76135179/firebase-auth-responds-with-getrecaptchaconfig-is-not-a-function-during-signin
import {signInWithEmailAndPassword, signInWithPopup} from "@firebase/auth"
import axios from 'axios';
// components
import Input from "../../components/Input/Input";
import routes from "../../route";
import {checkValidity, makeid} from "../../shared/utility";
import {auth, googleProvider, appCurrVersion} from '../../firebase'
import logo from '../../assets/img/Logo-Wordmark.png';
import signin from '../../assets/img/planet.png';
import signinPlanStar from '../../assets/img/stars.png';
import Bugsnag from "@bugsnag/js";
import googleLogo from "../../assets/img/Google__G__Logo.png"
import {UserContext} from "../../hooks/useUser";
import Card from "../../components/Card/Card";
import Button from "../../components/Button/Button";

export default function Signin(props) {
    let navigate = useNavigate()
    const {getRawUser, getToken, setUser, updateUser, fetchTokensBalances} = useContext(UserContext);

    const [inputs, setInputs] = useState({
        email: {
            elementType: 'input',
            name: 'email',
            otherClass: '',
            elementConfig: {
                type: 'email',
                placeholder: "Your email",
            },
            value: '',
            label: 'Adresse email',
            valid: false,
            validation: {
                required: true,
                email: true
            },
            touched: false,
            errorMessage: "Your email is wrong",
        },
        password: {
            elementType: 'input',
            otherClass: '',
            elementConfig: {
                type: 'password',
                placeholder: "Your password",
            },
            value: '',
            label: 'Mot de passe',
            valid: false,
            validation: {
                required: true,
                minLength: 6,
            },
            touched: false,
            errorMessage: "Your password is wrong",
        }
    });


    const inputChangedHandler = (e, formElement) => {
        // ON VERIFIE LE CHAMP
        const newInputs = {...inputs}
        const valid = checkValidity(e.target.value, inputs[formElement].validation)

        newInputs[formElement].value = e.target.value
        newInputs[formElement].valid = valid
        newInputs[formElement].touched = true

        newInputs[formElement].otherClass = ''
        if (!valid) {
            newInputs[formElement].otherClass = 'bg-red-50 text-red-500'
        }

        setInputs(newInputs)

        // VERIFICATION SI FORMULAIRE VALIDE POUR ENVOI
        let formIsValid = true
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }
    }

    const submitHandler = () => {
        try {
            console.log({auth});
            signInWithEmailAndPassword(auth, inputs.email.value, inputs.password.value)
                .then(async response => {
                    await getToken()
                    let rusr = await getRawUser()
                    if ((rusr && !rusr.userInvitationCode) || !rusr)  {
                        console.log('DATA NEW CREDENTIALS', rusr)
                        const referralCode = makeid(5)
                        const newUser = {
                            role: 'user',
                            firstConnection: true,
                            invited_by: "",
                            cgv: 'avril 2021',
                            referralRate: 10,
                            fromGame: true,
                            invitationCode: "",
                            userInvitationCode: referralCode,
                            // IDENTITY: "NOT SUBMITTED",
                            // KYC_STATUS: "NOT SUBMITTED",
                            // LIVENESS: "NOT SUBMITTED",
                            // RESIDENCY: "NOT SUBMITTED",
                            subscriptionDate: new Date().toISOString(),
                            fname: auth.currentUser.displayName,
                            lname: "",
                            name: "",
                            email: auth.currentUser.email,
                            byGoogle: false,
                        };

                        await updateUser(newUser)
                        // force token fetch because authent doesn't change and only authent change is watched
                        await fetchTokensBalances()
                        rusr = newUser

                        setTimeout(() => {
                            navigate(routes.DASHBOARD);
                        }, 500);
                    }
                    setUser(rusr)
                    setTimeout(() => {
                        navigate(routes.DASHBOARD);
                    }, 500);
                })
                .catch(error => {
                    const newInputs = {...inputs}
                    switch (error.code) {
                        case 'auth/user-not-found':
                        case 'auth/wrong-password':
                            newInputs.email.otherClass = 'bg-red-50 text-red-500'
                            newInputs.email.valid = false
                            newInputs.email.errorMessage = "The email/password combination is wrong"
                            break
                        default:
                            console.error(error);
                            Bugsnag.notify(error)
                            break
                    }

                    setInputs(newInputs)
                })
        } catch (err) {
            console.error(err);
            Bugsnag.notify(err)
        }
    }

    const onFormSubmit = async (e) => {
        try {
          e.preventDefault();
          await submitHandler();
        } catch (err) {
          console.error(err)
        }
    }

    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider);

            if (auth.currentUser.uid) {
                const tok = await auth.currentUser.getIdToken();

                await axios.get(`${db}Users/${auth.currentUser.uid}.json?auth=${tok}`).then(async response => {
                    if ((response.data && !response.data.userInvitationCode) || !response.data) {
                        console.log('DATA NEW GMAIL', response.data)
                        const referralCode = makeid(5)
                        const newUser = {
                            role: 'user',
                            firstConnection: true,
                            invited_by: "",
                            cgv: 'avril 2021',
                            referralRate: 10,
                            fromGame: false,
                            invitationCode: "",
                            userInvitationCode: referralCode,
                            // IDENTITY: "NOT SUBMITTED",
                            // KYC_STATUS: "NOT SUBMITTED",
                            // LIVENESS: "NOT SUBMITTED",
                            // RESIDENCY: "NOT SUBMITTED",
                            subscriptionDate: new Date().toISOString(),
                            fname: auth.currentUser.displayName,
                            lname: "",
                            name: "",
                            email: auth.currentUser.email,
                            byGoogle: true,
                        };

                        await updateUser(newUser)
                        // force token fetch because authent doesn't change and only authent change is watched
                        await fetchTokensBalances()

                        setTimeout(() => {
                            navigate(routes.DASHBOARD);
                        }, 500);
                    }
                })
            }

        } catch (error) {
            console.log(error);
            Bugsnag.notify(error);
        }
    };

    return (
        <div className="flex flex-col md:flex-row w-full h-screen bg-primary-dark overflow-hidden">
            <div className="flex flex-col h-full justify-center items-center p-5 w-full lg:w-1/2 2xl:w-1/3 lg:pl-32 xl:pl-48 bg-gradient-radial-to-br from-login-first to-transparent z-10">
                <form onSubmit={onFormSubmit} className="w-full">
                    <Card withBorder>
                        <img src={logo} alt="Logo MLC" className="w-1/2 mx-auto mt-6 mb-6"/>
                        <h1 className="text-3xl 2xl:text-5xl text-white uppercase text-center mb-6 font-passionOne">Login</h1>
                        <div className="w-full flex flex-col">
                            <Input
                                type={inputs['email'].elementConfig.type}
                                name={inputs['email'].name}
                                id={inputs['email'].name}
                                placeholder={inputs['email'].elementConfig.placeholder}
                                otherClass={inputs['email'].otherClass}
                                changed={(e) => inputChangedHandler(e, 'email')}
                                errorMessage={inputs['email'].errorMessage}
                                touched={inputs['email'].touched}
                                valid={inputs['email'].valid}
                                label="Email"
                            />
                        </div>
                        <div className="w-full flex flex-col">
                            <Input
                                type={inputs['password'].elementConfig.type}
                                name={inputs['password'].name}
                                id={inputs['password'].name}
                                placeholder={inputs['password'].elementConfig.placeholder}
                                otherClass={inputs['password'].otherClass}
                                changed={(e) => inputChangedHandler(e, 'password')}
                                errorMessage={inputs['password'].errorMessage}
                                touched={inputs['password'].touched}
                                valid={inputs['password'].valid}
                                label="Password"
                            />
                        </div>
                        <div className="flex flex-col w-full gap-5">
                            <Button color="secondary">Login</Button>
                            <Link to={routes.FORGOT_PASSWORD} className="text-slate-300 text-center underline">
                                Forgot password ?
                            </Link>
                            <p className="text-white font-passionOne text-center">Or</p>
                            <button
                                type="button"
                                onClick={signInWithGoogle}
                                className="inline-flex justify-center rounded-md bg-white w-full px-4 py-2 text-primary-dark
                                text-xl 2xl:text-2xl font-passionOne mx-auto gap-5"
                            >
                                <img src={googleLogo} className="w-8" alt=""/>
                                Login with Google
                            </button>
                        </div>
                        <hr className="bg-login-border border border-login-border mt-3"/>
                        <div className="flex flex-col justify-center gap-3">
                            <div className="flex text-white font-passionOne text-lg justify-center">
                                I don't have an account yet.
                                &nbsp;
                                <Link key="registerLink" to={routes.REGISTER} className="text-primary-orange">
                                    Register
                                </Link>
                            </div>
                            <div className="flex text-white font-passionOne w-100 justify-end">
                                <span className='w-100 text-sm opacity-50'>v{appCurrVersion}</span>
                            </div>
                        </div>
                    </Card>
                </form>
            </div>
          <div className='flex-col h-full justify-center items-center w-full lg:w-1/2 2xl:w-2/3 hidden lg:flex'>
            <div className='w-full relative'>
                <img src={signin} alt="Background" id="planet-mlp" className="w-full object-contain hidden lg:block"/>
                <img src={signinPlanStar} alt="Background stars"  id="stars-mlp" className="w-full object-contain hidden lg:block absolute"/>
            </div>
          </div>
        </div>
    )
}