import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {useTranslation} from "react-i18next";
import Button from '../Button/Button';
import {LiaTimesSolid} from "react-icons/lia";
import i18n from '../../i18n';

const TutorialNoWalletModal = ({isOpen, closeModal, handleGoToNextModal}) => {

    const {t} = useTranslation();
    
    const videoByLocale = {
        us: 'https://www.youtube.com/embed/WZ-1mJ6r1zo?si=Y26UpTclOncaZJkG',
        fr: 'https://www.youtube.com/embed/05Z7dxgraaU?si=IRg5mflNPgATEPby',
    };
    const [currentVideoLocale, setCurrentVideoLocale] = useState('us');
    const handleCloseModal = () => {
        closeModal()
    };

    useEffect(() => {
        if (i18n && i18n.language && i18n.language.toLowerCase() !== '') {
            setCurrentVideoLocale(i18n.language.toLowerCase())
        }
    }, [i18n.language]);


    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black-primary bg-opacity-60 backdrop-blur-sm"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-8 text-center">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                              className="w-full max-w-4xl transform overflow-hidden rounded-3xl bg-gradient-to-b from-primary-modalBlue to-primary-customDark p-12 gap-5 flex flex-col text-left align-middle shadow-xl transition-all">
                                <button onClick={() => handleCloseModal()} className="absolute top-8 right-10 font-bold rounded-2xl border-2 border-white border-opacity-15 p-4 hover:border-opacity-30">
                                    <LiaTimesSolid size={30} color="white"/>
                                </button>

                                <Dialog.Title as="h1" className="text-5xl font-passionOne text-white bg-clip-text uppercase mb-10">
                                    {t('AddWalletTuto.Title')}
                                </Dialog.Title>


                                      <div className="flex flex-col w-full gap-5">
                                          <div>
                                              <h2
                                                className="text-white uppercase font-passionOne text-3xl">{t('AddWalletTuto.MsgContent.ImportantExplanation')}</h2>
                                          </div>
                                          <div className="w-full aspect-w-16 aspect-h-9">
                                          {currentVideoLocale === 'fr' &&
                                          <div className="w-full aspect-w-16 aspect-h-9">
                                              <iframe src={videoByLocale['fr']}
                                                      frameBorder="0"
                                                      height="400"
                                                      className="w-full"
                                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                      allowFullScreen></iframe>
                                          </div>}
                                          {currentVideoLocale === 'us' &&
                                          <div className="w-full aspect-w-16 aspect-h-9">
                                              <iframe src={videoByLocale['us']}
                                                      frameBorder="0"
                                                      height="400"
                                                      className="w-full"
                                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                      allowFullScreen></iframe>
                                          </div>}
                                          
                                          </div>
                                          <p>
                                            <a className='text-amber-500' href='https://metamask.io/download/' target='_blank'>{t('Common.ClickMetamaskInstall')}</a>
                                          </p>
                                      </div>
                                      <div className="mt-2 flex w-full gap-5 justify-center">
                                          <Button onClick={handleGoToNextModal} color="dark" size="large" blockclass='w-fit'>
                                              {t('Common.Ok')}
                                          </Button>
                                      </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default TutorialNoWalletModal;
