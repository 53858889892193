import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Card from "../Card/Card";
import {TbShoppingBag} from "react-icons/tb";
import token3D from "../../assets/img/token_1_3D.png";
import TextWithLoader from "../TextWithLoader/TextWithLoader";
import Button from "../Button/Button";
import {BiMessageSquareError} from "react-icons/bi";
import DistributionCalendarModal from "../DistributionCalendarModal/DistributionCalendarModal";
import {auth, extMainApiUrl} from "../../firebase";
import axios from "axios";
import {UserContext} from "../../hooks/useUser";
import LockedTokenModal from "../LockedTokenModal/LockedTokenModal";
import BuyModal from "../BuyModal/BuyModal";
import BuyModalDisabled from "../BuyModalDisabled/BuyModalDisabled";

export default function Purchased() {
    const {t} = useTranslation();
    const [buyModalOpened, setBuyModalOpened] = useState(false);
    const [buyModalDisabledOpened, setBuyModalDisabledOpened] = useState(false);
    const [distributionCalendarIsOpened, setDistributionCalendarIsOpened] = useState(false);
    const [lockedModalOpened, setLockedModalOpened] = useState(false);
    const [isClaimFeatureDisabled, setIsClaimFeatureDisabled] = useState(true);
    const {user, totalPurchased, siteFeatureClaimToken, mlcTokenPriceInUsd} = useContext(UserContext);
    const [totalPurchasedCoinBalance, setTotalPurchasedCoinBalance] = useState(-1);
    const [totalPurchasedCoinBalanceTxt, setTotalPurchasedCoinBalanceTxt] = useState('?');
    const [totalPurchasedCoinBalanceTxtError, setTotalPurchasedCoinBalanceTxtError] = useState();
    const [totalPurchasedCoinBalanceInUsd, setTotalPurchasedCoinBalanceInUsd] = useState(0);

    const getTokenPourchasedBalance = async () => {
        if (totalPurchased === -2) {
            setTotalPurchasedCoinBalanceTxtError(t('Common.TokenSafeError'))
            setTotalPurchasedCoinBalance(0)
        } else {
            setTotalPurchasedCoinBalance(totalPurchased)
            setTotalPurchasedCoinBalanceTxt(parseFloat(parseFloat(totalPurchased).toFixed(0)).toLocaleString())
            setTotalPurchasedCoinBalanceInUsd(totalPurchased * mlcTokenPriceInUsd)
        }
    };

    const isDateAfterListing = () => {
        const now = new Date().getTime();
        // console.log(`${now} > 1719340000000`);
        return now > 1719340000000; // if current timestamp > than 25/06/2024 20:26:40 timestamp
    }

    useEffect(() => {
        if (siteFeatureClaimToken && siteFeatureClaimToken === '1') {
            setIsClaimFeatureDisabled(false);
        } else {
            setIsClaimFeatureDisabled(true);
        }
    }, [user, auth, siteFeatureClaimToken]);


    useEffect(() => {
        getTokenPourchasedBalance()
    }, [totalPurchased, mlcTokenPriceInUsd]);

    return (
        <Card title={t('Dashboard.PurchasedTokens')} icon={<TbShoppingBag size={40}/>} withBorder>
            <BuyModal
                isOpened={buyModalOpened}
                closeModal={() => setBuyModalOpened(false)}
            />
            <BuyModalDisabled
                isOpened={buyModalDisabledOpened}
                closeModal={() => setBuyModalDisabledOpened(false)}
            />
            <LockedTokenModal
                isOpened={lockedModalOpened}
                closeModal={() => setLockedModalOpened(false)}
            />
            <DistributionCalendarModal
                isOpened={distributionCalendarIsOpened}
                closeModal={() => setDistributionCalendarIsOpened(false)}
            />
            <div className="flex justify-start items-center gap-5 mb-5">
                <img src={token3D} alt="" className="w-24"/>
                <div className="flex flex-col justify-start">
                    <div className="flex gap-2">
                        <p className={"text-xl text-white uppercase font-passionOne " + (totalPurchasedCoinBalanceTxt.length > 8 ? 'lg:text-3xl' : 'lg:text-5xl')}>
                            <TextWithLoader isLoading={totalPurchasedCoinBalance === -1} errorMessage={totalPurchasedCoinBalanceTxtError}>
                                {totalPurchasedCoinBalanceTxt}
                            </TextWithLoader>
                        </p>
                        <p className="text-xl text-white lg:text-base uppercase font-passionOne">$MLC</p>
                    </div>
                    <div
                        className="text-lg text-white text-opacity-60 uppercase font-passionOne">≈ {parseFloat(parseFloat(totalPurchasedCoinBalanceInUsd).toFixed(2)).toLocaleString()} $
                    </div>
                </div>
            </div>
            <button onClick={() => setDistributionCalendarIsOpened(true)}
            // <button onClick={() => setLockedModalOpened(true)}
                    className="bg-primary-dark bg-opacity-50 text-white text-md font-bold rounded-3xl p-5 flex items-center gap-3 uppercase underline">
                <BiMessageSquareError size={30}/>
                {t('Balance.DistributionCalendar')}
            </button>


            <span className="group relative">
                {!isClaimFeatureDisabled &&
                    <Button onClick={() => setBuyModalOpened(true)} color="secondary" size="large">
                        {t('Buy.Buy')}
                    </Button>
                }
                {isClaimFeatureDisabled &&
                <span className="group relative flex justify-center">
                <Button color="secondary" size="large" onClick={() => setBuyModalDisabledOpened(true)}>
                    {t('Buy.Buy')}
                </Button>
                {!isDateAfterListing() && <>
                    <span
                        className="group-hover:flex group-focus:flex group-active:flex hidden bg-gray-800 px-3 py-2 text-xl text-gray-100 rounded-md absolute translate-y-[-120%]">
                    {t('Balance.ClaimModal.BuyAvailableMessage')}
                    </span>
                </>}
                {isDateAfterListing() && <>
                    <span
                        className="group-hover:flex group-focus:flex group-active:flex hidden bg-gray-800 px-3 py-2 text-xl text-gray-100 rounded-md absolute translate-y-[-120%]">
                    {t('Balance.ClaimModal.BuyAvailableMessageDefault')}
                    </span>
                </>}
                </span>
                }
            </span>
        </Card>
    )
}