import React, {useState} from 'react';
import logo from '../../assets/img/Logo-Wordmark.png';
import signin from '../../assets/img/planet.png';
import signinPlanStar from '../../assets/img/stars.png';
import Input from "../../components/Input/Input";
import {Link, useLocation, useSearchParams, useNavigate} from 'react-router-dom'
import axios from "axios";
import routes from "../../route";
import {auth} from '../../firebase';
import {sendPasswordResetEmail} from "firebase/auth";
import {checkValidity} from "../../shared/utility";
import Swal from 'sweetalert2'
import {useTranslation} from "react-i18next";
import Bugsnag from "@bugsnag/js";
import Card from "../../components/Card/Card";
import Button from "../../components/Button/Button";
import { extMainApiUrl, customAlertProps } from '../../firebase';

export default function ResetPassword() {

    const {t} = useTranslation();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    let navigate = useNavigate()
    // console.log(searchParams.get("userId"))
    // console.log(searchParams.get("rptkn"))

    const [password, setPassword] = useState('');
    const [passwordConf, setPasswordConf] = useState('');
    const [validPassword, setvalidPassword] = useState(true);
    const [validPasswordConf, setvalidPasswordConf] = useState(true);
    const [touchedPassword, settouchedPassword] = useState(false);
    const [touchedPasswordConf, settouchedPasswordConf] = useState(false);
    const [cssPassword, setCssPassword] = useState('');
    const [cssPasswordConf, setCssPasswordConf] = useState('');
    const [errorMessagePassword, setErrorMessagePassword] = useState('');
    const [errorMessagePasswordConf, setErrorMessagePasswordConf] = useState('');

    const handleClick = async (e) => {
        e.preventDefault()
        
        if (password.length < 6) {
            setErrorMessagePassword(t('Profile.PasswordTooShort'))
            setErrorMessagePasswordConf(t('Profile.PasswordTooShort'))

            setCssPassword('border-red-300')
            setCssPasswordConf('border-red-300')

            setvalidPassword(false)
            setvalidPasswordConf(false)

            settouchedPassword(true)
            settouchedPasswordConf(true)
            return;
        }
        if (password !== passwordConf) {
            setErrorMessagePasswordConf(t('Profile.PasswordDoesNotMatch'))
            setErrorMessagePassword(t('Profile.PasswordDoesNotMatch'))

            setCssPassword('border-red-300')
            setCssPasswordConf('border-red-300')

            setvalidPassword(false)
            setvalidPasswordConf(false)

            settouchedPassword(true)
            settouchedPasswordConf(true)
        } else {
            setCssPassword('')
            setCssPasswordConf('')

            setvalidPassword(true)
            setvalidPasswordConf(true)

            settouchedPassword(false)
            settouchedPasswordConf(false)
            // Handle it home made
            try {
                await axios.post(`${extMainApiUrl}/user/reset-password`, {
                    userId: searchParams.get("userId"),
                    rptkn: searchParams.get("rptkn"),
                    password: password,
                });
                Swal.fire({
                    title: 'Good!',
                    text: t('Profile.PasswordReset'),
                    icon: 'success',
                    confirmButtonText: 'Cool',
                    ...customAlertProps,
                });
                setTimeout(() => {
                    navigate(routes.LOGIN)
                }, 3000);
            } catch (error) {
                Swal.fire({
                    title: 'Oups!',
                    text: t('Profile.PasswordNotReset'),
                    icon: 'error',
                    confirmButtonText: 'Ok',
                        ...customAlertProps,
                });
                Bugsnag.notify(error);
            }
        }
    }

    return (
      <div className="flex flex-col md:flex-row w-full h-screen bg-primary-dark overflow-hidden">
          <div className="flex flex-col h-full justify-center items-center p-5 lg:w-1/2 2xl:w-1/3 lg:pl-32 xl:pl-48 bg-gradient-radial-to-br from-login-first to-transparent z-10">
              <div className="w-full">
                  <Card withBorder>
                      <img src={logo} alt="Logo MLC" className=" w-1/2 mx-auto"/>
                      <h1
                        className="text-5xl text-white uppercase text-center mb-12 font-passionOne">{t('Profile.Email.ResetPassword')}</h1>
                    <div className="w-full flex flex-col">
                        <Input type="password"
                            value={password}
                            name="password"
                            id="password"
                            placeholder="Your new password"
                            required="required"
                            changed={(e) => setPassword(e.target.value)}
                            valid={validPassword}
                            touched={touchedPassword}
                            errorMessage={errorMessagePassword}
                            otherClass={cssPassword}
                            autocomplete="new-password"
                        />
                    </div>
                    <div className="w-full flex flex-col">
                        <Input type="password"
                            value={passwordConf}
                            name="password_conf"
                            id="password_conf"
                            placeholder="Confirm new password"
                            required="required"
                            changed={(e) => setPasswordConf(e.target.value)}
                            valid={validPasswordConf}
                            touched={touchedPasswordConf}
                            errorMessage={errorMessagePasswordConf}
                            otherClass={cssPasswordConf}
                            autocomplete="new-password"
                        />
                    </div>

                      <div className="flex flex-col w-full">
                          <Button onClick={handleClick} color="secondary">
                              {t('Profile.Email.SaveNewPassword')}
                          </Button>
                      </div>
                      <hr className="bg-login-border border border-login-border mt-3"/>
                      <div className="flex text-white font-passionOne text-lg text-center mx-auto">
                          <Link key="login" to={routes.LOGIN} className="text-center">
                              {t('Profile.Email.GoBackToLogin')}
                          </Link>
                      </div>
                  </Card>
              </div>
          </div>
          <div className='flex flex-col h-full justify-center items-center w-full lg:w-1/2 2xl:w-2/3'>
            <div className='w-full relative'>
                <img src={signin} alt="Background" id="planet-mlp" className="w-full object-contain hidden lg:block"/>
                <img src={signinPlanStar} alt="Background stars"  id="stars-mlp" className="w-full object-contain hidden lg:block absolute"/>
            </div>
          </div>
            </div>
    )
}