import React, {useContext, useEffect, useState} from 'react'
import Card from "../Card/Card";
import {useTranslation} from "react-i18next";
import {BsBarChartSteps} from "react-icons/bs";
import {FaCheck} from "react-icons/fa";
import {TbPlugConnected} from "react-icons/tb";
import {PiSealWarningFill} from "react-icons/pi";
import {FaMoneyBillTrendUp} from "react-icons/fa6";
import LinkWalletAddressModal from "../LinkWalletAddress/LinkWalletAddressModal";
import TutorialNoWalletModal from "../TutorialNoWalletModal/TutorialNoWalletModal";
import {UserContext} from "../../hooks/useUser";
import {MdOutlineSecurity} from "react-icons/md";

export default function StepsTodo({ handleOpenKycModal }) {
    const {user, web3TokenRemainingLocked, web3TokenClaimable, totalPurchased} = useContext(UserContext);
    const {t} = useTranslation();
    const [linkWalletAddressIsOpened, setLinkWalletAddressIsOpened] = useState(false);
    const [claimIsDone, setClaimIsDone] = useState(false);
    const [tutorialNoWalletModalIsOpened, setTutorialNoWalletModalIsOpened] = useState(false);
    const KYCisDone = user && user.KYC_STATUS && user.KYC_STATUS === 'VALIDATED';
    const KYCisInProgress = user && user.investments && user.KYC_STATUS && user.KYC_STATUS !== 'NOT SUBMITTED' && user.KYC_STATUS !== 'VALIDATED';
    const connectedWalletIsDone = !!user.connectedWallet;

    useEffect(() => {
        if (totalPurchased && web3TokenRemainingLocked >= 0 && (web3TokenRemainingLocked + web3TokenClaimable) < totalPurchased) {
            setClaimIsDone(true)
        } else {
            setClaimIsDone(false)
        }
    }, [user, web3TokenRemainingLocked, web3TokenClaimable, totalPurchased]);

    const handleLinkWalletAddress = () => {
        setLinkWalletAddressIsOpened(true)
    }

    const handleCloseModal = () => {
        setLinkWalletAddressIsOpened(false)
    }

    const handleCloseModalForTutorial = () => {
        setLinkWalletAddressIsOpened(false)
        setTutorialNoWalletModalIsOpened(true)
    }

    const handleCloseTutorialModal = () => {
        setTutorialNoWalletModalIsOpened(false)
    }

    const handleCloseTutorialModalAndGoNext = () => {
        setLinkWalletAddressIsOpened(true)
        setTutorialNoWalletModalIsOpened(false)
    }

    const handleOpenClaimTuto = () => {
        window.open('https://whitepaper.mylovelyplanet.org/lovely-family-mlc-investors/how-to-claim-your-usdmlc-available-on-june-25');
    }

    return (
        <Card title={t('Dashboard.StepsToDo')} icon={<BsBarChartSteps size={35}/>} withBorder>
            <LinkWalletAddressModal
                isOpen={linkWalletAddressIsOpened}
                closeModal={handleCloseModal}
                openTutorialNoWallet={handleCloseModalForTutorial}
            />
            <TutorialNoWalletModal
                isOpen={tutorialNoWalletModalIsOpened}
                closeModal={handleCloseTutorialModal}
                handleGoToNextModal={handleCloseTutorialModalAndGoNext}
            />
            <ol className="relative text-gray-500 border-s border-gray-200 ml-5">
                <li className="mb-10 ms-6 flex items-center">
                    <span
                        className={`absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-4 ring-primary-white ring-opacity-50 ` + (KYCisDone ? 'bg-green-500' : (KYCisInProgress ? 'bg-yellow-500' : 'bg-gray-200'))}>
                        {KYCisDone ? <FaCheck size={18} color="white" /> : <MdOutlineSecurity size={18} />}
                    </span>
                    <div className="ml-2">
                        <h3 className="font-sofiaSans text-slate-200 font-bold text-lg leading-tight">{t('StepsToDo.KYC.title')}</h3>
                        <p className="text-sm text-slate-400">{t('StepsToDo.KYC.description')} {!KYCisDone && (<span onClick={handleOpenKycModal} className="text-primary-lightBlue cursor-pointer hover:underline">{t('StepsToDo.KYC.action')}</span>)}</p>
                    </div>
                </li>
                <li className="mb-10 ms-6 flex items-center">
                    <span
                        className={`absolute flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full -start-4 ring-4 ring-primary-white ring-opacity-50 ` + (connectedWalletIsDone ? 'bg-green-500' : 'bg-gray-200')}>
                        {connectedWalletIsDone ? <FaCheck size={18} color="white" /> : <TbPlugConnected size={18} />}
                    </span>
                    <div  className="ml-2">
                        <h3 className="font-sofiaSans text-slate-200 font-bold text-lg leading-tight">{t('StepsToDo.LinkWallet.title')}</h3>
                        <p className="hidden lg:block text-sm text-slate-400">{t('StepsToDo.LinkWallet.description')} {!connectedWalletIsDone && (<span
                            onClick={handleLinkWalletAddress}
                            className="text-primary-lightBlue cursor-pointer hover:underline">{t('StepsToDo.LinkWallet.action')}</span>)}</p>
                    </div>
                </li>
                <li className="ms-6 flex items-center">
                    <span
                        className={`absolute flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full -start-4 ring-4 ring-primary-white ring-opacity-50 ` + (claimIsDone ? 'bg-green-500' : 'bg-gray-200')}>
                        {claimIsDone ? <FaCheck size={18} color="white" /> : <FaMoneyBillTrendUp size={18} />}
                    </span>
                    <div  className="ml-2">
                        <h3 className="font-sofiaSans text-slate-200 font-bold text-lg leading-tight">
                            {t('StepsToDo.Claim.title')}
                            <span className="text-sm text-yellow-600 font-bold ml-2">
                            </span>
                        </h3>
                        <p className="text-sm text-slate-400">{t('StepsToDo.Claim.description')}. {!claimIsDone && (<span
                            onClick={handleOpenClaimTuto}
                            className="text-primary-lightBlue cursor-pointer hover:underline">{t('StepsToDo.Claim.help')}</span>)}</p>
                    </div>
                </li>
            </ol>
        </Card>
    );
}