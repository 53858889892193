import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MLCAbi, MLCTokenContract, MLCVestingAggregatorContract, VESTAGGAbi, auth, validPolygonChainId} from "../../../firebase";
import LinkWalletAddressModal from "../../LinkWalletAddress/LinkWalletAddressModal";
import VerifyEmailModal from "../../VerifyEmailModal/VerifyEmailModal";
import {UserContext} from "../../../hooks/useUser";
import Button from "../../Button/Button";
import Card from "../../Card/Card";
import { useWeb3Modal, useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import {FaCircle} from "react-icons/fa";
import {GoShieldCheck} from "react-icons/go";
import TutorialNoWalletModal from "../../TutorialNoWalletModal/TutorialNoWalletModal"
import {TbInfoHexagon} from "react-icons/tb";

export default function Status() {
    const {t} = useTranslation();
    const [name, setName] = useState('');
    const [linkWalletAddressIsOpened, setLinkWalletAddressIsOpened] = useState(false);
    const [verifyEmailModalIsOpened, setVerifyEmailModalIsOpened] = useState(false);
    const [tutorialNoWalletModalIsOpened, setTutorialNoWalletModalIsOpened] = useState(false);
    const {user, totalPurchased} = useContext(UserContext)
    // Web3Modal states
    const [browserWalletIsConnected, setBrowserWalletIsConnected] = useState(false);
    const [chainIdIsValid, setChainIdIsValid] = useState(false);
    const [linkedAddressIsValid, setLinkedAddressIsValid] = useState(false);
    const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { open: openWeb3WalletConnect } = useWeb3Modal({ view: 'Connect' })
    const { open: openWeb3WalletAccount } = useWeb3Modal({ view: 'Account' })
    const { open: openWeb3WalletNetwork } = useWeb3Modal({ view: 'Networks' })
    const { walletProvider } = useWeb3ModalProvider()

    useEffect(() => {
        setName(user?.fname || auth.currentUser.displayName)
        setBrowserWalletIsConnected(isConnected)
        if (Number(chainId) === validPolygonChainId) { // Polygon network
          setChainIdIsValid(true)
        } else {
          setChainIdIsValid(false)
        }
        if (user?.connectedWallet?.publicAddress && address && user?.connectedWallet?.publicAddress.toString().toLowerCase() === address.toString().toLowerCase()) {
          setLinkedAddressIsValid(true)
        } else {
          setLinkedAddressIsValid(false)
        }
    }, [auth, user, isConnected, chainId, address, walletProvider]);

    const testGetBalancesOfToken = async () => {
        // TODO dev DISABLED for now back-end tests
        return;
        // // Get balance of token in current connected  wallet
        // if (user.connectedWallet && browserWalletIsConnected && chainIdIsValid && linkedAddressIsValid && walletProvider) {
        //   let ethersProvider = new BrowserProvider(walletProvider)
        //   let signer = await ethersProvider.getSigner()
        //   let MLCContract = new Contract(MLCTokenContract, MLCAbi, signer)
        //   const balanceReq = MLCContract.balanceOf(address)
        //   let MLCBalance = await balanceReq
        //   console.log('MLC Token', formatUnits(MLCBalance, 18))

        // // Get balance of token in agg SC
        //   ethersProvider = new BrowserProvider(walletProvider)
        //   signer = await ethersProvider.getSigner()
        //   MLCContract = new Contract(MLCVestingAggregatorContract, VESTAGGAbi, signer)
        //   const ClaimableMLCBalance = await MLCContract.getAggregatedClaimable(address)
        //   console.log('Claimable MLC Token', ClaimableMLCBalance)
        //   let claimableToken = Number(0.0);
        //   for (const proxyResult of ClaimableMLCBalance) {
        //     if (proxyResult.length >= 2) {
        //       const vestScAddr = proxyResult[0]
        //       const vestClaimable = formatUnits(proxyResult[1], 18)
        //       if (!isNaN(vestClaimable)) {
        //         claimableToken += Number(vestClaimable);
        //       }
        //       console.log({vestScAddr, vestClaimable})
        //     }
        //   }
        //   console.log('Total Claimable = ' + claimableToken)
        // }
    }

    const handleLinkWalletAddress = () => {
        setLinkWalletAddressIsOpened(true)
    }

    const handleVerifyEmail = () => {
      setVerifyEmailModalIsOpened(true)
    }

    const handleCloseModal = () => {
        setLinkWalletAddressIsOpened(false)
    }

    const handleCloseVerifyEmailModal = () => {
        setVerifyEmailModalIsOpened(false)
    }

    const handleCloseModalForTutorial = () => {
        setLinkWalletAddressIsOpened(false)
        setTutorialNoWalletModalIsOpened(true)
    }

    const handleCloseTutorialModal = () => {
        setTutorialNoWalletModalIsOpened(false)
    }

    const handleCloseTutorialModalAndGoNext = () => {
        setLinkWalletAddressIsOpened(true)
        setTutorialNoWalletModalIsOpened(false)
    }

    const addressObfuscation = (string) => {
      if (!string) return false;
      return string.slice(0, 11) + '...' + string.slice(string.length - 11, string.length);
    };

    return (
      <Card withMinH={false} withBorder>
          <LinkWalletAddressModal
            isOpen={linkWalletAddressIsOpened}
            closeModal={handleCloseModal}
            openTutorialNoWallet={handleCloseModalForTutorial}
          />
          <VerifyEmailModal
            isOpen={verifyEmailModalIsOpened}
            closeModal={handleCloseVerifyEmailModal}
          />
          <TutorialNoWalletModal
            isOpen={tutorialNoWalletModalIsOpened}
            closeModal={handleCloseTutorialModal}
            handleGoToNextModal={handleCloseTutorialModalAndGoNext}
          />

          <h3 className="text-white font-passionOne text-4xl pt-3 pb-1 text-center overflow-hidden whitespace-nowrap text-ellipsis">{name}</h3>
          <img src={require('../../../assets/img/profile.png')} alt="profile" className="w-1/3 mx-auto"/>
        {
          user &&
          user.emailVerified
          && (
            <div
              className="flex text-white font-sofiaSans text-center mx-auto uppercase text-sm px-3 py-2 rounded-full bg-primary-dark gap-2">
              <img src={require('../../../assets/img/verified.png')} alt="verified" className="w-5"/>
              {t('Profile.EmailVerified')}
            </div>
          )
        }
        {
          user &&
          !user.emailVerified
          && (
            <div
              onClick={() => handleVerifyEmail()}
              className="cursor-pointer flex text-white font-sofiaSans text-center mx-auto uppercase text-sm px-3 py-2 rounded-full bg-orange-400 gap-2">
              {t('Profile.EmailNotVerified')}
            </div>
          )
        }
        {
          user &&
          user?.connectedWallet?.publicAddress &&
          totalPurchased > 0
          && (

            <div className="flex flex-col w-full gap-3">
              <label htmlFor="wallet" className="text-white text-center opacity-50 font-passionOne text-xl mt-2 uppercase">{t('Profile.WalletConnectedToProfile')}</label>
              <div
                className="flex text-white font-sofiaSans text-center mx-auto uppercase text-sm px-3 py-2 rounded-full bg-primary-dark gap-2">
                <img src={require('../../../assets/img/verified.png')} alt="verified" className="w-5"/>
                {addressObfuscation(user?.connectedWallet?.publicAddress)}
              </div>
            </div>
          )
        }
        {totalPurchased > 0 &&
          <>
          <hr className="border border-white border-opacity-5 mt-3"/>
          <div className="flex flex-col w-full gap-3">
              <div className="flex flex-col w-full gap-3">
                  <label htmlFor="wallet" className="text-white opacity-50 font-passionOne text-xl mt-4 uppercase">{t('Profile.WalletConnected')}</label>
                  <div className="relative">
                      <input
                        type="text"
                        name="wallet"
                        id="wallet"
                        readOnly
                        className="w-full text-white placeholder-white font-passionOne px-6 py-4 bg-primary-dark rounded-xl xl:text-lg"
                        value={addressObfuscation(address) || ''}
                      />
                      {(browserWalletIsConnected && chainIdIsValid && linkedAddressIsValid) && (
                        <span className="pointer text-white font-sofiaSans text-center uppercase text-sm px-3 py-1 rounded-full absolute right-2.5 top-4 bg-green-900">
                            {t('RecoveryAddress.WalletAdded')}
                        </span>
                      )}
                      {(browserWalletIsConnected && chainIdIsValid && !linkedAddressIsValid && !user?.connectedWallet?.publicAddress) && (
                        <span className="pointer text-white font-sofiaSans text-center uppercase text-sm px-3 py-1 rounded-full absolute right-2.5 top-4 bg-yellow-900">
                          {t('Wallet.Unverified')}
                        </span>
                      )}
                      {(browserWalletIsConnected && chainIdIsValid && !linkedAddressIsValid && user?.connectedWallet?.publicAddress) && (
                        <span className="absolute right-2.5 top-4 pointer">
                          <span className="group flex relative pointer text-white font-sofiaSans text-center uppercase text-sm px-3 py-1 rounded-full bg-red-900 items-center">
                            {t('Wallet.Wrong')}
                            <span className="group-hover:flex hidden bg-gray-800 px-2 py-2 text-sm text-gray-100 rounded-md absolute right-0 translate-y-[-90%] gap-2 items-center">
                                <GoShieldCheck color="green" />
                                {user?.connectedWallet?.publicAddress}
                            </span>
                          </span>
                        </span>
                      )}
                      {(!browserWalletIsConnected) && (
                        <span
                          className="pointer text-white font-sofiaSans text-center uppercase text-sm px-3 py-1 rounded-full absolute right-2.5 top-4 bg-red-900">
                            {t('Wallet.Disco')}
                        </span>
                      )}
                    {(browserWalletIsConnected && !chainIdIsValid) && (
                      <span className="pointer text-white font-sofiaSans text-center uppercase text-sm px-3 py-1 rounded-full absolute right-2.5 top-4 bg-red-900">
                            {t('Wallet.Net')}
                        </span>
                      )}
                  </div>
              </div>

              <div className="flex flex-col w-full gap-3">
                  <label htmlFor="wallet" className="text-white opacity-50 font-passionOne text-xl mt-4 uppercase">{t('Profile.Wallet')}</label>
                <div className="flex flex-col w-full gap-2">
                     <span className="hidden lg:block">
                        {!user.connectedWallet && (
                          <>
                            <Button onClick={() => handleLinkWalletAddress()}>
                              {t('Profile.WalletStatusAddPolygonAddress')}
                            </Button>
                            <div className="font-sofiaSans py-2 font-bold text-slate-400 flex items-center justify-center gap-1 text-sm">
                              <TbInfoHexagon size={20}/>
                              <span className="pt-0.5">{t('Profile.SupportedWallets')}</span>
                            </div>
                          </>
                        )}
                       {(user.connectedWallet && browserWalletIsConnected && chainIdIsValid && linkedAddressIsValid) && (
                         <Button onClick={() => testGetBalancesOfToken() && openWeb3WalletAccount()}
                                 iconbefore={<FaCircle color="green" size={27}/>}>
                           {t('Profile.WalletStatusConnected')}
                         </Button>
                       )}
                       {(user.connectedWallet && browserWalletIsConnected && chainIdIsValid && !linkedAddressIsValid) && (
                         <Button onClick={() => openWeb3WalletAccount()} iconbefore={<FaCircle color="red" size={27}/>}>
                           {t('Profile.WalletStatusWrongWallet')}
                         </Button>
                       )}
                       {(user.connectedWallet && !browserWalletIsConnected) && (
                         <Button onClick={() => openWeb3WalletConnect()}>
                           {t('Profile.WalletStatusToConnect')}
                         </Button>
                       )}
                       {(user.connectedWallet && browserWalletIsConnected && !chainIdIsValid) && (
                         <>
                           <Button onClick={() => openWeb3WalletNetwork()}
                                   iconbefore={<FaCircle color="red" size={27}/>}>
                             {t('Profile.WalletStatusChangeNetwork')}
                           </Button>
                           <div
                             className="font-sofiaSans py-2 font-bold text-slate-400 flex items-center justify-center gap-1 text-sm">
                             <TbInfoHexagon size={20}/>
                             <span className="pt-0.5">{t('Profile.SupportedWallets')}</span>
                           </div>
                         </>
                       )}
                    </span>
                  <span className="group relative lg:hidden flex-col justify-center">
                        {!user.connectedWallet && (
                          <>
                            <Button disabled={true}>
                              {t('Profile.WalletStatusAddPolygonAddress')}
                            </Button>
                            <div
                              className="font-sofiaSans py-2 font-bold text-slate-400 flex items-center justify-center gap-1 text-sm">
                              <TbInfoHexagon size={20}/>
                              <span className="pt-0.5">{t('Profile.SupportedWallets')}</span>
                            </div>
                          </>
                        )}
                      {(user.connectedWallet && browserWalletIsConnected && chainIdIsValid && linkedAddressIsValid) && (
                        <Button iconbefore={<FaCircle color="green" size={27}/>} disabled={true}>
                          {t('Profile.WalletStatusConnected')}
                          </Button>
                        )}
                        {(user.connectedWallet && browserWalletIsConnected && chainIdIsValid && !linkedAddressIsValid) && (
                          <Button iconbefore={<FaCircle color="red" size={27}/>} disabled={true}>
                            {t('Profile.WalletStatusWrongWallet')}
                          </Button>
                        )}
                        {(user.connectedWallet && !browserWalletIsConnected) && (
                          <Button disabled={true}>
                            {t('Profile.WalletStatusToConnect')}
                          </Button>
                        )}
                        {(user.connectedWallet && browserWalletIsConnected && !chainIdIsValid) && (
                          <>
                            <Button iconbefore={<FaCircle color="red" size={27}/>} disabled={true}>
                              {t('Profile.WalletStatusChangeNetwork')}
                            </Button>
                            <div
                              className="font-sofiaSans py-2 font-bold text-slate-400 flex items-center justify-center gap-1 text-sm">
                              <TbInfoHexagon size={20}/>
                              <span className="pt-0.5">{t('Profile.SupportedWallets')}</span>
                            </div>
                          </>
                        )}
                    <span
                      className="group-hover:flex group-focus:flex group-active:flex hidden bg-gray-800 px-3 py-2 text-xl text-gray-100 rounded-md absolute translate-y-[-120%]">
                          {t('Buy.BuyDisableMessage')}
                        </span>
                    </span>
                </div>
              </div>
          </div>
          </>
          }
      </Card>
    )
}
