import React, {useState} from 'react';
// components
import logo from '../../assets/img/Logo-Wordmark.png';
import signin from '../../assets/img/planet.png';
import signinPlanStar from '../../assets/img/stars.png';
import Input from "../../components/Input/Input";
import {Link} from 'react-router-dom'
import axios from "axios";
import routes from "../../route";
import {auth} from '../../firebase';
import {sendPasswordResetEmail} from "firebase/auth";
import {checkValidity} from "../../shared/utility";
import Swal from 'sweetalert2'
import {useTranslation} from "react-i18next";
import Bugsnag from "@bugsnag/js";
import Card from "../../components/Card/Card";
import Button from "../../components/Button/Button";
import { extMainApiUrl, extMainApiUrlForced, customAlertProps } from '../../firebase';

export default function ForgotPassword() {

    const {t} = useTranslation();

    const [isProcessing, setIsProcessing] = useState(false);
    const [inputs, setInputs] = useState({
        email: {
            elementType: 'input',
            name: 'email',
            otherClass: '',
            elementConfig: {
                type: 'email',
                placeholder: "email",
            },
            value: '',
            label: 'email',
            valid: false,
            validation: {
                required: true,
                email: true
            },
            touched: false,
            errorMessage: t('Profile.Email.Error'),
        }
    });
    const [valid, setValid] = useState(false);

    const handleClick = async () => {
        if (valid && !isProcessing) {
            setIsProcessing(true);
            if (inputs['email'].value) {
                // Handle it home made
                try {
                    await axios.post(`${extMainApiUrlForced}/sendForgottenPassword`, {
                        email: inputs['email'].value
                    });
                    setIsProcessing(false);
                    Swal.fire({
                        title: 'Good!',
                        text: t('Profile.EmailSent'),
                        icon: 'success',
                        confirmButtonText: 'Cool',
                        ...customAlertProps,
                    });
                } catch (error) {
                    setIsProcessing(false);
                    Swal.fire({
                        title: 'Oups!',
                        text: t('Profile.EmailNotSent'),
                        icon: 'error',
                        confirmButtonText: 'Ok',
                            ...customAlertProps,
                    });
                    Bugsnag.notify(error);
                }
            }
        }
    }

    const inputChangedHandler = (e, formElement) => {

        // ON VERIFIE LE CHAMP
        const newInputs = {...inputs}
        const valid = checkValidity(e.target.value, inputs[formElement].validation)

        newInputs[formElement].value = e.target.value
        newInputs[formElement].valid = valid
        newInputs[formElement].touched = true

        newInputs[formElement].otherClass = ''
        if (!valid) {
            newInputs[formElement].otherClass = 'bg-red-50 text-red-500'
        }

        setInputs(newInputs)


        // VERIFICATION SI FORMULAIRE VALIDE POUR ENVOI
        let formIsValid = true
        for (let input in newInputs) {
            formIsValid = newInputs[input].valid && formIsValid;
        }

        setValid(formIsValid)
    }

    return (
      <div className="flex flex-col md:flex-row w-full h-screen bg-primary-dark overflow-hidden">
          <div className="flex flex-col h-full justify-center items-center p-5 lg:w-1/2 2xl:w-1/3 lg:pl-32 xl:pl-48 bg-gradient-radial-to-br from-login-first to-transparent z-10">
              <div className="w-full">
                  <Card withBorder>
                      <img src={logo} alt="Logo MLC" className=" w-1/2 mx-auto"/>
                      <h1
                        className="text-5xl text-white uppercase text-center mb-12 font-passionOne">{t('Profile.Email.ForgottenPassword')}</h1>
                      <div className="w-full flex flex-col">
                          <Input
                            type={inputs['email'].elementConfig.type}
                            name={inputs['email'].name}
                            id={inputs['email'].name}
                            placeholder={inputs['email'].elementConfig.placeholder}
                            otherClass={inputs['email'].otherClass}
                            changed={(e) => inputChangedHandler(e, 'email')}
                            errorMessage={inputs['email'].errorMessage}
                            touched={inputs['email'].touched}
                            valid={inputs['email'].valid}
                            label="Email"
                          />
                      </div>
                      <div className="flex flex-col w-full">
                          <Button onClick={handleClick} color="secondary">
                              {t('Profile.Email.SendResetLink')}
                          </Button>
                      </div>
                      <hr className="bg-login-border border border-login-border mt-3"/>
                      <div className="flex text-white font-passionOne text-lg text-center mx-auto">
                          <Link key="login" to={routes.LOGIN} className="text-center">
                              {t('Profile.Email.GoBackToLogin')}
                          </Link>
                      </div>
                  </Card>
              </div>
          </div>
          <div className='flex flex-col h-full justify-center items-center w-full lg:w-1/2 2xl:w-2/3'>
            <div className='w-full relative'>
                <img src={signin} alt="Background" id="planet-mlp" className="w-full object-contain hidden lg:block"/>
                <img src={signinPlanStar} alt="Background stars"  id="stars-mlp" className="w-full object-contain hidden lg:block absolute"/>
            </div>
          </div>
            </div>
    )
}