
import {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {UserContext} from "../hooks/useUser";
import {auth, db} from "../firebase";
import {publicRoutes} from "../route"
import Planet from '../assets/img/Login.png';
import axios from "axios";
import i18n from "../i18n";

const AppContainer =({children, setSelectedLang}) =>{
    const {user, totalPurchased, fetchTokensBalances, setUser} = useContext(UserContext);
    const location = useLocation();
    const {pathname} = location;
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (auth.currentUser && auth.currentUser.uid && auth.currentUser.accessToken) {
            setLoading(true)
            axios.get(`${db}Users/${auth.currentUser.uid}.json?auth=${auth.currentUser.accessToken}`)
            .then((res) => {
                    if (res.data) {
                        setUser(res.data)
                        fetchTokensBalances()
                    }
                    // TODO dev comment this out when you want to test loader display
                    // give time to loader to be displayed
                    setTimeout(() => {
                        setLoading(false)
                    }, 2000);
                })
                .catch((err) => {
                    console.error(err)
                });
        } else {
            // Authorize to load unauthenticated pages
            setLoading(false)
        }
        
    }, [auth.currentUser]);

    useEffect(() => {
        if (user.selectedLang && user.selectedLang !== i18n.language) {
            setSelectedLang(user.selectedLang);
            i18n.changeLanguage(user.selectedLang, (err, t) => {
                if (err) return console.error('An error is happened when changing the language.', err);
            });
        }
    }, [user]);


    useEffect(() => {
        // crisp checker
        const crispInt = setInterval(() => {
          try {
              if (window.$crisp) {
                  if (totalPurchased > 0) {
                      window.$crisp.push(["do", "chat:show"]);
                    } else {
                        window.$crisp.push(["do", "chat:hide"]);
                    }
                    // console.log({totalPurchased})
            }
        } catch (error) {
            console.error(error);
            if (window.$crisp) {
                window.$crisp.push(["do", "chat:hide"]);
            }
          }
        }, 1000);
      return () => {
          clearInterval(crispInt)
        }
  
      }, [totalPurchased]);


    if (isLoading) {
        return (
            <>
                <div className="app-container">
                    <div className="flex align-middle justify-center w-screen h-screen flex-col">`
                        <div className="text-center">
                            <img src={Planet} alt="Planet" id="planet-mlp" className="margin-auto inline-block" width={300} height="auto"/>
                            <h1 className="text-white font-sofiaSans text-center text-3xl font-bold">Loading...</h1>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            <div className="app-container">
                {children}
            </div>
        </>

    )
}


export default AppContainer;