import React, {Fragment, useState} from 'react'
import {Dialog, Transition} from "@headlessui/react";
import Synaps from "@synaps-io/react-verify";

export default function SynapsModal({
    isOpened,
    closeModal,
    sessionId,
    service,
    lang,
    finishButton,
    handleProcessFinished
}) {
    const [loading, setLoading] = useState(true);

    return (
        <Transition appear show={isOpened} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black-primary bg-opacity-60 backdrop-blur-sm"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="rounded-3xl text-white max-w-3xl transform overflow-hidden bg-gradient-to-b from-primary-modalBlue to-primary-dark p-0 gap-3 flex justify-center items-center shadow-xl transition-all">
                                {loading && <div className="custom-loader absolute z-50" style={{ zoom: '200%' }} />}
                                <Synaps
                                    onReady={() => setLoading(false)}
                                    sessionId={sessionId}
                                    service={service}
                                    lang={lang}
                                    withFinishButton={finishButton}
                                    onFinish={() => {
                                      closeModal();
                                      handleProcessFinished();
                                      setTimeout(() => {
                                        setLoading(true);
                                      }, 500)
                                    }}
                                />
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}