import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Card from "../Card/Card";
import {TbMoneybag} from "react-icons/tb";
import token3D from "../../assets/img/token_1_3D.png";
import TextWithLoader from "../TextWithLoader/TextWithLoader";
import Button from "../Button/Button";
import {BiMessageSquareError, BiMessageSquareCheck} from "react-icons/bi";
import DistributionCalendarAirdropModal from "../DistributionCalendarAirdropModal/DistributionCalendarAirdropModal";
import {auth, extMainApiUrl} from "../../firebase";
import axios from "axios";
import {UserContext} from "../../hooks/useUser";
import LockedTokenModal from "../LockedTokenModal/LockedTokenModal";
import BuyModal from "../BuyModal/BuyModal";
import BuyModalDisabled from "../BuyModalDisabled/BuyModalDisabled";
import VerifyEmailModal from '../VerifyEmailModal/VerifyEmailModal';
import Countdown from '../Countdown/Countdown';

export default function QuestEligibility({ subquest = 'classic' }) {
    const {t} = useTranslation();
    const [verifyEmailModalIsOpened, setVerifyEmailModalIsOpened] = useState(false);
    const [errorCond, setErrorCond] = useState('0');
    const [isEligible, setIsEligible] = useState(true);
    const [distributionCalendarIsOpened, setDistributionCalendarIsOpened] = useState(false);
    const {user, tokenAirdropStats, totalPurchased, fetchTokensBalances, mlcTokenPriceInUsd} = useContext(UserContext);
    const endEligibilityDate = '2024-09-01T23:59:59.000Z';
    // const endEligibilityDate = '2024-07-03T09:38:30.000Z';


    const isDateAfterAirdropClosingDate = (maxDate) => {
        const now = new Date().getTime();
        const max = new Date(maxDate).getTime();
        // console.log(`${now} > 1719340000000`);
        return now > max; // if current timestamp > than 25/06/2024 20:26:40 timestamp
    }

    useEffect(() => {
        // console.log({tokenAirdropStats, ev: user.emailVerified});
        
        
        if (tokenAirdropStats[subquest]?.error) {
            setIsEligible(false);
            setErrorCond('1');
        } else if (tokenAirdropStats[subquest]?.isbanned) {
            setErrorCond('4');
            setIsEligible(false);
        } else if (!tokenAirdropStats[subquest]?.tokenbalance || (tokenAirdropStats[subquest]?.tokenbalance && tokenAirdropStats[subquest]?.tokenbalance <= 0)) {
            setErrorCond('2');
            setIsEligible(false);
        } else if (!user.emailVerified) {
            setIsEligible(false);
            setErrorCond('3');
        } else {
            setErrorCond('');
            setIsEligible(true);
        }
        
        // test force
        // if (subquest == 'classic') {
        //     setErrorCond('');
        //     setIsEligible(true);

        // } else {

        //     setErrorCond('3');
        // }
        // setErrorCond('1');
    }, [user, auth, tokenAirdropStats]);


    const handleVerifyEmail = () => {
    setVerifyEmailModalIsOpened(true)
    }

    const handleCloseVerifyEmailModal = () => {
        setVerifyEmailModalIsOpened(false)
    }
    // useEffect(() => {
    //     getTokenPourchasedBalance()
    // }, [totalPurchased, mlcTokenPriceInUsd]);

    return (
        <Card title={subquest === 'tw' ? t('QuestEligibility.AirdropTokensTw') : t('QuestEligibility.AirdropTokens')} icon={<TbMoneybag size={40}/>} withBorder>
            
          <VerifyEmailModal
            isOpen={verifyEmailModalIsOpened}
            closeModal={handleCloseVerifyEmailModal}
          />
          <DistributionCalendarAirdropModal
              nbToken={tokenAirdropStats[subquest]?.tokenbalance || 0}
              isOpened={distributionCalendarIsOpened}
              closeModal={() => setDistributionCalendarIsOpened(false)}
          />

            <div className="flex justify-start items-center gap-5 -mt-8 pl-2">
                <span onClick={() => subquest === 'classic' ? window.open('https://quest.mylovelyplanet.org/') : window.open('https://trustwallet.mylovelyplanet.org/en')} className="font-passionOne text-xl text-primary-lightBlue cursor-pointer hover:underline">
                {subquest === 'classic' ? 'https://quest.mylovelyplanet.org' : 'https://trustwallet.mylovelyplanet.org'}
                </span>
            </div>

            {(errorCond === '1') && 
            <>
            <div className="text-primary-orange font-passionOne text-xl mt-4 uppercase flex items-center gap-1 -mt-2">
                {t('QuestEligibility.Msg27')}
                &nbsp;
                {subquest === 'classic' ? 'QUEST' : 'TRUST WALLET'}
                
                {t('QuestEligibility.Msg27bis')}
            </div>

            <div className="flex justify-start items-center gap-5">
                <span onClick={() => window.open('https://whitepaper.mylovelyplanet.org/about-us/airdrop-quest/how-to-know-if-you-participated-in-the-airdrop')} className="font-passionOne text-xl text-primary-lightBlue cursor-pointer hover:underline">
                {t('QuestEligibility.Msg28')}
                </span>
            </div>
            <div className="border-2 border-white border-opacity-15">
            </div>
            <button onClick={() => true} disabled
                    className="bg-primary-dark bg-opacity-50 text-white text-md font-bold rounded-3xl p-5 flex items-center gap-3 uppercase underline">
                <BiMessageSquareError size={40}/>
                {t('QuestEligibility.Msg2')}
            </button>
            {isDateAfterAirdropClosingDate(endEligibilityDate) &&
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg3')}
                
            </div>
            }
            {!isDateAfterAirdropClosingDate(endEligibilityDate) &&
            <div className="text-white font-passionOne text-xl mt-4 flex flew-row items-center gap-1">
                <Countdown airdrop={true} date={new Date(endEligibilityDate)}/>
            </div>
            }
            
            <div className="text-white opacity-50 font-passionOne text-xl mt-4 uppercase flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
            {t('QuestEligibility.Msg4')}
            </div>
            
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
            {t('QuestEligibility.Msg5')}
                 
            </div>
            <div className="flex justify-start items-center gap-5">
                <span onClick={() => window.open('https://whitepaper.mylovelyplanet.org/about-us/airdrop-quest/step-1-mail-validation')} className="font-passionOne text-xl text-primary-lightBlue cursor-pointer hover:underline">
                {/* {t('QuestEligibility.Msg')} */}
            {t('QuestEligibility.Msg6')}
                </span>
            </div>
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg7')}
               
            </div>

            {/* <div className="text-white opacity-50 font-passionOne text-xl mt-4 uppercase flex items-center gap-1">
                Additionnal help
            </div> */}
            </>
            }

            {(errorCond === '2') && 
            <>
            <button onClick={() => true} disabled
                    className="bg-primary-dark bg-opacity-50 text-white text-md font-bold rounded-3xl p-5 flex items-center gap-3 uppercase underline">
                <BiMessageSquareError size={40}/>
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg8')}
                
            </button>
            {isDateAfterAirdropClosingDate(endEligibilityDate) &&
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg9')}
                
            </div>
            }
            {!isDateAfterAirdropClosingDate(endEligibilityDate) &&
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                <Countdown airdrop={true} date={new Date(endEligibilityDate)}/>
            </div>
            }
            
            <div className="text-white opacity-50 font-passionOne text-xl mt-4 uppercase flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
                
                {t('QuestEligibility.Msg4')}
            </div>
            
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
                If you think your may have token to claim, please contact us at support@mylovelyplanet.org with screenshots and details.
            </div>
            {/* <div className="flex justify-start items-center gap-5">
                <span onClick={() => window.open('https://quest.mylovelyplanet.org/')} className="font-passionOne text-xl text-primary-lightBlue cursor-pointer hover:underline">
                https://quest.mylovelyplanet.org/
                </span>
            </div> */}
            {/* <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                If the amount is different please contact us at support@mylovelyplanet.org with screenshots and details.
            </div> */}

            </>
            }

            {(errorCond === '3') && 
            <>
            <button onClick={() => true} disabled
                    className="bg-primary-dark bg-opacity-50 text-white text-md font-bold rounded-3xl p-5 flex items-center gap-3 uppercase underline">
                <BiMessageSquareError size={40}/>
                {/* {t('QuestEligibility.Msg')} */}
                You're not eligible because your email is not verified.
            </button>
            {isDateAfterAirdropClosingDate(endEligibilityDate) &&
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg9')}
            </div>
            }
            {!isDateAfterAirdropClosingDate(endEligibilityDate) &&
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                <Countdown airdrop={true} date={new Date(endEligibilityDate)}/>
            </div>
            }
            
            <div className="text-white opacity-50 font-passionOne text-xl mt-4 uppercase flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg4')}
            </div>
            
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg10')}
                
            </div>
            <div className="flex justify-start items-center gap-5">
                <span onClick={() => window.open('https://whitepaper.mylovelyplanet.org/about-us/airdrop-quest/step-1-mail-validation')} className="font-passionOne text-xl text-primary-lightBlue cursor-pointer hover:underline">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg11')}
                </span>
            </div>
            <div className="flex justify-start items-center gap-5">
                <span onClick={() => handleVerifyEmail()} className="font-passionOne text-xl text-primary-lightBlue cursor-pointer hover:underline">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg12')}
                
                </span>
            </div>
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg7')}
            </div>
            </>
            }

            {(errorCond === '4') && 
            <>
            <button onClick={() => true} disabled
                    className="bg-primary-dark bg-opacity-50 text-white text-md font-bold rounded-3xl p-5 flex items-center gap-3 uppercase underline">
                <BiMessageSquareError size={40}/>
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg14')}
            </button>
            {/* <div className="text-white opacity-50 font-passionOne text-xl mt-4 uppercase flex items-center gap-1">
                
                {t('QuestEligibility.Msg4')}
            </div> */}
            
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                <p className='m-0 p-0'>
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg14bis')}
                 
                &nbsp;<span onClick={() => window.open('mailto:support@mylovelyplanet.org')} className="font-passionOne text-xl text-primary-lightBlue cursor-pointer hover:underline"> 
                support@mylovelyplanet.org 
                </span>&nbsp;{t('QuestEligibility.Msg15')}
                 
                </p>
            </div>


            {isDateAfterAirdropClosingDate(endEligibilityDate) &&
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg9')}
            </div>
            }
            {!isDateAfterAirdropClosingDate(endEligibilityDate) &&
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                <Countdown airdrop={true} date={new Date(endEligibilityDate)}/>
            </div>
            }
            

            </>
            }
            {isEligible && 
            <>
            <button onClick={() => setDistributionCalendarIsOpened(true)}
                    className="bg-lime-600 text-white text-md font-bold rounded-3xl p-5 flex items-center gap-3 uppercase underline">
                <BiMessageSquareCheck size={40}/>
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg16')}&nbsp;
                 {tokenAirdropStats[subquest]?.tokenbalance}
                 &nbsp;
                {t('QuestEligibility.Msg17')}
            </button>
            
            <div className="text-white opacity-50 font-passionOne text-xl mt-4 uppercase flex items-center gap-1">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg18')}
            </div>
            
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                <p className='m-0 p-0'>
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg19')}
                </p>
            </div>
            <div className="flex justify-start items-center gap-5">
                <span onClick={() => setDistributionCalendarIsOpened(true)} className="font-passionOne text-xl text-primary-lightBlue cursor-pointer hover:underline">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg20')}
                </span>
            </div>

            
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                <p className='m-0 p-0'>
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg21')}
                </p>
            </div>
            <div className="flex justify-start items-center gap-5">
                <input
                        type="text"
                        name="wallet"
                        id="wallet"
                        readOnly
                        className="w-full text-white placeholder-white font-passionOne px-6 py-4 bg-primary-dark rounded-xl xl:text-lg"
                        value={tokenAirdropStats[subquest]?.address || '?'}
                        />
            </div>


            
            {tokenAirdropStats[subquest]?.tgeScAddress && tokenAirdropStats[subquest]?.tgeScAddress !== '' &&
            <>
             <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                <p className='m-0 p-0'>
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg22')}
                
                </p>
            </div>
            <div className="flex justify-start items-center gap-5">
                <p className='m-0 p-0'>
                    <span onClick={() => window.open('https://whitepaper.mylovelyplanet.org/about-us/airdrop-quest/step-2-claim-if-eligible')} className="font-passionOne text-xl text-primary-lightBlue cursor-pointer hover:underline">
                    {/* {t('QuestEligibility.Msg')} */}
                    {t('QuestEligibility.Msg23')}
                    
                    </span>
                    <br />
                    <span onClick={() => window.open(`https://app.sablier.com/airstream/${tokenAirdropStats[subquest]?.tgeScAddress}-137/`)} className="font-passionOne text-xl text-primary-lightBlue cursor-pointer hover:underline">
                    {/* {t('QuestEligibility.Msg')} */}
                    {t('QuestEligibility.Msg24')}
                    
                    </span>
                </p>
            </div>
            </>
            }
            {!(tokenAirdropStats[subquest]?.tgeScAddress && tokenAirdropStats[subquest]?.tgeScAddress !== '') &&
            <>
            <div className="text-white font-passionOne text-xl mt-4 flex items-center gap-1">
                <p className='m-0 p-0'>
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg25')}
                
                </p>
            </div>
            <div className="flex justify-start items-center gap-5">
                <span onClick={() => window.open('https://whitepaper.mylovelyplanet.org/about-us/airdrop-quest/step-2bis-eligible-for-2nd-claim-phase')} className="font-passionOne text-xl text-primary-lightBlue cursor-pointer hover:underline">
                {/* {t('QuestEligibility.Msg')} */}
                {t('QuestEligibility.Msg26')}
                
                </span>
            </div>
            </>
            }
            </>
            }

        </Card>
    )
}