import React from 'react';
import {useTranslation} from 'react-i18next';

export default function Alert(props) {
    const {t} = useTranslation();

    return (
        <div className={`w-full flex flex-col md:flex-row gap-5 p-5 md:p-2 md:gap-0 text-center items-center justify-center text-sm rounded-2xl ${props.otherCssClass}`}>
            <span className="mr-6 font-sofiaSans text-primary-dark text-opacity-90 font-bold text-lg">{t(props.message)}</span>
            {props.button && props.button !== '' && (
              <button
                onClick={props.onClick}
                className="text-lg font-passionOne text-center px-3 py-1 bg-primary-dark bg-opacity-40 border-none rounded-xl text-white">{props.button}
              </button>
            )}
            {props.children}
        </div>
    )
}