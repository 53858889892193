const routes = {
    HOME: '',
    LOGIN: '/login',
    REGISTER: '/register',
    PROFILE: '/admin/profile',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    DASHBOARD: '/admin',
    TRANSACTIONS: '/admin/transactions',
    AIRDROPS: '/admin/airdrops',
    MAIN_WEBSITE: 'https://mylovelyplanet.org',
    WHITEPAPER: 'https://whitepaper.mylovelyplanet.org/',
    TUTORIAL: 'https://imagine3835.zendesk.com/hc/en-gb',
}


export const publicRoutes = [
    routes.HOME,
    routes.LOGIN,
    routes.REGISTER,
    routes.FORGOT_PASSWORD,
    routes.RESET_PASSWORD,
]

export default routes