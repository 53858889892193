import React, {useEffect, useState} from 'react';

export default function Input(props) {

    const [autocomplete, setAutocomplete] = useState('');

    useEffect(() => {

        if (props.autocomplete) {
            setAutocomplete(props.autocomplete)
        } else {
            setAutocomplete('')
        }
    })

    return (
        <>
            <label htmlFor={props.id} className="block font-medium text-white uppercase text-base font-passionOne outline-0">{props.label}</label>
            <div className="mt-1 w-full">
                <input
                    {...props}
                    type={props.type}
                    name={props.name}
                    id={props.id}
                    className={`block w-full rounded-xl bg-white bg-opacity-10 border-white border-opacity-10 border-2 text-sm md:text-base xl:text-xl py-3 px-5 text-white font-passionOne ${props.otherClass ? props.otherClass : ''}`}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.changed}
                    required={props.required}
                    autoComplete={autocomplete}
                    disabled={props.disabled ? props.disabled : false}
                    onPaste={props.onPaste}
                />

                {props.after}

                {!props.valid && props.touched && (<p className="mt-2 text-sm text-red-600"
                                                      id={props.id + '-error'}>{props.errorMessage ? props.errorMessage : ''}</p>)}
            </div>
        </>
    )
}