import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: 'GB',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            GB: {
                translation: {
                    ClaimToken: {
                        AlreadyInProgress: 'You have a claim already in progress.',
                        SuccessClaim: 'You have claimed your $MLC successfully 🎉 !',
                        ErrorClaim: 'An error ocurred while claiming your token, please try again in few minutes or refresh the page.',
                        ErrorClaimTooLong: 'An error ocurred while claiming your token, may be your transaction is too long. Please check your wallet in few minutes or refresh the page.',
                        WalletNotConnected: 'To claim your token, you must connect your wallet.',
                        NotEnoughToken: 'You cannot claim because your available token balance is insufficient.',
                    },
                    Common: {
                        TokenSafeError: 'Oops, loading error, funds are safe',
                        Step1: 'Step 1',
                        Step2: 'Step 2',
                        Step3: 'Step 3',
                        Step4: 'Step 4',
                        Step5: 'Step 5',
                        Step6: 'Step 6',
                        Nextstep: 'Next step',
                        PrevStep: 'Go Back',
                        Processing: 'Processing',
                        Ok: 'Ok',
                        Month: 'Month',
                        Months: 'Months',
                        ClickMetamaskInstall: 'Click here to Install Metamask',
                        CliffEnd: 'End of Cliff / Start token release',
                        CliffDetails: '(*) You will be able to claim your tokens starting the 20th of september and everyday until the end of the vesting. The number displayed in front of the month are the total tokens you will be able to claim during that month',
                    },
                    BuyMlc: "Buy $MLC",
                    RecoveryAddress: {
                        MsgContent: {
                            ErrorStep1: "Please check the mandatory checkbox",
                            ErrorStep2: "Please check the mandatory disclaimer checkbox",
                            ErrorStep4: "Please check all the mandatory checkboxes",
                            ErrorStep5: "Wallet link has failed, please try again",
                            CheckVideoWatched: "I have watched the video",
                            ImportantExplanation: "Watch out very important explanation",
                            Disclaimer: "Disclaimer",
                            DisclaimerDetails1: "I understand that after I connect My Wallet, I will receive my $MLC tokens on this wallet and only there.",
                            DisclaimerDetails2: "I understand that if I lose access to my wallet, My Lovely Planet team will not technically be able to send me all my tokens claimed $MLC tokens to any another wallet nor help me retreive my $MLC that are already on this connect wallet.",
                            CheckDisclaimer: "I have read the disclaimer above and i fully accept it",
                            EmailVerification: "Identity verification by email",
                            EmailVerificationExplanationOnly: "Click on the button below in order to receive your unique code to verify your email.",
                            EmailVerificationExplanation: "Click on the button below in order to receive your unique code to verify your email and proceed with wallet connection.",
                            LastDisclaimer: "Last disclaimer before connection",
                            CheckLastDis1: "I confirm I have carefully watched the previous video and read the disclaimer",
                            CheckLastDis2: "I acknowledge i will no longer be able to change my wallet adress",
                            CheckLastDis3: "I ACKNOWLEDGE I WILL LOSE ALL MY UNCLAIMED $MLC IF I LOSE ACCESS TO MY WALLET",
                            WalletLinking: "Connect to your wallet",
                            WalletLinkingExplanation: "This page will allow you to sign this address below to ensure you are the owner.",
                            WalletLinkingExplanation2: "First click on connect to connect your wallet on Polygon network and then you can click on 'Add Polygon Address' to link definitely your wallet with your My Lovely Planet account.",
                            WalletLinkingExplanation3: "If your wallet is not visible and you have just installed Metamask or Trustwallet, please refresh the MyLovelyPlanet page..",
                            WalletLinkingInProgress: "Your wallet is about to be linked",
                        },
                        NextStep: "Next step",
                        WalletAdded: "Connected",
                        AccountConnected: "Your account is now connected",
                        GoodNetwork: "You are connected to the good network : Polygon network",
                        Signed: "You have validated your public address",
                        AccountNotConnected: "Your account is not connected",
                        BadNetwork: "You are not connected to the good network (Please connect to Polygon Network)",
                        NotSigned: "You have not signed the message yet",
                        ClickAgain: "Click again on the button below to sign and validate your address",
                        ChooseAnotherNetwork: "Choose another network",
                        ConnectYourAccount: "Connect your account (open Metamask in your extensions and enter your password)",
                        SignYourMessage: "Sign message in Metamask",
                        WatchTutorialHere: "Watch tutorial here",
                        TryAgain: "Click here to try again",
                        Auth: {
                            Title: "Add Public Address",
                            Subtitle: "To connect your wallet click to the following button",
                            Question1: "",
                            Question2: "You must connect your wallet in order to receive your $MLC token",
                            CloseButton: "Close",
                            InputPlaceholder: "Copy paste the code received by email",
                            SendEmailButton: "Send me my code by email",
                            SendEmailInProgress: "Sending email...",
                            ValidateCodeButton: "Validate code",
                            ValidationInProgress: "Validating code...",
                        },
                        Step1: {
                            Title: "Add your public address",
                            Subtitle: "Step 1",
                            Question: "What kind of wallet do you use ?",
                            ConnectWithMetamask: "I use Metamask Wallet",
                            ConnectWithOtherWallet: "I don't use Metamask / I don't have any Web3 Wallet",
                        },
                        Step2Metamask: {
                            Title: "Add your public address",
                            Subtitle: "Step 2",
                            Question: "Click on the button below to connect your wallet",
                            ConnectWithMetamask: "Metamask",
                            Error: "You are not connected on polygon blockchain (Matic Network)",
                            MessageToSign: "Confirm your wallet address",
                            MetamaskNotInstalled: "Metamask is not installed. Please follow instructions to install it and refresh the page",
                        },
                        Step2OtherWallet: {
                            Title: "Add your public address",
                            Subtitle: "Step 2",
                            Question: "Setup your first metamask wallet",
                            IHaveSetupMetamask: "I have setup metamask",
                            IKnowWherePublicAddressIsLocated: "I know where my public address is located",
                            MetamaskIsInMyFavroitesExtensions: "Metamask is in my favorite extensions",
                            IHaveAddedPolygonNetwork: "I have added Polygon network To Metamask",
                            Cancel: "Cancel",
                            Start: "Start",
                        },
                        Step3Metamask: {
                            Title: "Done",
                            Subtitle: "YOUR PUBLIC ADDRESS IS NOW LINKED TO YOUR ACCOUNT",
                        }
                    },
                    General: {
                        Edit: "Edit",
                        SelectLanguage: "Select Language",
                        SelectCountry: "Select your country",
                        Claim: "Claim",
                        ClaimInProgress: "Claim in progress..",
                        PurchasedToken: "Purchased token",
                        ReferralToken: "Referral token",
                        BonusToken: "Bonus token",
                        Days: 'Days',
                        Hours: 'Hours',
                        Min: 'Min',
                        Sec: 'Sec',
                        SelectYourLanguage: "Select your Language",
                        AreYouInvited: "Referral code (OPTIONAL)",
                        DaysLeft: "You have ",
                        DaysLeftAfter: ' left to submit identity documents',
                        Years: "  Years",
                        Year: ' Year',
                        Vesting: 'Vesting',
                        YourProfile: "Your Profile",
                        Logout: "Logout",
                        YouAreInvitedBy: "You are invited by",
                        YouWillReceiveMoreTokens: "You will receive 10% more on your first transaction",
                        Save: "Save",
                    },
                    CurrentRound: {
                        Current: "Current round",
                        Title: "Current round 10 : 50% of Launch Price",
                        LaunchPrice: "Launch Price",
                        Mlcremaining: "Remaining Token",
                        End: 'End of round before price increase',
                        Start: 'Time before presale starts',
                    },
                    Header: {
                        Dashboard: "Dashboard",
                        AirDrops: "Airdrops",
                        Buy: "Buy tokens",
                        ICO: "Private and public sale Distribution",
                        YourProfile: "Your profile",
                        Logout: "Logout",
                        Transactions: "Transactions",
                        MyToken: "My MLC Token",
                        MainWebSite: "Main website",
                        TypeHere: "Type here...",
                        Referral: "Referral",
                        SuperAdmin: "Super Admin",
                        Contact: "Contact",
                        ReferralEvent: "Referral event",
                    },
                    AirDrops : {
                        Title: 'Airdrops',
                    },
                    ReferralEvent: {
                        TitleRE: "Referral Event",
                        EnormousSecretPrice: "Enormous Secret Price",
                        Sponsorship: "Referral(s)",
                        Title1: "MAGIC CHANCE CHALLENGE - PRIZE POOL of ",
                        PricePool: "1000 $USDT",
                        ToWin: "to win !",
                        ReadMore: "Read more",
                        AlertDescEvent: "Discover the rules of the event",
                        NumberOfTickets: "YOUR MAGIC TICKET AMOUNT",
                        NumberOfTicketsTotal: "TOTAL MAGIC TICKETS AT STAKE",
                        DrawDate: "End date of the event",
                        Title2: "YOUR EVENT STATISTICS",
                        NbReferrals: "Number of referral(s)",
                        CurrentRank: "Current Rank",
                        WinTokens: "Tokens Earned",
                        NextRank: "Next rank in",
                        Title3: "YOUR CURRENT REWARDS AS",
                        USDT: "USDT",
                        MLCtokens: "$MLC Tokens",
                        VIPinvitation: "VIP Invitation",
                        MagicTicket: "Magic Ticket",
                        SecretNFT: "Secret Exclusive NFT",
                        EventRules: "Event Rules",
                        SlideOver: {
                            A: "A - Each referral helps you level up.",
                            B: "B - A referral is considered valid if the referred person makes their first investment.",
                            C: "C - The referred person must complete the KYC process to receive their tokens and rewards.",
                            D: "D - With each new referral, the referrer moves up on the 'Lovely Medals' scale.",
                            E: "E - The referred person receives a 10% bonus on their first investment.",
                            F: "F - Each new achieved level grants specific rewards as indicated in the 'Rewards Table'.",
                            G: "G - The number of tokens earned at each level is calculated based on the rate of 1 $MLC = 0.1$ (listing price).",
                            H: "H - The earned USDT and $MLC tokens at each level will be sent to the referrer's linked public address.",
                            I: "I - Only one winner will be selected for the 'Magic Chance Challenge'.",
                            J: "J - The prize for the 'Magic Chance Challenge' will be distributed in USDT directly to the winner's public address.",
                            K: "K - Reward distribution on October, 1st 2023.",
                        }
                    },
                    Referral: {
                        Title: "Sponsorship",
                        AlertDesc: "Discover referral program",
                        AlertDescrVesting: "Discover your vesting program",
                        ReadMore: "Read more",
                        ShareOnLinkedin: "Share on Linkedin",
                        ShareOnTwitter: "Share on Twitter",
                        ReferralList: "Referral list",
                        BonusDetails: "Your referral bonus details",
                        BonusReferral: "% bonus",
                        NbReferral: "Number of referrals",
                        BonusRegister: "Register Bonus",
                        BonusTotal: "Total bonus",
                        YouHaveBeenInvitedBy: "You have been invited by ",
                        YouWillReceive: "You will receive 10% bonus on your first purchase",
                        YouReceived: "You already received your 10% bonus. referral bonus works only on first transaction.",
                        YouHaveNotBeenInvited: "Sponsorship only works on the first transaction. If you are not sponsored and you have already made your 1st transaction, you will not be able to be sponsored. But you can sponsor other users with your code available in this area",
                        EnterYourCode: "Enter your referral code. Only valid for your first transaction",
                        Error: "You cannot set your own referral code",
                        Table: {
                            Name: "User name",
                            EarnedToken: "Earned token",
                            RegisterDate: "Register date",
                            Status: "Status",
                            Cancelled: "Cancelled",
                            NbTransactions: "Nb transactions"
                        },
                        SlideOver: {
                            Title: "Referral program",
                            Descr: "For each new referral, you receive a percentage of their first transaction in the form of bonus tokens. The referral also earns a 10% bonus thanks to your referral code. The bonus is calculated on the basis of confirmed transactions from verified users.",
                            BaseLine: "More referrals = more bonuses",
                            Referrals: "For each referrals",
                            moreThan100: "100 et +"
                        }
                    },
                    Dashboard: {
                        Title: 'Dashboard',
                        TokenAddr: 'Token Address',
                        TokensBalance: 'Tokens available to claim',
                        PurchasedTokens: 'Bought Tokens',
                        BuyTokenForAll: 'Get Tokens',
                        Equivalent: 'Equivalent to',
                        TokenName: "Token Name",
                        TicketSymbol: 'Ticket Symbol',
                        WhitePaper: "WhitePaper",
                        Tutorial: 'FAQ',
                        TokenCalculation: "Token Calculation",
                        TokenCalculationDesc: "Enter amount to calculate token.",
                        TokenCalculationDesc2: "* Amount calculated based current tokens price",
                        BuyTokens: "$MLC in connected wallet",
                        StepsToDo: 'Steps To Do',
                        Feeds: {
                            Title: "News",
                        }
                    },
                    VersionUpdate: {
                        Message: 'A new version is available, please reload the page.',
                    },
                    SessionExpired: {
                        Message: 'Your session has expired, please reload the page.',
                        Reload: 'Reload',
                    },
                    AddWalletTuto : {
                        Title: 'How to create a wallet',
                        MsgContent: {
                            ImportantExplanation: 'Watch this video and click on ok when you are ready to go on with the wallet connexion process.'
                        },
                    },
                    Wallet: {
                        NoAdressConnected: "NO ADDRESS CONNECTED",
                        Unverified: "UNVERIFIED WALLET",
                        Wrong: "WRONG WALLET",
                        Disco: "DISCONNECTED",
                        Net: "WRONG NETWORK",
                    },
                    Profile: {
                        Title: "Profile details",
                        Updated: "Profile updated",
                        NotUpdated: "There is an error, please contact support",
                        Remember: "Remember to add your wallet address before 2023, Dec 30",
                        PersonalData: "Personal Data",
                        Settings: "Settings",
                        Name: "Name",
                        Phone: "Phone",
                        UpdateProfile: "Update profile",
                        UpdatedProfile: "Your profile is updated",
                        EnableNotifications: "Enable notifications",
                        SaveLogs: "Save Logs",
                        EnableNewsletter: "Enable newsletter",
                        SendEmailVerification: "Email sent. Please verify your checkbox and spam folder",
                        EmailSent: "Email sent. Please verify your checkbox and spam folder",
                        Reload: 'Reload page after clicking on email link',
                        PasswordDoesNotMatch: 'Password are different',
                        PasswordTooShort: 'Password must be at least 6 characters',
                        PasswordReset: 'Password reset successully',
                        PasswordNotReset: 'Password reset failed',
                        NoWallet: "I don't have a wallet or I'm a beginner",
                        IHaveAWallet: "I have a Wallet",
                        Switch: "Change Wallet",
                        WalletStatusConfirm: "Confirm this address",
                        EmailNotSent: "Email not sent, please try again later",
                        IdontSeeMyWallet: "You don't see your wallet ? Refresh",
                        Password: {
                            General: "Password",
                            Old: "Old password",
                            New: "New password",
                            Confirm: "Confirm new password",
                            Update: "Update password",
                            Send: "Send reset password email",
                            Sent: "If your email exists, check your mailbox to reset password"
                        },
                        Email: {
                            SaveNewPassword: "Save new Password",
                            ResetPassword: "Reset Password",
                            Error: "If your email exists, check your inbox to reset your password",
                            SendResetLink: "Send password reset link",
                            GoBackToLogin: "Go back to login page",
                            ForgottenPassword: "Forgotten password"
                        },
                        AccountStatusTitle: "Your account status",
                        EmailVerified: "Email verified",
                        EmailSendCode: "Send code by email",
                        EmailResendCode: "Resend code by email",
                        EmailResendCodeIn: "Resend code by email in ",
                        EmailVerify: 'Save code',
                        VerifyEmail: "Verify email",
                        EmailNotVerified: "Email not verified",
                        KYCPending: "KYC pending",
                        RefusedDocument: "Document refused",
                        WalletConnected: "Wallet currently connected",
                        WalletConnectedToProfile: "Registered address to claim",
                        Wallet: "Wallet",
                        WalletStatusAddPolygonAddress: "Connect Wallet",
                        WalletStatusConnected: "Wallet Connected",
                        WalletStatusToConnect: "Connect Wallet",
                        WalletStatusChangeNetwork: "Change network to Polygon",
                        WalletStatusWrongWallet: "Wrong Wallet - Switch Wallet",
                        WalletAddByWalletConnect: "Connect with ",
                        SupportedWallets: "Recommended wallets are Metamask and TrustWallet.",
                        PayWith: "Pay with ",
                        PayByOtherWallet: "Continue",
                        Continue: "Continue",
                        Referral: {
                            Title: "Earn with",
                            Title2: "referral",
                            Desc: "Invite your friends & family and receive a bonus.",
                            Copied: "Link is copied",
                        },
                        WalletConnectedInfo: {
                            Desc: "In order to get your $MLC you need to add you Polygon address by connecting your Wallet.",
                        },
                        KYC: {
                            Title: "Identity Verification - KYC",
                            Desc: "In order to get your $MLC you need to complete your KYC.",
                            Desc2: "You have not submitted your KYC application to verify your identity.",
                            Proceed: "Verify my identity",
                            Status: {
                                Title: "Status",
                                NotSub: "Not submitted",
                            },
                            Submitted: "You have submitted your identity elements. They are being verified by our teams.",
                            Rejected: "Your identity elements was rejected by our compliant team. Please try once again",
                            Validated: "Your identity has been confirmed with success",

                        },
                        Quest: {
                            Link: 'Airdrop Claim',
                            MessageAlert: 'For the airdrop quest (QUEST or TRUSTWALLET), no KYC needed and in order to claim your token follow the instructions on the following button '
                        },
                    },
                    MyToken: {
                        Title: "My MLC Token",
                        TitleBalance: "Tokens Balance",
                        BuyMore: "Buy more token",
                        Total: "Total token amount",
                        PurchaseToken: "Purchased token",
                        ReferralToken: "Referral token",
                        BonusesToken: "Bonuses token",
                        TotalContributed: "Total contributed"
                    },
                    Help: {
                        Help: 'Help',
                        HowToClaimInvest: 'How to claim your tokens from investment',
                        HowToClaimInvestQuest: 'How to claim your tokens from quests, Trust Wallet, and airdrops.',
                        HowToBuySell: 'How to buy or sell $MLC',
                        HowToSeeToken: 'View your $MLC on MetaMask',
                    },
                    Balance: {
                        TokensLockedVesting: "Tokens remaining locked in vesting",
                        TokensBoughtPresale: "Total tokens bought in presale",
                        DistributionCalendar: "View Distribution Calendar",
                        DistributionCalendarModal: {
                            Title: 'Release Calendar',
                            Month: 'Month',
                            MLC_Released: '$MLC Released',
                            ClaimAvailableMessage: 'Claim will be available on 25th June',
                        },
                        BuyDisabledModal: {
                            Title: 'Buy',
                            BuyAvailableMessageDisabledPopup: 'Buy will be available at TGE the 25th of June at 3pm UTC',
                        },
                        ClaimModal: {
                            Title: 'Claim',
                            ClaimAvailableMessage: 'Claim will be available on 25th June',
                            ClaimAvailableMessageDisabledPopup: 'Your claimable tokens will be available at TGE the 25th of June at 3pm UTC',
                            BuyAvailableMessage: 'Buy will be available on 25th June',
                            ClaimAvailableMessageNoKYC: 'Claim is not available because your KYC must be completed.',
                            ClaimAvailableMessageDefault: 'Claim is not available now, please try again in few moment.',
                            BuyAvailableMessageDefault: 'Buy is not available now, please try again in few moment.',
                        },
                    },
                    QuestCountDown: {
                        Title: 'Next Airdrop release',
                        Msg: 'Once this countdown is at 0 the token in your unclaimed token will be automaticaly send to your wallet.',
                        MsgAlert: 'to become eligible, after this time airdrop will be closed.',
                    },
                    QuestEligibility: {
                        Claim: 'How to claim ?',
                        AirdropTokens: 'Airdrop Classic Quest',
                        AirdropTokensTw: 'Airdrop Trust Wallet',
                        CheckStatus: 'Check eligibility',
                        Msg1: 'Check your eligibility to see if you get some $MLC tokens from Airdrops',
                        Msg2: 'You\'re not eligible because your email was not found in the quest.',
                        Msg3: 'Airdrop time to become eligible is closed there is no possibility to claim token even if you fix your status now.',
                        Msg4: 'How to fix this status',
                        Msg5: 'Please follow the steps and read carrefully the part concerning quest email :',
                        Msg6: '👉 How to become eligible for airdrop',
                        Msg7: ' When you have well followed described steps in the link, you can click again on check eligibility button in the top of this page.',
                        Msg8: 'You have 0 token to claim for this quest',
                        Msg9: 'Airdrop time to become eligible is closed there is no possibility to claim token even if you fix your status now.',
                        Msg10: 'Please follow the steps and read carrefully the part concerning email verification : ',
                        Msg11: '👉 How to become eligible for airdrop by verifying my email',
                        Msg12: '👉 Also you can click here to verify your email',
                        Msg13: 'Your account has been banned',
                        Msg14: 'We have banned account that invited bots.',
                        Msg14bis: 'If you think your invitation code has not been used by bots, please contact us at',
                        Msg15: 'with screenshot proof that you invited real people.',
                        Msg16: 'You\'r eligible with',
                        Msg17: '$MLC to claim',
                        Msg18: 'What\'s next',
                        Msg19: 'Your airdrop is validated, you have nothing to do we will send you your $MLC token after the cliff in accordance to your release calendar available here : ',
                        Msg20: '📆 Open my release calendar',
                        Msg21: 'This is the wallet which will receive the airdrop :',
                        Msg22: 'Please note your TGE airdrop is validated in a Sablier smartcontract, be sure to claim your TGE amount by yourself with the following links :',
                        Msg23: '👉 How to claim my airdrop (only TGE) with Sablier',
                        Msg24: '👉 Click here to open Sablier App',
                        Msg25: 'Please note your airdrop is validated after the TGE snapshot, so we will airdrop your 5% TGE in bundle with the first month of vesting token airdrop, more details about airdrop here :',
                        Msg26: '👉 How my airdrop token will be airdroppped ?',
                        Msg27: 'If you have not participated to the "AIRDROP',
                        Msg27bis: '" please ignore all that is below.',
                        Msg28: '❔ How to know if I participated to this airdrop ?',
                    },
                    Buy: {
                        Title: "Buy my lovely coin - $MLC ",
                        MaxLimit: "You have already reached the maximum token purchase limit for this sale.",
                        Password: "Update your password",
                        AirdropMark: 'Airdrop Claim',
                        Buy: 'Buy $MLC',
                        HowToSeeMyToken: 'See my tokens',
                        HowToSeeMyTokenMsg1: 'To view your tokens directly in your wallet (Metamask or other Polygon-compatible wallets), you will need to add the token to the list of visible tokens in your wallet. By default, only main blockchains like Ethereum, Polygon, etc., are displayed in wallets.',
                        HowToSeeMyTokenMsg2: 'Follow the steps below to add your token to your wallet:',
                        HowToSeeMyTokenMsgLink: 'Steps',
                        Join: 'Join the greatest game for climate protection',
                        Secure: "Secure payment with",
                        With: "Card payment purposed by",
                        AlreadyCollected: "Already collected",
                        usersRegistered: 'Nb Registers',
                        PleaseBeforeStarting: "Please before starting",
                        ForbiddenCountries: "You can't buy $MLC",
                        IsMajor: "You are 18 or more",
                        SelectYourCountry: "Select your country of residence",
                        YouAccept: "You accept",
                        TAC: "General terms of sale",
                        KYC: "I understand I will have to verify my identity with a KYC to receive my My Lovely Coin",
                        Start: "Start",
                        Cancel: "Cancel",
                        OK: "Ok",
                        PasswordEmailSent: "An account associated with this email already exists, you have probably already played MyLovelyPlanet mobile game. For security reasons click here to reset your password and access the site (this will also change your game password)",
                        Step1: "Step 1 - Payment method",
                        Step2: "Step 2 - Select amount",
                        Payment: "Amount",
                        YourAmount: "Your amount",
                        WrongBalance: "Minim 200 €",
                        WrongCryptoBalance: "Minim ",
                        MaxCryptoBalance: "and Max : ",
                        ChooseVestingBonus: "Choose Your Vesting Bonus",
                        LearnMore: "Learn more",
                        YouReceive: "You receive",
                        Purchased: "purchased",
                        Bonus: "$MLC bonus",
                        Referral: "$MLC referral",
                        BuyNow: "Buy now",
                        TransferAmount: "Transfer amount",
                        PaymentAmount: "Payment amount",
                        TransferIndications: "Transfer indications",
                        OtherWalletIndications: "Send crypto to:",
                        TransferIndicationsDetails: "Indicate this reference into your transfer",
                        YourPublicWalletAddress: "Fill in your wallet's public key",
                        AlreadyUsed: "This wallet address is already used in another account. Please check your wallet address",
                        Iban: "IBAN",
                        Bic: "BIC",
                        TransferConfirmation: "I confirm i did the transfer",
                        EtherscanFeedback: "Follow your transaction here",
                        TY: "Thank you",
                        ShowTransaction: "You can see the status of your transaction in the transaction section. It usually takes 3 to 5 days to receive and confirm your payment by bank transfer and around 20 minutes for crypto payment.",
                        ShowTransactionByCard: "You can see the status of your purchase in the transaction section.",
                        ViewTransaction: "View transaction",
                        ChoosePaymentMethod: "Choose your payment method",
                        ProcessCrypto: "Don't quit the page during process",
                        ShowCryptoTransaction: "Show transaction here",
                        CryptoRejected: "Transaction was rejected or cancelled",
                        InsufficientBalance: "Insufficient balance",
                        CoinGeckoError: "CoinGecko is not responding. Please try again later",
                        YourCountryIsNotAuthorized: "Your country is not in the authorized list",
                        AcceptConditions: "Please accept conditions",
                        FalseAmount: "Amount must be between 200 and 5000 €",
                        CoingeckoError: "Coingecko api is not currently operational. Choose another payment method or wait for few minutes. Click here to see Coingecko status",
                        VerifyEmail: "Please make sure your public key is correct, this key will be used to authentify your payment",
                        TransactionHash: "Transaction Hash",
                        Warning: "WARNING: WITHOUT HASH TRANSACTION, THE VERIFICATION OF YOUR PAYMENT WILL BE AFFECTED AND MAY TAKE SEVERAL DAYS...",
                        ValidateWarning: "I confirm transaction hash",
                        SavedWithSuccess: "Saved with success",
                        Error: "Not saved",
                        WarningCrypto: "You have to send your crypto on ETH Mainnet, BNB Chain Mainnet or Polygon Mainnet ONLY.",
                        SaleIsOver: "Presale is over. Join Discord to support the project.",
                        BuyDisableMessage: "Wallet features are disabled on mobile and tablet",
                        BuyModal: {
                            Title: 'Buy',
                            UniswapMsg: 'Uniswap will be available 15-30min after the CEX to let us the time to lock liquidity and create the official pool.',
                            BuyAvailableMessage: 'Buy not available for now',
                        },
                        LockedTokenModal: {
                            Title: 'Tokens remaining locked in vesting',
                            LockedMessage1: 'Your MLC tokens will be distributed according to your vesting calendar. This means that you will receive a percentage of your total MLC at listing and the rest will be daily unlocked according to your vesting calendar (they become "claimable").',
                            LockedMessage2: 'Your vesting calendar depends on what you have chosen when you bought it. You can have different vesting calendar according to different purchase. Your vesting calendar is available at all times on click on the associated button in your Dashboard and the number of tokens (in percentage) you will have at listing is detailed in the "transaction" table in "TGE" column.',
                            LockedMessage3: 'To understand precisely how this will unlock, here is an example for a purchase of 10000 $MLC tokens at 2 years vesting:',
                            LockedMessage4: 'Nota 1 : you do not have to come and claim your tokens everyday; you can come whenever you want and claim all the currently available tokens.',
                            LockedMessage5: 'Nota 2 : your vesting period cannot be modified; it is hard coded in the smart contract which handles your claimable tokens, and which was automatically edited based on your preferences picked during purchase process.',
                            LockedMessageDot1: '5% (500 $MLC in our example) of your $MLC will unlock at listing, immediately claimable',
                            LockedMessageDot2: '3 month cliff (no more tokens received during 3 month)',
                            LockedMessageDot3: 'The rest of your tokens will be linearly unlocked everyday over the vesting period. So if you chose 2 years vesting, you will be able to claim percentage of your 9500$MLC everyday during the 21 months remaining after the initial 3 months cliff.',
                        },
                    },
                    Press: {
                        Title: "My Lovely Planet in the press",
                    },
                    Transactions: {
                        Title: 'Transactions',
                        PageContentDescr: 'Transactions list of private and public sale investements.',
                        Fix: {
                            Title: 'Add transation hash',
                            Message: 'You need to add your transaction hash to ensure your payment will be validated.',
                        },
                        Table: {
                            empty: 'empty',
                            AtTge: 'TGE',
                            ID: 'ID',
                            Tokens: 'Tokens',
                            Vesting: 'Vesting',
                            Paid: 'Paid',
                            Type: 'Type',
                            Status: "Status",
                            Search: 'Type to search',
                            ConfirmingByCrypto: 'Confirming transfer',
                            ConfirmingByEuro: 'Confirming transfer',
                            MissingTransactionHash: 'Missing Tx hash',
                            Fix: 'Add tx hash',
                            validated: "Validated",
                            pending: 'Pending',
                            cancelled: "Cancelled",
                            RejectedByUser: 'Rejected',
                            purchase: 'Purchase',
                            bonus: 'bonus',
                            referral: 'Referral',
                            PurchasePrivate: 'Purchase private sale',
                        }
                    },
                    VestingBonus: {
                        Title: "Vesting bonus",
                        Subtitle: "What is the vesting period ?",
                        Means: "  The vesting period is defined as follows :",
                        FirstLi: "- 5% of your $MLC will unlock at listing (only investors have $MLC at listing)",
                        SecondLi: "- 3 month cliff (no more token received during 3 month)",
                        ThirdLi: "- The rest of the tokens unlocked everyday over the vesting period. (if you choose 2 years, you will receive $MLC everyday during 2 years)",
                        Example: "Example",
                        Example1: "You choose to invest $1000 with a 2 year vesting in round 1 (at a price of $0.061). You will get 18032 tokens (16393 $MLC + 1639 $MLC Bonus).",
                        Example2: "You will receive 5% of your tokens on the day of the listing, i.e. 901.6 tokens. ",
                        Example3: "You will not receive any tokens for the next 3 months, during the blocking period. ",
                        Example4: "Finally you will receive 26.8 tokens per day for 639 days, corresponding to 21 months (the 24 months of vesting - the 3 months of blocking) ",
                        Year1: "1 year",
                        Year2: "1.5 year",
                        Year3: "2 years",
                        Year4: "2.5 years",
                        Year5: "3 years",
                    },
                    StepsToDo: {
                        KYC: {
                            title: 'KYC',
                            description: 'Confirm your identity',
                            action: 'here'
                        },
                        LinkWallet: {
                            title: 'Link wallet',
                            description: 'Connect your wallet to your account',
                            action: 'here',
                        },
                        Claim: {
                            title: 'Claim',
                            description: 'Start to claim your tokens',
                            warn: 'Available in june',
                            help: 'See how to claim?',
                        },
                    },
                },
            },
            FR: {
                translation: {
                    Common: {
                        Nextstep: 'Étape suivante',
                        PrevStep: 'Retour',
                        Processing: 'Processing',
                        Ok: 'Ok',
                        Month: 'Mois',
                        Months: 'Mois',
                        ClickMetamaskInstall: 'Cliquez ici pour installer Metamask',
                        CliffEnd: 'Fin du Cliff / Début de la distribution de token',
                        CliffDetails: '(*) Vous pourrez réclamer vos tokens à partir du 20 septembre, puis chaque jour jusqu\'à la fin du vesting. Le nombre de MLC affiché à côté de chaque mois correspond au total de tokens que vous pourrez réclamer au total sur ce mois.',
                    },
                    BuyMlc: "Buy $MLC",
                    RecoveryAddress: {
                        MsgContent: {
                            ErrorStep1: "Veuillez cocher la case obligatoire",
                            ErrorStep2: "Veuillez cocher la case de non-responsabilité obligatoire",
                            ErrorStep4: "Veuillez cocher toutes les cases obligatoires",
                            ErrorStep5: "La liaison du portefeuille a échoué, veuillez réessayer",
                            CheckVideoWatched: "J'ai regardé la vidéo",
                            ImportantExplanation: "Attention, explication très importante",
                            Disclaimer: "Avis de non-responsabilité",
                            DisclaimerDetails1: "Je comprends qu'après avoir connecté mon portefeuille, je recevrai mes jetons $MLC sur ce portefeuille et uniquement là.",
                            DisclaimerDetails2: "Je comprends que si je perds l'accès à mon portefeuille, l'équipe de My Lovely Planet ne pourra techniquement pas m'envoyer tous mes jetons réclamés $MLC vers un autre portefeuille ni m'aider à récupérer mes $MLC qui sont déjà sur ce portefeuille connecté.",
                            CheckDisclaimer: "J'ai lu l'avertissement ci-dessus et je l'accepte pleinement",
                            EmailVerification: "Vérification de l'identité par e-mail",
                            EmailVerificationExplanationOnly: "Cliquez sur le bouton ci-dessous pour recevoir votre code unique afin de vérifier votre e-mail.",
                            EmailVerificationExplanation: "Cliquez sur le bouton ci-dessous pour recevoir votre code unique afin de vérifier votre e-mail et de procéder à la connexion du portefeuille.",
                            LastDisclaimer: "Dernier avertissement avant la connexion",
                            CheckLastDis1: "Je confirme avoir visionné attentivement la vidéo précédente et lu l'avertissement",
                            CheckLastDis2: "Je reconnais que je ne pourrai plus changer l'adresse de mon portefeuille",
                            CheckLastDis3: "JE RECONNAIS QUE JE PERDRAI TOUS MES $MLC NON RÉCLAMÉS SI JE PERDS L'ACCÈS À MON PORTEFEUILLE",
                            WalletLinking: "Connectez votre portefeuille",
                            WalletLinkingExplanation: "Cette page vous permettra de signer l'adresse ci-dessous pour s'assurer que vous etes le propriétaire.",
                            WalletLinkingExplanation2: "Cliquez d'abord sur Connecter pour connecter votre portefeuille sur le réseau Polygon, puis vous pouvez cliquer sur 'Ajouter une adresse Polygon' pour lier définitivement votre portefeuille à votre compte My Lovely Planet.",
                            WalletLinkingExplanation3: "Si votre portefeuille n'est pas visible et que vous venez d'installer Metamask ou Trustwallet, veuillez rafraîchir la page MyLovelyPlanet.",
                            WalletLinkingInProgress: "Votre portefeuille est sur le point d'être lié",
                        },
                        NextStep: "Étape suivante",
                        WalletAdded: "Connecté",
                        AccountConnected: "Votre compte est maintenant connecté",
                        GoodNetwork: "Vous êtes connecté au bon réseau : réseau Polygon",
                        Signed: "Vous avez validé votre adresse publique",
                        AccountNotConnected: "Votre compte n'est pas connecté",
                        BadNetwork: "Vous n'êtes pas connecté au bon réseau (Veuillez vous connecter au réseau Polygon)",
                        NotSigned: "Vous n'avez pas encore signé le message",
                        ClickAgain: "Cliquez à nouveau sur le bouton ci-dessous pour signer et valider votre adresse",
                        ChooseAnotherNetwork: "Choisissez le réseau Polygon",
                        ConnectYourAccount: "Connectez votre compte (ouvrez Metamask dans vos extensions et saisissez votre mot de passe)",
                        SignYourMessage: "Signez le message dans Metamask",
                        WatchTutorialHere: "Regardez le tutoriel ici",
                        TryAgain: "Cliquez ici pour réessayer",
                        Auth: {
                            Title: "Ajouter une adresse publique",
                            Subtitle: "Pour connecter votre portefeuille, cliquez sur le bouton suivant",
                            Question1: "",
                            Question2: "Vous devez connecter votre portefeuille pour recevoir votre jeton $MLC",
                            CloseButton: "Fermer",
                            InputPlaceholder: "Copiez-collez le code reçu par e-mail",
                            SendEmailButton: "Envoyez-moi mon code par e-mail",
                            SendEmailInProgress: "Envoi de l'e-mail...",
                            ValidateCodeButton: "Valider le code",
                            ValidationInProgress: "Validation du code...",
                        },
                        Step1: {
                            Title: "Ajoutez votre adresse publique",
                            Subtitle: "Etape 1",
                            Question: "Quel type de portefeuille utilisez-vous ?",
                            ConnectWithMetamask: "J'utilise Metamask",
                            ConnectWithOtherWallet: "Je n'utilise pas Metamask / Je n'ai aucun portefeuille Web3",
                        },
                        Step2Metamask: {
                            Title: "Ajoutez votre adresse publique",
                            Subtitle: "Etape 2",
                            Question: "Cliquez sur bouton ci-dessous pour vous connecter à votre portefeuille",
                            ConnectWithMetamask: "Metamask",
                            Error: "Vous n'êtes pas connecté à la blockchain Polygon (Matic Network)",
                            MessageToSign: "Confirmez votre adresse",
                            MetamaskNotInstalled: "Metamask  n'est pas installé. Suivez les instructions ci-dessous pour l'installer et raffraichissez la page",
                        },
                        Step2OtherWallet: {
                            Title: "Ajoutez votre adresse publique",
                            Subtitle: "Etape 2",
                            Question: "Créez votre premier portefeuille Metamask",
                            IHaveSetupMetamask: "J'utilise déjà Metamask",
                            IKnowWherePublicAddressIsLocated: "J'ai compris où se trouve mon adresse publique",
                            MetamaskIsInMyFavroitesExtensions: "Metamask est dans mes extensions favorites",
                            IHaveAddedPolygonNetwork: "J'ai ajouté le réseau Polygon à Metamask",
                            Cancel: "Annuler",
                            Start: "Commencer",
                        },
                        Step3Metamask: {
                            Title: "Bravo",
                            Subtitle: "Votre adresse est parfaitement reliée à votre compte",
                        }
                    },
                    General: {
                        Edit: "Modifier",
                        SelectLanguage: "Choisissez la langue",
                        SelectCountry: "Choisissez votre pays",
                        Claim: "Récupérer",
                        ClaimInProgress: "Récupération en cours..",
                        PurchasedToken: "Tokens achetés",
                        ReferralToken: "Tokens de parrainage",
                        BonusToken: "Token bonus",
                        Days: 'Jours',
                        Hours: 'Heures',
                        Min: 'Min',
                        Sec: 'Sec',
                        SelectYourLanguage: "Choisissez votre langue",
                        AreYouInvited: "Code de parrainage (OPTIONNEL)",
                        DaysLeft: "Il vous reste ",
                        DaysLeftAfter: " pour soumettre vos documents d'identité",
                        Years: ' ans',
                        Year: ' an',
                        Vesting: 'Vesting',
                        YourProfile: "Votre profil",
                        Logout: "Déconnexion",
                        YouAreInvitedBy: "Vous êtes invité par ",
                        YouWillReceiveMoreTokens: "Vous recevrez 10% de tokens supplémentaires sur votre première transaction",
                        Save: "Sauvegarder",
                    },
                    CurrentRound: {
                        Current: "Prix du round",
                        Title: "Tour d'investissement actuel : 50% du prix de lancement",
                        LaunchPrice: "Prix de lancement",
                        Mlcremaining: "Token de la presale restant",
                        End: "Fin du tour d'investissement et augmentation du prix dans",
                        Start: 'Le tour d\'investissement démarre dans',
                    },
                    Header: {
                        Dashboard: "Tableau de bord",
                        AirDrops: "Airdrops",
                        Buy: "Acheter",
                        ICO: "Distribution private et public sale",
                        YourProfile: "Votre profil",
                        Logout: "Déconnexion",
                        Transactions: "Transactions",
                        MyToken: "Mes tokens MLC",
                        MainWebSite: "Site principal",
                        Referral: "Parrainage",
                        TypeHere: "Saisissez ici...",
                        SuperAdmin: "Super Admin",
                        Contact: "Contact",
                        ReferralEvent: "Evénement Parrainage"
                    },
                    AirDrops : {
                        Title: 'Airdrops',
                    },
                    ReferralEvent: {
                        TitleRE: "Evénement Parrainage",
                        EnormousSecretPrice: "Enorme bonus secret",
                        Sponsorship: "Parrainage(s)",
                        Title1: "MAGIC CHANCE CHALLENGE - PRIZE POOL de ",
                        PricePool: "1000 $USDT",
                        ToWin: "à gagner !",
                        ReadMore: "En savoir +",
                        AlertDescEvent: "Découvrez les règles de l'événement",
                        NumberOfTickets: "Ton nombre de Magic Ticket",
                        NumberOfTicketsTotal: "MAGIC TICKET TOTAL EN JEU ",
                        DrawDate: "Date de fin de l'événement",
                        Title2: "Tes statistiques sur l'événement",
                        NbReferrals: "Nombre de filleuls",
                        CurrentRank: "Rang Actuel",
                        WinTokens: "Tokens gagnés",
                        NextRank: "Grade suivant dans",
                        Title3: "Tes récompenses actuelles en tant que ",
                        USDT: "USDT",
                        MLCtokens: "$MLC Tokens",
                        VIPinvitation: "VIP Invitation",
                        MagicTicket: "Magic Ticket",
                        SecretNFT: "NFT secret exclusif",
                        EventRules: "Règles de l'événement",
                        SlideOver: {
                            A: "A - Chaque parrainage te fait monter en grade",
                            B: "B - Un parrainage est considéré comme valide si le filleul a investi pour la première fois.",
                            C: "C - Le filleul devra quoi qu’il arrive procéder à son KYC pour obtenir ses tokens et ses récompenses.",
                            D: "D - Pour chaque nouveau filleul, le parrain monte en grade sur l’échelle des « Lovely Medals ».",
                            E: "E - Le filleul reçoit 10% bonus sur son premier investissement.",
                            F: "F - Chaque nouveau grade atteint donne droit à des récompenses spécifiques indiqué dans le « Tableau des récompenses ».",
                            G: "G - Le montant de tokens gagnés sur chaque grade est calculé sur une base 1 $MLC = 0.1$ (prix du listing).",
                            H: "H - Les USDT et les tokens $MLC gagnés sur chaque grade seront envoyés sur l’adresse publique liée au compte du parrain.",
                            I: "I - Un seul gagnant sera tiré au sort pour le challenge « Magic Chance Challenge ».",
                            J: "J - Le gain lié au “Magic Chance Challenge” sera distribué en USDT directement sur l’adresse publique du gagnant",
                            K: "K - Distribution des récompenses le 1er octobre 2023.",
                        }

                    },
                    Referral: {
                        Title: "Parrainage",
                        AlertDesc: "Découvrez notre programme de parrainage",
                        AlertDescrVesting: "Découvrez notre programme de vesting",
                        ReadMore: "En savoir +",
                        ShareOnLinkedin: "Partager sur Linkedin",
                        ShareOnTwitter: "Partager sur Twitter",
                        ReferralList: "Liste",
                        BonusDetails: "Vos détails des bonus de parrainage",
                        BonusReferral: "% bonus",
                        NbReferral: "Nombre de parrainage",
                        BonusRegister: "Bonus d'inscription",
                        BonusTotal: "Bonus total",
                        YouHaveBeenInvitedBy: "Vous avez été invité par ",
                        YouWillReceive: "Vous revevrez 10% de bonus supplémentaires sur votre premier achat",
                        YouReceived: "Vous avez bien reçu votre bonus sur votre 1è transaction. Le parrainage ne fonctionne que sur la première transaction",
                        YouHaveNotBeenInvited: "Le parrainage ne fonctionne que sur la première transaction. Vous n'êtes pas parrainé, et vous avez déjà fait votre 1è transaction, vous ne pourrez pas vous faire parrainer. Mais vous pouvez parrainer d'autres utilisateurs avec votre code disponible dans cet espace",
                        EnterYourCode: "Entrez votre code de parrainage. Uniquement valable pour votre première transaction",
                        Error: "Vous ne pouvez pas mettre votre propre code de parrainage",
                        Table: {
                            Name: "Nom",
                            EarnedToken: "Token gagnés",
                            RegisterDate: "Date",
                            Status: "Statut",
                            NbTransactions: "Nb transactions"
                        },
                        SlideOver: {
                            Title: "Programme de parrainage",
                            Descr: "Pour chaque nouveau filleul, vous recevez un pourcentage de leur première transaction sous forme de tokens bonus. Le filleul gagne également 10% bonus grâce à votre code de parrainage. Le bonus est calculé à partir des transactions confirmées des utilisateurs vérifiés.",
                            BaseLine: "Plus de parrainages = plus de bonus",
                            Referrals: "Pour chaque parrainage",
                            moreThan100: "100 et +"
                        }
                    },
                    Dashboard: {
                        Title: 'Tableau de bord',
                        TokenAddr: 'Adresse du token',
                        TokensBalance: 'Solde de Tokens',
                        PurchasedTokens: 'Tokens achetés',
                        BuyTokenForAll: 'Obtenir des Tokens',
                        Equivalent: 'Equivalent à',
                        TokenName: "Nom du jeton",
                        TicketSymbol: 'Symbole du jeton',
                        WhitePaper: "Livre blanc",
                        Tutorial: 'FAQ',
                        TokenCalculation: "Conversion de Tokens",
                        TokenCalculationDesc: "Entrez le montant pour calculer le jeton.",
                        TokenCalculationDesc2: "* Montant calculé sur la base du prix actuel du jeton",
                        BuyTokens: "$MLC dans le wallet",
                        StepsToDo: 'Etapes à faire',
                        Feeds: {
                            Title: "Actualités",
                        }
                    },
                    VersionUpdate: {
                        Message: 'Une nouvelle version du site est disponible, veuillez recharger la page.',
                    },
                    SessionExpired: {
                        Message: 'Votre session a expirée, veuillez recharger la page.',
                        Reload: 'Recharger',
                    },
                    AddWalletTuto : {
                        Title: 'Comment créer un wallet',
                        MsgContent: {
                            ImportantExplanation: 'Regardez cette vidéo et cliquez sur OK lorsque vous êtes prêt à continuer le processus d\'ajout de portefeuille.'
                        },
                    },
                    Wallet: {
                        NoAdressConnected: "PAS D'ADRESSE CONNECTÉE",
                        Unverified: "WALLET NON VÉRIFIÉ",
                        Wrong: "MAUVAIS WALLET",
                        Disco: "DÉCONNECTÉ",
                        Net: "MAUVAIS RÉSEAU",
                    },
                    Profile: {
                        Title: "Détails de votre profil",
                        Updated: "Profil mis à jour",
                        NotUpdated: "Il y a eu une erreur, contactez le support",
                        Remember: "N'oubliez pas d'ajouter votre adresse de portefeuille avant le 30 déc 2023",
                        PersonalData: "Données personnelles",
                        Settings: "Paramètres",
                        Name: "Identité",
                        Phone: "Téléphone",
                        UpdateProfile: "Mettre à jour le profil",
                        UpdatedProfile: "Votre profil a bien été mis à jour",
                        EnableNotifications: "Activer les notifications",
                        SaveLogs: "Enregistrer l'activité du compte",
                        EnableNewsletter: "S'abonner à la newsletter",
                        SendEmailVerification: "L'email de vérification a bien été envoyé. Vérifiez vos spams également",
                        EmailSent: "L'email de vérification a été envoyé. Vérifiez vos spams également",
                        Reload: 'Rechargez la page après avoir cliqué sur le lien dans l\'email reçu',
                        PasswordDoesNotMatch: 'Les mots de passe ne concordent pas',
                        PasswordTooShort: 'Le mot de passe doit contenir au moins 6 caractères',
                        PasswordReset: 'Le mot de passe a bien été réinitialisé',
                        PasswordNotReset: 'Une erreur s\'est produite lors de la réinitalisation du mot de passe',
                        NoWallet: "Je n'ai pas de wallet ou je suis débutant",
                        IHaveAWallet: "J'ai un Wallet",
                        Switch: "Changer de Wallet",
                        WalletStatusConfirm: "Confirmer cette adresse",
                        EmailNotSent: "Erreur d'envoi d'email, veuillez re-essayer plus tard",
                        IdontSeeMyWallet: "Vous ne voyez pas votre wallet ? Refresh",
                        Password: {
                            General: "Mot de passe",
                            Old: "Mot de passe actuel",
                            New: "Nouveau mot de passe",
                            Confirm: "Confirmation nouveau mot de passe",
                            Update: "Mettre à jour le mot de passe",
                            Send: "Envoyer un email de réinitialisation de mot de passe",
                            Sent: "Vérifiez votre boite email (y compris vos spams)"
                        },
                        Email: {
                            SaveNewPassword: "Enregistrer le nouveau mot de passe",
                            ResetPassword: "Réinitialiser le mot de passe",
                            Error: "Si votre email existe, vérifier vos mails pour réinitialiser votre mot de passe",
                            SendResetLink: "Envoyer le lien de réinitialisation",
                            GoBackToLogin: "Retourner à la page de login",
                            ForgottenPassword: "Mot de passe oublié"
                        },
                        AccountStatusTitle: "Statut de votre compte",
                        EmailVerified: "Email vérifié",
                        EmailSendCode: "Envoyer le code par email",
                        EmailResendCode: "Renvoyer le code",
                        EmailResendCodeIn: "Renvoyer le code dans ",
                        EmailVerify: 'Enregistrer le code',
                        VerifyEmail: "Vérifier l'email",
                        EmailNotVerified: "Email non verifié.",
                        KYCPending: "KYC en attente",
                        RefusedDocument: "Document refusé",
                        WalletConnected: "Portefeuille actuellement connecté",
                        WalletConnectedToProfile: "Adresse enregistrée pour claim",
                        Wallet: "Portefeuille",
                        WalletStatusAddPolygonAddress: "Connecter un wallet",
                        WalletStatusConnected: "Wallet connecté",
                        WalletStatusToConnect: "Connecter le Wallet",
                        WalletStatusChangeNetwork: "Utiliser un réseau Polygon",
                        WalletStatusWrongWallet: "Mauvais Wallet - Changer de Wallet",
                        WalletAddByWalletConnect: "Connectez vous avec ",
                        SupportedWallets: "Les wallets recommandés sont Metamask et TrustWallet.",
                        PayWith: "Payez avec ",
                        PayByOtherWallet: "Continuer",
                        Continue: "Continuer",
                        Referral: {
                            Title: "Gagner de l'argent grâce",
                            Title2: "au parrainage",
                            Desc: "Invitez vos amis et votre famille et recevez un bonus.",
                            Copied: "Lien copié",
                        },
                        WalletConnectedInfo: {
                            Desc: "Pour obtenir vos $MLC, vous devez ajouter votre addresse Polygon en connectant votre wallet.",
                        },
                        KYC: {
                            Title: "Vérification de votre identité - KYC",
                            Desc: "Pour obtenir vos $MLC, vous devez réaliser un KYC - Know Your Customer / Vérification d'identité.",
                            Desc2: "Vous n'avez pas soumis votre demande KYC pour vérifier votre identité.",
                            Proceed: "Vérifier mon identité",
                            Status: {
                                Title: "Statut",
                                NotSub: 'Non commencé'
                            },
                            Submitted: "Vous avez soumis votre identité. Elle est en-cours de vérification par nos équipes.",
                            Rejected: "Certains documents de vérifications ont été rejetés par notre équipe. Merci de réessayer",
                            Validated: "Votre identité a été vérifiée avec succès",
                        },
                        Quest: {
                            Link: 'Récupérer Airdrop',
                            MessageAlert: 'Pour la quest d\'airdrop (QUEST ou TRUSTWALLET), pas de KYC nécessaire, afin de réclamer votre jeton, suivez les instructions sur le bouton suivant '
                        },
                    },
                    MyToken: {
                        Title: "Mes Tokens MLC",
                        BuyMore: "Acheter des MLC",
                        Total: "Montant total",
                        PurchaseToken: "Tokens acquis",
                        ReferralToken: "Tokens de parrainage",
                        BonusesToken: "Tokens bonus",
                        TotalContributed: "Contribution totale"
                    },
                    Help: {
                        Help: 'Aide',
                        HowToClaimInvest: 'Comment réclamer vos tokens issus d\'un investissement',
                        HowToClaimInvestQuest: 'Comment réclamer vos tokens provenant des quêtes, de Trust Wallet et des airdrops.',
                        HowToBuySell: 'Comment acheter ou vendre des $MLC',
                        HowToSeeToken: 'Voir vos $MLC dans MetaMask',
                    },
                    Balance: {
                        TokensLockedVesting: "Tokens restants bloqués en vesting",
                        TokensBoughtPresale: "Total de tokens achetés en prévente",
                        DistributionCalendar: "Voir le calendrier de distribution",
                        DistributionCalendarModal: {
                            Title: 'Calendrier de distribution',
                            Month: 'Mois',
                            MLC_Released: '$MLC Released',
                            ClaimAvailableMessage: 'La récupération des tokens sera disponible le 25 Juin',
                        },
                        BuyDisabledModal: {
                            Title: 'Acheter',
                            BuyAvailableMessageDisabledPopup: 'L\'achat de tokens sera disponible au TGE le 25 Juin à 15h UTC',
                        },
                        ClaimModal: {
                            Title: 'Claim',
                            ClaimAvailableMessage: 'La récupération des tokens sera disponible le 25 Juin',
                            ClaimAvailableMessageDisabledPopup: 'La récupération des tokens sera disponible au TGE le 25 Juin à 15h UTC',
                            BuyAvailableMessage: 'L\'achat des tokens sera disponible le 25 Juin',
                            ClaimAvailableMessageNoKYC: 'La récupération des tokens n\'est pas disponible car votre KYC n\'est pas terminé.',
                            ClaimAvailableMessageDefault: 'La récupération des tokens n\'est pas disponible, veuillez reessayer dans un moment.',
                            BuyAvailableMessageDefault: 'L\'achat des tokens n\'est pas disponible, veuillez reessayer dans un moment.',
                        },
                    },
                    QuestCountDown: {
                        Title: 'Prochain airdrop',
                        MsgAlert: 'pour devenir éligible, passer ce délai le airdrop sera fermé.',
                    },
                    QuestEligibility: {
                        Claim: 'Comment récupérer ?',
                        AirdropTokens: 'Airdrop quest classique',
                        AirdropTokensTw: 'Airdrop Trust Wallet',
                        CheckStatus: 'Vérifier l\'éligibilité',
                        Msg1: 'Vérifiez votre éligibilité pour voir si vous avez reçu des tokens $MLC des Airdrops.',
                        Msg2: 'Vous n\'êtes pas éligible car votre email n\'a pas été trouvé dans la quête.',
                        Msg3: 'Airdrop" terminé, il n\'est plus possible de réclamer des tokens, même si vous mettez à jour votre statut maintenant.',
                        Msg4: 'Comment corriger ce statut',
                        Msg5: 'Veuillez lire attentivement la partie concernant l\'email pour la quête :',
                        Msg6: '👉 Comment devenir éligible pour l\'airdrop',
                        Msg7: 'Une fois que vous avez bien suivi les étapes décrites dans le lien, vous pouvez cliquer à nouveau sur le bouton "Vérifier l\'éligibilité" en haut de cette page.',
                        Msg8: 'Vous n\'avez aucun token à réclamer pour cette quête.',
                        Msg9: 'Le temps pour devenir éligible à l\'airdrop est clos ; il n\'est désormais plus possible de réclamer des tokens même si vous corrigez votre statut maintenant.',
                        Msg10: 'Veuillez suivre les étapes et lire attentivement la partie concernant la vérification de l\'e-mail :',
                        Msg11: '👉 Comment devenir éligible à l\'airdrop en vérifiant mon email',
                        Msg12: '👉 Vous pouvez également cliquer ici pour vérifier votre email',
                        Msg13: 'Votre compte a été banni',
                        Msg14: 'Nous avons banni les comptes qui ont invité des bots.',
                        Msg14bis: 'Si vous pensez que votre code d\'invitation n\'a pas été utilisé par des bots, veuillez nous contacter à',
                        Msg15: 'avec une capture d\'écran prouvant que vous avez invité de vraies personnes.',
                        Msg16: 'Vous êtes éligible avec',
                        Msg17: '$MLC à récupérer',
                        Msg18: 'Quoi faire ensuite',
                        Msg19: 'Votre airdrop est validé, vous n\'avez rien à faire nous vous enverrons votre token $MLC après le cliff conformément à votre calendrier de distribution disponible ici :',
                        Msg20: '📆 Ouvrir mon calendrier de distribution',
                        Msg21: 'Ceci est le wallet qui recevra l\'airdrop :',
                        Msg22: 'Attention, votre airdrop du TGE est validé dans un smartcontract Sablier, veillez à réclamer vous-même votre montant TGE avec les liens suivants :',
                        Msg23: '👉 Comment réclamer mon airdrop (uniquement TGE) avec Sablier',
                        Msg24: '👉 Cliquez ici pour ouvrir l\'application Sablier',
                        Msg25: 'Veuillez noter que votre airdrop est validé mais celui-ci a eu lieu après le snapshot du TGE, nous allons donc airdrop votre TGE de 5 % avec le premier mois de airdrop du token, plus de détails sur l\'airdrop ici :',
                        Msg26: '👉 Comment mon jeton airdrop sera-t-il envoyé ?',
                        Msg27: 'Si vous n\'avez pas participé à l\'"AIRDROP',
                        Msg27bis: '" veuillez ignorer tout ce qui suit.',
                        Msg28: '❔ Comment savoir si j\'ai participé à cet airdrop ?',

                    },
                    Buy: {
                        Title: "Acheter des My Lovely Coin - $MLC ",
                        MaxLimit: "Vous avez déjà atteint la limite maximale d'achat de jetons pour cette vente.",
                        Password: "Mettez votre mot de passe à jour",
                        Buy: 'Acheter des $MLC',
                        AirdropMark: 'Récupérer Airdrop',
                        HowToSeeMyToken: 'Voir mes tokens',
                        HowToSeeMyTokenMsg1: 'Pour voir vos tokens directement sur votre wallet (Metamask ou autre wallet compatible Polygon), vous allez devoir ajouter le token à la liste des tokens visibles depuis votre wallet (par défaut, seuls les blockchains principales comme Ethereum, Polygon etc... sont affichées sur les wallet).',
                        HowToSeeMyTokenMsg2: 'Suivez les étapes ci dessous pour ajouter votre token sur votre wallet:',
                        HowToSeeMyTokenMsgLink: 'Etapes',
                        Join: 'Rejoignez le plus grand jeu pour la protection du climat',
                        Secure: "Paiement sécurisé grâce à ",
                        With: "Paiement par carte proposé par",
                        AlreadyCollected: "Déjà collectés",
                        usersRegistered: 'Nb enregistrés',
                        PleaseBeforeStarting: "Avant de commencer",
                        ForbiddenCountries: "Vous ne pouvez pas acheter de $MLC",
                        IsMajor: "Vous avez 18 ans ou plus",
                        SelectYourCountry: "Sélectionnez votre pays de résidence",
                        YouAccept: "Vous acceptez",
                        TAC: "Les conditions générales de vente",
                        KYC: "Je comprends que je devrai vérifier mon identité pour recevoir mes My Lovely Coin.",
                        Start: "Commencer",
                        Cancel: "Annuler",
                        OK: "Ok",
                        PasswordEmailSent: "Un compte associé à cet email est déja existant, tu as surement déja joué au Jeu MyLovelyPlanet. Pour des raisons de sécurité clique ici pour réinitialiser ton mot de passe et accéder au site (cela modifiera aussi ton mot de passe du jeu)",
                        Step1: "Etape 1 - Moyen de paiement",
                        Step2: "Etape 2 - Sélectionner le montant",
                        Payment: "Montant",
                        YourAmount: "Votre montant",
                        WrongBalance: "Minimum 200 €.",
                        MaxCryptoBalance: "et Max : ",
                        ChooseVestingBonus: "Choisissez votre bonus",
                        LearnMore: "En savoir +",
                        YouReceive: "Vous recevez",
                        Purchased: "achetés",
                        Bonus: "$MLC bonus",
                        Referral: "$MLC parrainage",
                        BuyNow: "Acheter maintenant",
                        TransferAmount: "Montant du virement",
                        PaymentAmount: "Montant du paiement",
                        TransferIndications: "Indications de virement",
                        OtherWalletIndications: "Envoyez vos cryptos à l'adresse suivante",
                        TransferIndicationsDetails: "Indiquer la référence suivante dans votre virement",
                        YourPublicWalletAddress: "Saisissez la clé publique de votre wallet",
                        AlreadyUsed: "Cette addresse est déjà utilisée sur un autre compte. Merci de vérifier votre saisie",
                        Iban: "IBAN",
                        Bic: "BIC",
                        TransferConfirmation: "Je confirme avoir réalisé le virement",
                        EtherscanFeedback: "Suivez votre transaction ici",
                        TY: "Merci",
                        ShowTransaction: "Vous pouvez voir l'état de votre transaction dans la section des transactions. Il faut généralement compter 3 à 5 jours pour recevoir et confirmer votre virement bancaire et environ 20 minutes pour confirmer votre paiement crypto.",
                        ShowTransactionByCard: "Vous pouvez voir votre transaction dans l'onglet \"Transactions\".",
                        ViewTransaction: "Voir la transaction",
                        ChoosePaymentMethod: "Choisissez votre moyen de paiement",
                        ProcessCrypto: "Ne quittez pas la page durant le processus",
                        ShowCryptoTransaction: "Voir la transaction ici",
                        CryptoRejected: "La transaction a été annulée ou rejetée",
                        InsufficientBalance: "Fonds insuffisant",
                        CoinGeckoError: "CoinGecko ne répond pas. Réessayez dans quelques instants",
                        YourCountryIsNotAuthorized: "Votre pays n'est pas dans la liste autorisée",
                        AcceptConditions: "Veuillez accepter les conditions",
                        FalseAmount: "Le montant doit être compris entre 200 et 5000 €",
                        CoingeckoError: "L'Api CoinGecko n'est pas opérationnelle actuellement. Choisissez une autre méthode de paiement ou attendez quelques minutes avant de réessayer. Cliquez ici pour vérifier le statut de CoinGecko",
                        VerifyEmail: "Vérifiez bien votre clé publique saisie, cette clé servira à authentifier la réception de votre paiement",
                        TransactionHash: "Saisir l'identifiant de transaction",
                        Warning: "ATTENTION : SANS TRANSACTION HASH, LA VERIFFICATION DE VOTRE PAIEMENT SERA AFFECTEE ET POURRA PRENDRE PLUSIEURS JOURS...",
                        ValidateWarning: "Je confirme l'ID de la transaction",
                        SavedWithSuccess: "Sauvegardé avec succès",
                        Error: "Non sauvegardé",
                        WarningCrypto: "Vous devez envoyer vos cryptos sur les réseaux ETH Mainnet, BNB Chain Mainnet or Polygon Mainnet.",
                        SaleIsOver: "La prévente est terminée. Rejoignez Discord pour soutenir le projet.",
                        BuyDisableMessage: "Les fonctionnalités du wallet sont désactivés en mobile et tablette",
                        BuyModal: {
                            Title: 'Acheter',
                            UniswapMsg: 'Uniswap sera disponible 15 à 30 minutes après le lancement sur la plateforme d\'échange centralisée (CEX) afin de nous laisser le temps de verrouiller la liquidité et de créer la pool officiel.',
                            BuyAvailableMessage: 'Achat non disponible pour le moment',
                        },
                        LockedTokenModal: {
                            Title: 'Tokens restants bloqués en vesting',
                            LockedMessage1: 'Vos jetons MLC seront distribués selon votre calendrier basé sur les options de vesting choisies. Cela signifie que vous recevrez un pourcentage de vos $MLC totaux lors du listing et que le reste sera débloqué quotidiennement en fonction de votre calendrier de distribution (ils deviennent « réclamables / Claimables »).',
                            LockedMessage2: 'Votre calendrier de distribution dépend de l\'option de vesting que vous avez choisie lors de votre achat de $MLC. Votre calendrier de distribution est disponible à tout moment en cliquant sur le bouton associé dans votre Tableau de Bord et le nombre de tokens (en pourcentage) dont vous disposerez au listing est détaillé dans le tableau « Transactions » de la colonne « TGE ».',
                            LockedMessage3: 'Pour comprendre précisément comment cela va se débloquer, voici un exemple pour un achat de 10000 $MLC tokens à 2 ans de vesting :',
                            LockedMessage4: 'Nota 1 : vous n\'êtes pas obligé de venir réclamer vos jetons tous les jours ; vous pouvez venir quand vous le souhaitez et réclamer tous les jetons actuellement disponibles.',
                            LockedMessage5: 'Nota 2 : votre période de vesting ne peut être modifiée ; il est codé en dur dans le smart contrat qui gère vos jetons et qui a été automatiquement créé en fonction de vos préférences choisies lors du processus d\'achat.',
                            LockedMessageDot1: '5 % (500 $MLC dans notre exemple) de vos $MLC seront débloqués lors de l\'annonce, immédiatement récupérables',
                            LockedMessageDot2: 'Cliff de 3 mois (plus de tokens reçus pendant 3 mois)',
                            LockedMessageDot3: 'Le reste de vos jetons sera débloqué de manière linéaire chaque jour pendant la période d\'acquisition. Ainsi, si vous avez choisi le vesting sur 2 ans, vous pourrez réclamer un pourcentage de vos 9 500 $ MLC quotidiennement pendant les 21 mois restant après le cliff initial de 3 mois.',
                        },
                    },
                    Press: {
                        Title: "My Lovely Planet dans la presse",
                    },
                    Transactions: {
                        Title: 'Transactions',
                        PageContentDescr: 'Liste des transactions effectuées lors de vos investissements de private et public sale.',
                        Table: {
                            empty: 'vide',
                            AtTge: 'TGE',
                            ID: 'ID',
                            Tokens: 'Tokens',
                            Vesting: 'Vesting',
                            Paid: 'Payé',
                            Type: 'Type',
                            Status: "Statut",
                            Search: 'Rechercher',
                            ConfirmingByCrypto: 'En attente de réception',
                            ConfirmingByEuro: 'En attente de réception',
                            MissingTransactionHash: 'Tx hash manquant',
                            Fix: 'Ajout du hash de tx',
                            validated: "Validé",
                            pending: 'En attente de confirmation',
                            cancelled: "Annulée",
                            RejectedByUser: 'Rejetée',
                            purchase: 'achat',
                            bonus: 'bonus',
                            referral: 'parrainage',
                            PurchasePrivate: 'Achat private sale',
                        }
                    },
                    VestingBonus: {
                        Title: "Bonus de vesting",
                        Subtitle: "Qu'est-ce que la période de vesting ?",
                        Means: " La période de vesting est définie comme suit :",
                        FirstLi: "- 5% de vos $MLC seront débloqués au listing (seuls les investisseurs qui ont des tokens avant le listing)",
                        SecondLi: "- 3 mois de blocage (Pas de réception de token durant 3 mois)",
                        ThirdLi: "- Le reste des token est débloqué chaque jour durant la période de vesting. (Si vous choisissez 2 ans, vous recevrez des $MLC chaque jour durant 2 ans)",
                        Example: "Exemple",
                        Example1: "Vous choisissez d'investir 1000€ avec un vesting de 2 ans au round 1 (au prix de 0.061€). Vous obtiendrez 18032 tokens (16393 $MLC + 1639 $MLC Bonus).",
                        Example2: "Vous recevrez le jour du listing 5% de vos tokens soit : 901,6 tokens.",
                        Example3: "Vous ne recevrez pas de tokens les 3 mois suivants, durant la période de blocage.",
                        Example4: "Enfin, vous toucherez 26,8 tokens par jour pendant 639 jours, correspondant à 21 mois (les 24 mois de vesting - les 3 mois de blocage)",
                        Year1: "1 an",
                        Year2: "1.5 an",
                        Year3: "2 ans",
                        Year4: "2.5 ans",
                        Year5: "3 ans",
                    },
                    StepsToDo: {
                        KYC: {
                            title: 'KYC',
                            description: 'Confirme ton identité',
                            action: 'ici'
                        },
                        LinkWallet: {
                            title: 'Connecte ton wallet',
                            description: 'Connecte ton wallet à ton compte',
                            action: 'ici',
                        },
                        Claim: {
                            title: 'Récupère',
                            description: 'Commence à récupérer tes tokens',
                            warn: 'Disponible en juin',
                            help: 'Voir comment claim?',
                        },
                    },
                },
            },
            DE: {
                translation: {
                    General: {
                        Edit: "Bearbeiten",
                        SelectLanguage: "Wähle die Sprache",
                        SelectCountry: "Wähle dein Land",
                        Claim: "Beanspruchen",
                        PurchasedToken: "Gekaufte Tokens",
                        ReferralToken: "Empfehlungs-Token",
                        BonusToken: "Bonus-Token",
                        Days: 'Tage',
                        Hours: 'Stunden',
                        Min: 'Min',
                        Sec: 'Sek',
                        SelectYourLanguage: "Wähle deine Sprache",
                        DaysLeft: "Du hast noch ",
                        DaysLeftAfter: " übrig, um deine Identifikationsdokumente einzureichen",
                        Years: ' Jahre',
                        Year: ' Jahr',
                        Vesting: 'Vesting',
                        YourProfile: "Dein Profil",
                        Logout: "Abmelden",
                    },
                    CurrentRound: {
                        Current: "Aktuelle Runde",
                        Title: "Aktuelle Runde: 50% des Einführungspreises",
                        LaunchPrice: "Einführungspreis",
                        Mlcremaining: "Remaining Token",
                        End: "Ende der Runde vor Preiserhöhung",
                        Start: 'Time before presale starts',
                    },
                    Header: {
                        Dashboard: "Dashboard",
                        Buy: "Kaufen",
                        ICO: "ICO-Verteilung",
                        YourProfile: "Dein Profil",
                        Logout: "Abmelden",
                        Transactions: "Transaktionen",
                        MyToken: "Meine MLC-Token",
                        MainWebSite: "Hauptwebsite",
                        Referral: "Empfehlung",
                        SuperAdmin: "Super Admin",
                        Contact: "Kontakt",

                    },
                    Referral: {
                        Title: "Empfehlungsprogramm",
                        AlertDesc: "Entdecken Sie unser Empfehlungsprogramm",
                        ReadMore: "Mehr erfahren",
                        ShareOnLinkedin: "Auf Linkedin teilen",
                        ShareOnTwitter: "Auf Twitter teilen",
                        ReferralList: "Empfehlungsliste",
                        BonusDetails: "Details zu Ihren Empfehlungsboni",
                        BonusReferral: "% Bonus",
                        NbReferral: "Anzahl der Empfehlungen",
                        BonusRegister: "Registrierungsbonus",
                        BonusTotal: "Gesamtbonus",
                        YouHaveBeenInvitedBy: "Sie wurden eingeladen von",
                        YouWillReceive: "Sie erhalten einen zusätzlichen Bonus von 10% bei Ihrem ersten Kauf",
                        Table: {
                            Name: "Name",
                            EarnedToken: "Erhaltene Tokens",
                            RegisterDate: "Registrierungsdatum",
                            Status: "Status",
                            NbTransactions: "Anzahl der Transaktionen",
                        },
                        SlideOver: {
                            Title: "Empfehlungsprogramm",
                            Descr: "Für jeden von Ihnen geworbenen Investor erhalten Sie einen Prozentsatz ihrer ersten Transaktion in Form von Bonus-Tokens. Der Bonus wird auf Basis der bestätigten Transaktionen der verifizierten Benutzer berechnet.",
                            BaseLine: "Mehr Empfehlungen = mehr Bonus",
                            Referrals: "Für jede Empfehlung",
                            moreThan100: "100 und mehr",
                        }
                    },
                    Dashboard: {
                        Title: 'Tableau de bord',
                        TokensBalance: 'Token-Guthaben',
                        Equivalent: 'Äquivalent zu',
                        TokenName: "Token-Name",
                        TicketSymbol: 'Token-Symbol',
                        WhitePaper: "Whitepaper",
                        TokenCalculation: "Token-Berechnung",
                        TokenCalculationDesc: "Gib den Betrag ein, um den Token zu berechnen.",
                        TokenCalculationDesc2: "* Betrag basierend auf dem aktuellen Token-Preis berechnet",
                        BuyTokens: "Tokens kaufen",
                        StepsToDo: 'Steps To Do',
                        Feeds: {
                            Title: "Nachrichten",
                        }
                    },
                    VersionUpdate: {
                        Message: 'A new version is available, please reload the page.',
                    },
                    SessionExpired: {
                        Message: 'Your session has expired, please reload the page.',
                        Reload: 'Reload',
                    },
                    Profile: {
                        Title: "Détails de votre profil",
                        Updated: "Profil aktualisiert",
                        NotUpdated: "Es ist ein Fehler aufgetreten. Bitte kontaktiere den Support.",
                        Remember: "Denke daran, deine Wallet-Adresse vor dem 30. Dezember 2023 hinzuzufügen.",
                        PersonalData: "Persönliche Daten",
                        Settings: "Einstellungen",
                        Name: "Name",
                        Phone: "Telefon",
                        UpdateProfile: "Profil aktualisieren",
                        UpdatedProfile: "Dein Profil wurde erfolgreich aktualisiert.",
                        EnableNotifications: "Benachrichtigungen aktivieren",
                        SaveLogs: "Aktivitätsprotokoll speichern",
                        EnableNewsletter: "Newsletter abonnieren",
                        SendEmailVerification: "Die E-Mail-Verifizierung wurde erfolgreich versendet. Überprüfe auch deinen Spam-Ordner.",
                        Reload: "Bitte aktualisiere die Seite nach dem Klicken des Links in der E-Mail.",
                        Password: {
                            General: "Passwort",
                            Old: "Aktuelles Passwort",
                            New: "Neues Passwort",
                            Confirm: "Neues Passwort bestätigen",
                            Update: "Passwort aktualisieren",
                            Send: "Passwort-Wiederherstellungs-E-Mail senden",
                            Sent: "Überprüfe dein E-Mail-Postfach (einschließlich Spam-Ordner)"
                        },
                        AccountStatusTitle: "Status deinen Kontos",
                        EmailVerified: "E-Mail verifiziert",
                        EmailNotVerified: "E-Mail nicht verifiziert.",
                        KYCPending: "KYC ausstehend",
                        RefusedDocument: "Dokument abgelehnt",
                        Wallet: "Wallet",
                        WalletStatusAddPolygonAddress: "Connect a Wallet",
                        WalletStatusConnected: "Wallet Connected",
                        WalletStatusToConnect: "Connect Wallet",
                        WalletStatusChangeNetwork: "Change network to Polygon",
                        WalletStatusWrongWallet: "Wrong Wallet - Switch Wallet",
                        WalletAddByWalletConnect: "Mit WalletConnect verbinden",
                        SupportedWallets: "Recommended wallets are Metamask and TrustWallet.",
                        PayWith: "Bezahlen mit",
                        PayByOtherWallet: "Mit anderem Wallet bezahlen",
                        Referral: {
                            Title: "Geld verdienen mit",
                            Title2: "Empfehlung",
                            Desc: "Lade deine Freunde und Familie ein und erhalte einen Bonus.",
                            Copied: "Link kopiert",
                        },
                        KYC: {
                            Title: "Identitätsüberprüfung - KYC",
                            Desc: "Um den Vorschriften zu entsprechen, musst du eine Identitätsüberprüfung durchführen (KYC - Know Your Customer).",
                            Desc2: "Du hast deine KYC-Anfrage zur Überprüfung deiner Identität noch nicht eingereicht.",
                            Proceed: "Identität überprüfen",
                            Status: {
                                Title: "Status",
                                NotSub: "Nicht begonnen"
                            },
                            Submitted: "Du hast deine Identitätsunterlagen eingereicht. Sie werden derzeit von unserem Team überprüft.",
                            Rejected: "Einige Überprüfungsdokumente wurden von unserem Team abgelehnt. Bitte kontaktiere uns über den Chat unten rechts auf dem Bildschirm."
                        },
                    },
                    MyToken: {
                        Title: "Meine MLC-Token",
                        BuyMore: "MLC kaufen",
                        Total: "Gesamtmenge",
                        PurchaseToken: "Gekaufte Token",
                        ReferralToken: "Empfehlungs-Token",
                        BonusesToken: "Bonus-Token",
                        TotalContributed: "Gesamtbeitrag"
                    },
                    Balance: {
                        TokensLockedVesting: "Tokens remaining locked in vesting",
                        TokensBoughtPresale: "Total tokens bought in presale",
                        DistributionCalendar: "View Distribution Calendar",
                        DistributionCalendarModal: {
                            Title: 'Release Calendar',
                            Month: 'Month',
                            MLC_Released: '$MLC Released',
                            ClaimAvailableMessage: 'Claim will be available on 25th June',
                        },
                        ClaimModal: {
                            Title: 'Claim',
                            ClaimAvailableMessage: 'Claim will be available on 25th June',
                        },
                    },
                    Buy: {
                        Title: "My Lovely Coin kaufen - $MLC",
                        MaxLimit: "You have already reached the maximum token purchase limit for this sale.",
                        Password: "Aktualisiere dein Passwort",
                        Buy: "MLC kaufen",
                        Join: "Trete dem größten Spiel zum Schutz des Klimas bei",
                        Secure: "Sichere Zahlung dank",
                        With: "Zahlung per Karte angeboten von",
                        AlreadyCollected: "Bereits gesammelt",
                        usersRegistered: "Anzahl registrierter Investoren",
                        PleaseBeforeStarting: "Bevor du beginnst",
                        ForbiddenCountries: "Du kannst kein $MLC kaufen",
                        IsMajor: "Du bist 18 Jahre oder älter",
                        SelectYourCountry: "Wähle dein Land",
                        YouAccept: "Du akzeptierst",
                        TAC: "die Allgemeinen Geschäftsbedingungen",
                        KYC: "Ich verstehe, dass ich meine Identität überprüfen muss, um meine My Lovely Coin zu erhalten.",
                        Start: "Starten",
                        Cancel: "Abbrechen",
                        OK: "OK",
                        PasswordEmailSent: "Ein Konto mit dieser E-Mail existiert bereits. Du hast wahrscheinlich bereits das Spiel MyLovelyPlanet gespielt. Aus Sicherheitsgründen klicke hier, um dein Passwort zurückzusetzen und auf die Website zuzugreifen (das ändert auch dein Passwort für das Spiel).",
                        Step1: "Schritt 1 - Zahlungsmethode",
                        Step2: "Schritt 2 - Betrag auswählen",
                        Payment: "Betrag",
                        YourAmount: "Dein Betrag",
                        WrongBalance: "Mindestens 200 €.",
                        MaxCryptoBalance: "und maximal: ",
                        ChooseVestingBonus: "Wähle deinen Bonus",
                        LearnMore: "Erfahre mehr",
                        YouReceive: "Du erhältst",
                        Purchased: "gekauft",
                        Bonus: "$MLC Bonus",
                        Referral: "$MLC Empfehlung",
                        BuyNow: "Jetzt kaufen",
                        TransferAmount: "Überweisungsbetrag",
                        PaymentAmount: "Zahlungsbetrag",
                        TransferIndications: "Überweisungsanweisungen",
                        OtherWalletIndications: "Senden Sie Ihre Kryptowährungen an die folgende Adresse",
                        TransferIndicationsDetails: "Geben Sie die folgende Referenz in Ihrer Überweisung an",
                        YourPublicWalletAddress: "Geben Sie die öffentliche Wallet-Adresse ein",
                        AlreadyUsed: "Diese Adresse wird bereits für ein anderes Konto verwendet. Bitte überprüfen Sie Ihre Eingabe",
                        Iban: "IBAN",
                        Bic: "BIC",
                        TransferConfirmation: "Ich bestätige, die Überweisung getätigt zu haben",
                        EtherscanFeedback: "Verfolgen Sie Ihre Transaktion hier",
                        TY: "Vielen Dank",
                        ShowTransaction: "Sie können den Status Ihrer Transaktion im Transaktionsbereich einsehen. In der Regel dauert es 3 bis 5 Tage, um Ihre Banküberweisung zu erhalten und zu bestätigen, und etwa 20 Minuten, um Ihre Kryptozahlung zu bestätigen.",
                        ShowTransactionByCard: "Sie können Ihre Transaktion im Tab Transaktionen einsehen.",
                        ViewTransaction: "Transaktion anzeigen",
                        ChoosePaymentMethod: "Wählen Sie Ihre Zahlungsmethode",
                        ProcessCrypto: "Verlassen Sie die Seite während des Vorgangs nicht",
                        ShowCryptoTransaction: "Transaktion hier anzeigen",
                        CryptoRejected: "Die Transaktion wurde abgelehnt oder abgebrochen",
                        InsufficientBalance: "Unzureichendes Guthaben",
                        CoinGeckoError: "CoinGecko antwortet nicht. Bitte versuchen Sie es in einigen Augenblicken erneut",
                        YourCountryIsNotAuthorized: "Ihr Land ist nicht in der autorisierten Liste enthalten",
                        AcceptConditions: "Bitte akzeptieren Sie die Bedingungen",
                        FalseAmount: "Der Betrag muss zwischen 200 und 5000 € liegen",
                        CoingeckoError: "Die CoinGecko-API ist derzeit nicht verfügbar. Wählen Sie eine andere Zahlungsmethode oder warten Sie einige Minuten, bevor Sie es erneut versuchen. Klicken Sie hier, um den Status von CoinGecko zu überprüfen",
                        VerifyEmail: "Bitte überprüfen Sie die eingegebene E-Mail-Adresse sorgfältig. Diese E-Mail-Adresse dient zur Authentifizierung des Zahlungseingangs.",
                        TransactionHash: "Geben Sie die Transaktions-ID ein",
                        Warning: "ACHTUNG: OHNE TRANSAKTIONS-HASH WIRD DIE ÜBERPRÜFUNG IHRER ZAHLUNG BEEINTRÄCHTIGT UND KANN MEHRERE TAGE DAUERN...",
                        ValidateWarning: "Ich bestätige die Transaktions-ID",
                        SavedWithSuccess: "Erfolgreich gespeichert",
                        Error: "Nicht gespeichert",
                        WarningCrypto: "Sie müssen Ihre Kryptowährungen im ETH Mainnet, BNB Chain Mainnet oder Polygon Mainnet senden.",
                        SaleIsOver: "Presale is over. Join Discord to support the project.",
                        BuyDisableMessage: "Wallet features are disabled on mobile and tablet",
                        BuyModal: {
                            Title: 'Buy',
                            BuyAvailableMessage: 'TODO Message Buy Not Available',
                        },
                    },
                    Press: {
                        Title: "My Lovely Planet in der Presse",
                    },
                    Transactions: {
                        Title: 'Transaktionen',
                        Table: {
                            ID: 'ID',
                            Tokens: 'Tokens',
                            Paid: 'Bezahlt',
                            Type: 'Typ',
                            Status: 'Status',
                            Search: 'Suche',
                            ConfirmingByCrypto: 'Warte auf Bestätigung',
                            ConfirmingByEuro: 'Warte auf Bestätigung',
                            Validated: 'Bestätigt',
                            Pending: 'Ausstehend',
                            Cancelled: 'Abgebrochen',
                            RejectedByUser: 'Abgelehnt',
                            Purchase: 'Kauf',
                            Bonus: 'Bonus',
                            Referral: 'Empfehlung',
                        }
                    },
                    VestingBonus: {
                        Title: "Vesting-Bonus",
                        Subtitle: "Was ist die Vesting-Periode?",
                        Means: " Die Vesting-Periode wird wie folgt definiert :",
                        FirstLi: "- 5% Ihrer $MLC werden beim Listing freigegeben (nur für Investoren, die vor dem Listing Tokens besitzen)",
                        SecondLi: "- 3 Monate Sperrfrist (kein Erhalt von Tokens während 3 Monaten)",
                        ThirdLi: "- Der Rest der Tokens wird jeden Tag während der Vesting-Periode freigegeben (Wenn Sie 2 Jahre wählen, erhalten Sie jeden Tag für 2 Jahre $MLC Tokens)",
                        Example: "Beispiel",
                        Example1: "Sie entscheiden sich, 1000€ mit einer Vesting-Periode von 2 Jahren in der Runde 1 (zu einem Preis von 0,061€) zu investieren. Sie erhalten 18032 Tokens (16393 $MLC + 1639 $MLC Bonus).",
                        Example2: "Sie erhalten am Tag des Listings 5% Ihrer Tokens, das sind 901,6 Tokens.",
                        Example3: "In den nächsten 3 Monaten, während der Sperrfrist, erhalten Sie keine Tokens.",
                        Example4: "Danach, erhalten Sie 26,8 Tokens pro Tag für 639 Tage, was 21 Monaten entspricht (24 Monate Vesting - 3 Monate Sperrfrist).",
                        Year1: "1 Jahr",
                        Year2: "1,5 Jahre",
                        Year3: "2 Jahre",
                        Year4: "2,5 Jahre",
                        Year5: "3 Jahre"
                    },
                    StepsToDo: {
                        KYC: {
                            title: 'KYC',
                            description: 'Confirm your identity',
                            action: 'here'
                        },
                        LinkWallet: {
                            title: 'Link wallet',
                            description: 'Connect your wallet to your account',
                            action: 'here',
                        },
                        Claim: {
                            title: 'Claim',
                            description: 'Start to claim your tokens',
                            warn: 'Available in june',
                        },
                    },
                },
            },
            ES: {
                translation: {
                    General: {
                        Edit: "Editar",
                        SelectLanguage: "Seleccionar idioma",
                        SelectCountry: "Seleccionar tu país",
                        Claim: "Reclamar",
                        PurchasedToken: "Token comprado",
                        ReferralToken: "Token de referencia",
                        BonusToken: "Token de bonificación",
                        Days: "Días",
                        Hours: "Horas",
                        Min: "Min",
                        Sec: "Seg",
                        SelectYourLanguage: "Seleccionar tu idioma",
                        DaysLeft: "Te quedan ",
                        DaysLeftAfter: " para enviar los documentos de identidad",
                        Years: "Años",
                        Year: "Año",
                        Vesting: "Vesting",
                        YourProfile: "Tu perfil",
                        Logout: "Cerrar sesión",
                    },
                    CurrentRound: {
                        Current: "Ronda actual",
                        Title: "Ronda actual 10: 50% del precio de lanzamiento",
                        LaunchPrice: "Precio de lanzamiento",
                        Mlcremaining: "Remaining Token",
                        End: "Fin de la ronda antes del aumento de precio",
                        Start: 'Time before presale starts',
                    },
                    Header: {
                        Dashboard: "Panel de control",
                        Buy: "Comprar tokens",
                        ICO: "Distribución ICO",
                        YourProfile: "Tu perfil",
                        Logout: "Cerrar sesión",
                        Transactions: "Transacciones",
                        MyToken: "Mi token MLC",
                        MainWebSite: "Sitio web principal",
                        Referral: "Referencia",
                        SuperAdmin: "Super Administrador",
                        Contact: "Contacto",
                    },
                    Referral: {
                        Title: "Patrocinio",
                        AlertDesc: "Descubre el programa de referencias",
                        ReadMore: "Leer más",
                        ShareOnLinkedin: "Compartir en Linkedin",
                        ShareOnTwitter: "Compartir en Twitter",
                        ReferralList: "Lista de referencias",
                        BonusDetails: "Detalles de bonificación por referencias",
                        BonusReferral: "% de bonificación",
                        NbReferral: "Número de referencias",
                        BonusRegister: "Bono de registro",
                        BonusTotal: "Bonificación total",
                        YouHaveBeenInvitedBy: "Has sido invitado por ",
                        YouWillReceive: "Recibirás un bono del 10% en tu primera compra",
                        Table: {
                            Name: "Nombre de usuario",
                            EarnedToken: "Token ganado",
                            RegisterDate: "Fecha de registro",
                            Status: "Estado",
                            Cancelled: "Cancelado",
                            NbTransactions: "Núm. de transacciones"
                        },
                        SlideOver: {
                            Title: "Programa de referencias",
                            Descr: "Por cada inversor invitado, recibes un porcentaje de todas sus primeras transacciones en forma de tokens de bonificación. El bono se calcula a partir de las transacciones confirmadas de los usuarios verificados.",
                            BaseLine: "Más referencias = más bonificaciones",
                            Referrals: "Por cada referencia",
                            moreThan100: "100 y +"
                        },
                    },
                    Dashboard: {
                        Title: 'Panel de control',
                        TokensBalance: 'Saldo de tokens',
                        Equivalent: 'Equivalente a',
                        TokenName: "Nombre del token",
                        TicketSymbol: 'Símbolo del token',
                        WhitePaper: "WhitePaper",
                        TokenCalculation: "Cálculo de tokens",
                        TokenCalculationDesc: "Ingrese la cantidad para calcular los tokens.",
                        TokenCalculationDesc2: "* La cantidad se calcula en función del precio actual de los tokens",
                        BuyTokens: "Comprar tokens",
                        StepsToDo: 'Steps To Do',
                        Feeds: {
                            Title: "Noticias",
                        }
                    },
                    VersionUpdate: {
                        Message: 'A new version is available, please reload the page.',
                    },
                    SessionExpired: {
                        Message: 'Your session has expired, please reload the page.',
                        Reload: 'Reload',
                    },
                    Profile: {
                        Title: "Detalles del perfil",
                        Updated: "Perfil actualizado",
                        NotUpdated: "Hay un error, por favor contacta al soporte",
                        Remember: "Recuerda agregar tu dirección de billetera antes del 30 de diciembre de 2023",
                        PersonalData: "Datos personales",
                        Settings: "Configuración",
                        Name: "Nombre",
                        Phone: "Teléfono",
                        UpdateProfile: "Actualizar perfil",
                        UpdatedProfile: "Tu perfil ha sido actualizado",
                        EnableNotifications: "Activar notificaciones",
                        SaveLogs: "Guardar registros",
                        EnableNewsletter: "Activar boletín informativo",
                        SendEmailVerification: "Correo electrónico enviado. Por favor verifica tu bandeja de entrada y la carpeta de spam",
                        Reload: 'Recargar la página después de hacer clic en el enlace del correo electrónico',
                        Password: {
                            General: "Contraseña",
                            Old: "Contraseña antigua",
                            New: "Nueva contraseña",
                            Confirm: "Confirmar nueva contraseña",
                            Update: "Actualizar contraseña",
                            Send: "Enviar correo electrónico para restablecer la contraseña",
                            Sent: "Si tu correo electrónico existe, revisa tu bandeja de entrada para restablecer la contraseña"
                        },
                        AccountStatusTitle: "Estado de tu cuenta",
                        EmailVerified: "Correo electrónico verificado",
                        EmailNotVerified: "Correo electrónico no verificado.",
                        KYCPending: "Verificación de identidad pendiente",
                        RefusedDocument: "Documento rechazado",
                        Wallet: "Billetera",
                        WalletStatusAddPolygonAddress: "Add Polygon Address",
                        WalletStatusConnected: "Wallet Connected",
                        WalletStatusToConnect: "Connect Wallet",
                        WalletStatusChangeNetwork: "Change network to Polygon",
                        WalletStatusWrongWallet: "Wrong Wallet - Switch Wallet",
                        WalletAddByWalletConnect: "Conectar con",
                        SupportedWallets: "Recommended wallets are Metamask and TrustWallet.",
                        PayWith: "Pagar con",
                        PayByOtherWallet: "Pagar con otras billeteras",
                        Referral: {
                            Title: "Gana con",
                            Title2: "referencias",
                            Desc: "Invita a tus amigos y familiares y recibe un bono.",
                            Copied: "El enlace ha sido copiado",
                        },
                    },
                    KYC: {
                        Title: "Verificación de identidad - KYC",
                        Desc: "Para cumplir con la regulación, los participantes deben pasar por un proceso de verificación de identidad.",
                        Desc2: "No has enviado tu solicitud de KYC para verificar tu identidad.",
                        Proceed: "Verificar mi identidad",
                        Status: {
                            Title: "Estado",
                            NotSub: 'No iniciado'
                        },
                        Submitted: "Has enviado tu identidad. Está siendo verificada por nuestro equipo.",
                        Rejected: "Algunos documentos de verificación han sido rechazados por nuestro equipo. Por favor, contáctanos a través del chat en la parte inferior derecha de la pantalla.",
                    },
                    MyToken: {
                        Title: "Mis Tokens MLC",
                        BuyMore: "Comprar más MLC",
                        Total: "Total",
                        PurchaseToken: "Tokens adquiridos",
                        ReferralToken: "Tokens de referencia",
                        BonusesToken: "Tokens de bonificación",
                        TotalContributed: "Contribución total"
                    },
                    Balance: {
                        TokensLockedVesting: "Tokens remaining locked in vesting",
                        TokensBoughtPresale: "Total tokens bought in presale",
                        DistributionCalendar: "View Distribution Calendar",
                        DistributionCalendarModal: {
                            Title: 'Release Calendar',
                            Month: 'Month',
                            MLC_Released: '$MLC Released',
                            ClaimAvailableMessage: 'Claim will be available on 25th June',
                        },
                        ClaimModal: {
                            Title: 'Claim',
                            ClaimAvailableMessage: 'Claim will be available on 25th June',
                        },
                    },
                    Buy: {
                        Title: "Comprar My Lovely Coin - $MLC",
                        MaxLimit: "You have already reached the maximum token purchase limit for this sale.",
                        Password: "Actualiza tu contraseña",
                        Buy: 'Comprar $MLC',
                        Join: 'Únete al mayor juego para la protección del clima',
                        Secure: "Pago seguro a través de",
                        With: "Pago con tarjeta proporcionado por",
                        AlreadyCollected: "Ya recolectados",
                        usersRegistered: 'Nb inversores',
                        PleaseBeforeStarting: "Antes de comenzar",
                        ForbiddenCountries: "No puedes comprar $MLC",
                        IsMajor: "Eres mayor de 18 años",
                        SelectYourCountry: "Selecciona tu país de residencia",
                        YouAccept: "Aceptas",
                        TAC: "los términos y condiciones de venta",
                        KYC: "Entiendo que debo verificar mi identidad para recibir mis My Lovely Coin.",
                        Start: "Comenzar",
                        Cancel: "Cancelar",
                        OK: "OK",
                        PasswordEmailSent: "Ya existe una cuenta asociada a este correo electrónico, probablemente ya hayas jugado al juego MyLovelyPlanet. Por razones de seguridad, haz clic aquí para restablecer tu contraseña y acceder al sitio (esto también cambiará tu contraseña del juego)",
                        Step1: "Paso 1: Método de pago",
                        Step2: "Paso 2: Seleccionar la cantidad",
                        Payment: "Cantidad",
                        YourAmount: "Tu cantidad",
                        WrongBalance: "Mínimo 200 €.",
                        MaxCryptoBalance: "y máximo: ",
                        ChooseVestingBonus: "Elige tu bonificación",
                        LearnMore: "Más información",
                        YouReceive: "Recibes",
                        Purchased: "comprados",
                        Bonus: "$MLC de bonificación",
                        Referral: "$MLC de referencia",
                        BuyNow: "Comprar ahora",
                        TransferAmount: "Cantidad de transferencia",
                        PaymentAmount: "Cantidad de pago",
                        TransferIndications: "Indicaciones de transferencia",
                        OtherWalletIndications: "Envía tus criptomonedas a la siguiente dirección",
                        TransferIndicationsDetails: "Indica la siguiente referencia en tu transferencia",
                        YourPublicWalletAddress: "Ingresa la dirección pública de tu billetera",
                        AlreadyUsed: "Esta dirección ya está en uso en otra cuenta. Por favor, verifica tu entrada",
                        Iban: "IBAN",
                        Bic: "BIC",
                        TransferConfirmation: "Confirmo que he realizado la transferencia",
                        EtherscanFeedback: "Sigue tu transacción aquí",
                        TY: "Gracias",
                        ShowTransaction: "Puedes ver el estado de tu transacción en la sección de transacciones. Por lo general, toma de 3 a 5 días recibir y confirmar tu transferencia bancaria y alrededor de 20 minutos para confirmar tu pago con criptomonedas.",
                        ShowTransactionByCard: "Puedes ver tu transacción en la pestaña Transacciones.",
                        ViewTransaction: "Ver transacción",
                        ChoosePaymentMethod: "Elige tu método de pago",
                        ProcessCrypto: "No cierres la página durante el proceso",
                        ShowCryptoTransaction: "Ver transacción aquí",
                        CryptoRejected: "La transacción ha sido cancelada o rechazada",
                        InsufficientBalance: "Saldo insuficiente",
                        CoingeckoError: "La API de CoinGecko no está disponible en este momento. Elige otro método de pago o espera unos minutos antes de intentarlo nuevamente. Haz clic aquí para verificar el estado de CoinGecko.",
                        VerifyEmail: "Verifica que la dirección de tu billetera sea correcta, esta dirección se utilizará para autenticar la recepción de tu pago.",
                        TransactionHash: "Ingresa el identificador de la transacción",
                        Warning: "ATENCIÓN: SIN EL IDENTIFICADOR DE TRANSACCIÓN, LA VERIFICACIÓN DE TU PAGO SE VERÁ AFECTADA Y PUEDE TOMAR VARIOS DÍAS...",
                        ValidateWarning: "Confirmo el ID de la transacción",
                        SavedWithSuccess: "Guardado exitosamente",
                        Error: "No guardado",
                        WarningCrypto: "Debes enviar tus criptomonedas a las redes ETH Mainnet, BNB Chain Mainnet o Polygon Mainnet.",
                        SaleIsOver: "Presale is over. Join Discord to support the project.",
                        BuyDisableMessage: "Wallet features are disabled on mobile and tablet",
                        BuyModal: {
                            Title: 'Buy',
                            BuyAvailableMessage: 'TODO Message Buy Not Available',
                        },
                    },
                    Press: {
                        Title: "My Lovely Planet en la prensa",
                    },
                    Transactions: {
                        Title: 'Transacciones',
                        Table: {
                            ID: 'ID',
                            Tokens: 'Tokens',
                            Paid: 'Pagado',
                            Type: 'Tipo',
                            Status: "Estado",
                            Search: 'Buscar',
                            ConfirmingByCrypto: 'Esperando confirmación',
                            ConfirmingByEuro: 'Esperando confirmación',
                            validated: "Validado",
                            pending: 'Pendiente de confirmación',
                            cancelled: "Cancelado",
                            RejectedByUser: 'Rechazado',
                            purchase: 'compra',
                            bonus: 'bonificación',
                            referral: 'referencia',
                        }
                    },
                    VestingBonus: {
                        Title: "Bono de vesting",
                        Subtitle: "¿Qué es el período de vesting?",
                        Means: " El período de vesting se define de la siguiente manera:",
                        FirstLi: "- El 5% de tus $MLC se desbloqueará al momento de la cotización (solo para los inversores que tengan tokens antes de la cotización)",
                        SecondLi: "- 3 meses de bloqueo (sin recepción de tokens durante 3 meses)",
                        ThirdLi: "- El resto de los tokens se desbloquea diariamente durante el período de vesting. (Si eliges 2 años, recibirás $MLC todos los días durante 2 años)",
                        Example: "Ejemplo",
                        Example1: "Decides invertir 1000€ con un vesting de 2 años en la ronda 1 (a un precio de 0.061€). Obtendrás 18032 tokens (16393 $MLC + 1639 $MLC de bono).",
                        Example2: "Recibirás el día de la cotización el 5% de tus tokens, es decir, 901,6 tokens.",
                        Example3: "Durante los siguientes 3 meses, no recibirás tokens debido al período de bloqueo.",
                        Example4: "Finalmente, recibirás 26,8 tokens por día durante 639 días, lo que corresponde a 21 meses (24 meses de vesting - 3 meses de bloqueo).",
                        Year1: "1 año",
                        Year2: "1.5 años",
                        Year3: "2 años",
                        Year4: "2.5 años",
                        Year5: "3 años",
                    },
                    StepsToDo: {
                        KYC: {
                            title: 'KYC',
                            description: 'Confirm your identity',
                            action: 'here'
                        },
                        LinkWallet: {
                            title: 'Link wallet',
                            description: 'Connect your wallet to your account',
                            action: 'here',
                        },
                        Claim: {
                            title: 'Claim',
                            description: 'Start to claim your tokens',
                            warn: 'Available in june',
                        },
                    },
                },
            },
        }
    });

export default i18n;