// libraries
import React, {Fragment, useContext, useEffect, useMemo, useState} from 'react';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Dialog, Menu, Transition} from '@headlessui/react'
import {useTranslation} from 'react-i18next';
import ReactFlagsSelect from "react-flags-select";
import {signOut} from "firebase/auth"
import {Bars3BottomLeftIcon, XMarkIcon,} from '@heroicons/react/24/outline'
import routes from "../route";
import {auth} from '../firebase'
import {UserContext} from "../hooks/useUser";
import { TbSmartHome, TbRefresh, TbMoneybag, TbWorld, TbHelpHexagon, TbFileText } from "react-icons/tb";
const logo = require('../assets/img/Logo-Wordmark.png');

const btnBuyLogoStyle = {
    width: "100%",
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Layout(props) {
    const {selectedLang, setSelectedLang} = props;
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const navigation = useMemo(() => [
        {
            name: 'Header.Dashboard',
            href: routes.DASHBOARD,
            current: location.pathname === '/admin/' + routes.DASHBOARD || location.pathname === '/admin',
            icon: <TbSmartHome size={25} />
        },
        {
            name: 'Header.Transactions',
            href: routes.TRANSACTIONS,
            current: location.pathname === routes.TRANSACTIONS,
            icon: <TbRefresh size={25} />
        },
        {
            name: 'Header.AirDrops',
            href: routes.AIRDROPS,
            current: location.pathname === routes.AIRDROPS,
            icon: <TbMoneybag size={25} />
        },
    ], [location.pathname]);
    let secondNavigation = [
        {name: 'Header.MainWebSite', href: routes.MAIN_WEBSITE, current: false, icon: <TbWorld size={25} />},
        {name: 'Dashboard.WhitePaper', href: routes.WHITEPAPER, current: false, icon: <TbFileText size={25} />},
        {name: 'Dashboard.Tutorial', href: routes.TUTORIAL, current: false, icon: <TbHelpHexagon size={25} />}
    ]
    const userDropDownNavigation = [
        {name: t('General.YourProfile'), href: '/admin/profile'},
    ]

    const [color, setColor] = useState(false)
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const {user, updateUser} = useContext(UserContext);

    const changeColor = () => {
        if (window.scrollY >= 90) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)

    const handleSelectLanguage = async (lang) => {
        i18n.changeLanguage(lang);
        setSelectedLang(lang)

        let updatedUser = {
            selectedLang: lang
        }

        updateUser(updatedUser)
    }

    useEffect(() => {
        i18n.changeLanguage(selectedLang)
    }, [user, i18n, navigation, selectedLang]);

    const logoutHandler = async () => {
        await signOut(auth);
        window.$crisp.push(["do", "chat:close"]);
        try {
            window.localStorage.clear()
            window.sessionStorage.clear()
            window.indexedDB.deleteDatabase('firebaseLocalStorageDb')
            const Cookies = document.cookie.split(';');
            // set past expiry to all cookies
            for (var i = 0; i < Cookies.length; i++) {
                document.cookie = Cookies[i] + "=; expires="+ new Date(0).toUTCString();
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 xl:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black-primary bg-opacity-75"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel
                                className="relative flex w-full max-w-xs flex-1 flex-col bg-primary-dark pt-5 pb-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full outline-none ring-2 ring-inset ring-white bg-primary-dark"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex flex-shrink-0 items-center px-4">
                                    <Link
                                        key="buyButtonLogo"
                                        to={routes.DASHBOARD}
                                        onClick={() => setSidebarOpen(false)}
                                        style={btnBuyLogoStyle}
                                    >
                                        <img
                                            className="h-16 w-auto mx-auto"
                                            src={logo}
                                            alt="MLC Logo"
                                        />
                                    </Link>
                                </div>
                                <div className="mt-5 flex flex-1 overflow-y-auto">
                                    <nav className="flex flex-col flex-1 pt-2 justify-between">
                                        <div>
                                            {navigation.map((item) => {
                                                    return <Link
                                                        key={item.name}
                                                        to={item.href}
                                                        onClick={() => setSidebarOpen(false)} // Ajouter cette ligne
                                                        className={classNames(
                                                            item.current ? 'w-5/6 bg-white text-primary-dark' : 'w-5/6 text-white hover:bg-slate-600',
                                                            'group flex px-2 py-2 text-xl rounded-r-xl font-passionOne py-[14px] pl-[18px] mb-[8px]'
                                                        )}
                                                    >

                                                        <span className="pr-[8px]">{item.icon}</span>
                                                        {t(item.name)}
                                                    </Link>
                                                }
                                            )}
                                        </div>
                                        <div className="mt-20">
                                            {secondNavigation.map((item) => (
                                                <a
                                                    key={item.name}
                                                    href={item.href}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    className={classNames(
                                                        item.current ? 'w-5/6 bg-white text-primary-dark' : 'w-5/6 text-white hover:bg-slate-600',
                                                        'group flex px-2 py-2 text-xl rounded-r-xl font-passionOne py-[14px] pl-[18px] mb-[8px]'
                                                    )}
                                                >
                                                    <span className="pr-[8px]">{item.icon}</span>
                                                    {t(item.name)}
                                                </a>
                                            ))}
                                        </div>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-grow flex-col overflow-y-auto bg-gradient-to-tr from-primary-dark from-60% to-gradients-customOne pt-12 border-r-2 border-white border-opacity-5">
                    <div className="flex flex-shrink-0 items-center px-4 mb-[40px]">
                        <Link
                            key="buyButtonLogo"
                            to={routes.DASHBOARD}
                            onClick={() => setSidebarOpen(false)}
                            style={btnBuyLogoStyle}
                        >
                            <img
                                className="h-16 w-auto mx-auto"
                                src={logo}
                                alt="MLC Logo"
                            />
                        </Link>
                    </div>
                    <div className="mt-5 mb-5 flex flex-1 flex-col">
                        <nav className="flex flex-col flex-1 justify-between">
                            <div>
                                {navigation.map((item) => {
                                    return <Link
                                        key={item.name}
                                        to={item.href}
                                        className={classNames(
                                            item.current ? 'w-5/6 bg-white text-primary-dark' : 'w-5/6 text-white hover:bg-slate-600',
                                            'group flex px-2 py-2 text-xl rounded-r-xl font-passionOne py-[14px] pl-[18px] mb-[8px]'
                                        )}
                                    >
                                        <span className="pr-[8px]">{item.icon}</span>
                                        {t(item.name)}
                                    </Link>
                                })}
                            </div>
                            <div className="mt-20">
                                {secondNavigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        target="_blank"
                                        rel="noreferrer"
                                        className={classNames(
                                            item.current ? 'w-5/6 bg-white text-primary-dark' : 'w-5/6 text-white hover:bg-slate-600',
                                            'group flex px-2 py-2 text-xl rounded-r-xl font-passionOne py-[14px] pl-[18px] mb-[8px]'
                                        )}
                                    >
                                        <span className="pr-[8px]">{item.icon}</span>
                                        {t(item.name)}
                                    </a>
                                ))}
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div
              className="flex flex-1 flex-col lg:pl-64 bg-gradient-to-br from-gradients-customOne to-primary-dark shadow min-h-screen">
                <div className="sticky top-0 z-10 flex h-16 flex-shrink-0">
                    <button
                      type="button"
                      className={color ? "px-4 text-gray-500 outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden backdrop-blur bg-primary-dark bg-opacity-50" : "px-4 text-gray-500 outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"}
                      onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3BottomLeftIcon className="h-6 w-6 text-primary-white" aria-hidden="true"/>
                    </button>
                    <div
                      className={`flex flex-1 px-4 sm:px-8 md:px-12 xl:px-16 justify-end lg:justify-between align-middle ${color ? "py-[31px] backdrop-blur bg-primary-dark bg-opacity-50" : "pt-0 lg:pt-8"}`}>
                        <div
                          className={`font-sofiaSans font-extralight hidden lg:block md:text-[26px] text-white justify-center align-middle whitespace-nowrap ${color ? "" : "pt-5"}`}
                          style={{lineHeight: 0}}>Hello, <b
                          className="font-extrabold font-sofiaSans">{(user?.fname || auth?.currentUser?.displayName) ?? ''}</b>!
                        </div>
                        <div className="ml-4 flex items-center md:ml-6">

                            <ReactFlagsSelect
                              selected={selectedLang}
                              countries={["US", "FR"]}
                              customLabels={{US: "EN", FR: "FR"}}
                              placeholder={t('General.SelectLanguage')}
                              onSelect={handleSelectLanguage}
                              className="mr-3 bg-transparent font-passionOne rounded-full"
                              selectButtonClassName="bg-white bg-opacity-10 border-2 border-white border-opacity-10 rounded-3xl mt-1"
                            />

                            <Menu as="div" className="relative ml-3">
                                <div>
                                    <Menu.Button
                                      className="flex max-w-xs items-center rounded-full bg-slate-600 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                        <span className="sr-only">Open user menu</span>
                                        <img
                                          className="h-10 w-10 rounded-full"
                                          src={require('../assets/img/profile.png')}
                                          alt="Profile"
                                        />
                                    </Menu.Button>
                                </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-3xl bg-primary-dark bg-opacity-60 py-1 shadow-lg flex flex-col gap-1 p-1">
                                        {userDropDownNavigation.map((item) => (
                                            <Menu.Item key={item.name}>
                                                {({active}) => (
                                                    <Link
                                                        key={item.name}
                                                        to={item.href}
                                                        className={classNames(
                                                            active ? 'font-passionOne text-xl text-white hover:bg-white hover:text-black-primary rounded-3xl' : '',
                                                            'block px-4 py-2 font-passionOne text-xl text-white hover:bg-white hover:text-black-primary rounded-3xl'
                                                        )}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        ))}
                                        <button onClick={logoutHandler}
                                                className="w-full text-left block px-4 py-2 font-passionOne text-xl text-white hover:bg-white hover:text-black-primary rounded-3xl"
                                        >
                                            {t('General.Logout')}
                                        </button>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
                <main>
                    <div className="xl:py-6">
                        <div className="mx-auto w-full px-4 sm:px-8 md:px-12 xl:px-16 pb-4 sm:pb-8 md:pb-12 xl:pb-16">
                            {/* Replace with your content */}
                            <div className="xl:py-4">
                                <Outlet/>
                            </div>
                            {/* /End replace */}
                        </div>
                    </div>
                </main>
            </div>
        </>

    )
}
