import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Tab} from "@headlessui/react";
import 'react-phone-input-2/lib/style.css';
import {useTranslation} from 'react-i18next';
import Input from "../../components/Input/Input"
import {auth, db, customAlertProps} from "../../firebase";
import Swal from 'sweetalert2'
import {sendPasswordResetEmail, updateEmail, updateProfile} from "@firebase/auth";
import axios from "axios";
import Bugsnag from "@bugsnag/js";
import Alert from "../../components/Alert/Alert";
import {extMainApiUrl} from "../../firebase";
import {UserContext} from "../../hooks/useUser";
import Button from "../../components/Button/Button";
import {stringDeleteSpace, stringReplaceDoubleSpaceBySimple} from "../../shared/utility";
import {FaRegCopy} from "react-icons/fa";

export default function Profile(props) {
    const {t} = useTranslation();
    const {user, updateUser} = useContext(UserContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState("");
    const [verifiedEmail, setVerifiedEmail] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [countdown, setCountdown] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true)
        setName(user?.fname || auth.currentUser.displayName);
        setEmail(auth.currentUser.email);
        setVerifiedEmail(user?.emailVerified);

        if (!user.emailVerified) {
            setSelectedIndex(2);
        }
        setIsLoading(false)
    }, [user, auth]);

    const startCountdown = () => {
        setCountdown(30);
        const interval = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(interval);
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    const handleSendCode = async () => {
        try {
            await axios.post(`${extMainApiUrl}/sendVerifyCode`, {
                userId: auth.currentUser.uid
            });
            setIsSent(true);
            startCountdown();
            Swal.fire({
                title: 'Good!',
                text: t('Profile.EmailSent'),
                icon: 'success',
                confirmButtonText: 'Cool',
                ...customAlertProps,
            });
        } catch (error) {
            Bugsnag.notify(error);
        }
    }

    const handleCheckCode = async () => {
        if (code && code.length === 6) {
            try {
                const response = await axios.post(`${extMainApiUrl}/checkVerifyCode`, {
                    userId: auth.currentUser.uid,
                    code: code
                });

                if (response.data === 'OK') {
                    setVerifiedEmail(true);
                    Swal.fire({
                        title: 'Good!',
                        text: "Email is verified",
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        ...customAlertProps,
                    });
                } else {
                    setVerifiedEmail(false);
                    Swal.fire({
                        title: 'Oups!',
                        text: "Code is wrong",
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        ...customAlertProps,
                    });
                }
            } catch (error) {
                // Handle error
            }
        }
    }

    const saveProfileHandler = async () => {
        try {
            // if (email !== auth.currentUser.email) {
            //     const oldEmail = auth.currentUser.email;
            //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            //     if (!emailRegex.test(email)) {
            //         Swal.fire({
            //             title: 'Oups!',
            //             text: t('Profile.EmailNotValid'),
            //             icon: 'error',
            //             confirmButtonText: 'Ok',
            //             ...customAlertProps,
            //         });
            //         return;
            //     }

            //     // // Firebase auth update
            //     // await updateEmail(auth.currentUser, email);
            //     // // Firebase datastore update
            //     // await axios.get(`${db}Users/${auth.currentUser.uid}.json?auth=${token}`).then(async res => {
            //     //     const updatedUser = {
            //     //         ...res.data,
            //     //         email,
            //     //         oldEmail,
            //     //     }
            //     //     console.error('DISABLED PUT OVERRIDE')
            //     // await axios.put(`${db}Users/${auth.currentUser.uid}.json?auth=${token}`, updatedUser).catch(err => Bugsnag.notify(err))

            //         // const updatedUserForBrevo = {
            //         //     oldEmail,
            //         //     newEmail: email,
            //         // }

            //         // await axios.post(`${extMainApiUrl}/updateUserEmailToBrevo`, updatedUserForBrevo)

            //     // }).catch((err) => {
            //     //     Bugsnag.notify(err)
            //     //     Swal.fire({
            //     //         title: 'Oups!',
            //     //         text: t('Profile.NotUpdated'),
            //     //         icon: 'error',
            //     //         confirmButtonText: 'Ok',
            //     //     ...customAlertProps,
            //     //     });
            //     //     return;
            //     // });
            // }
            if (name !== auth.currentUser.displayName) {
                // await updateProfile(auth.currentUser, {
                //     displayName: name
                // });
                let updatedUser = {
                    fname: name
                }

                const res = await updateUser(updatedUser)
                Swal.fire({
                    title: 'Good!',
                    text: t('Profile.UpdatedProfile'),
                    icon: 'success',
                    confirmButtonText: 'Cool',
                ...customAlertProps,
                });
            }
        } catch (error) {
            Bugsnag.notify(error);
            Swal.fire({
                title: 'Oups!',
                text: t('Profile.NotUpdated'),
                icon: 'error',
                confirmButtonText: 'Ok',
                    ...customAlertProps,
            });
        }
    }

    const resetPasswordHandler = async () => {
        // Handle it home made
        try {
            await axios.post(`${extMainApiUrl}/sendForgottenPassword`, {
                userId: auth.currentUser.uid
            });
            Swal.fire({
                title: 'Good!',
                text: t('Profile.EmailSent'),
                icon: 'success',
                confirmButtonText: 'Cool',
                ...customAlertProps,
            });
        } catch (error) {
            Swal.fire({
                title: 'Oups!',
                text: t('Profile.EmailNotSent'),
                icon: 'error',
                confirmButtonText: 'Ok',
                    ...customAlertProps,
            });
            Bugsnag.notify(error);
        }
        // sendPasswordResetEmail(auth, email)
        //     .then(res => {
        //         Swal.fire({
        //             title: 'Good!',
        //             text: t('Profile.Password.Sent'),
        //             icon: 'success',
        //             confirmButtonText: 'Cool',
        //             ...customAlertProps,
        //         });
        //     })
        //     .catch(err => {
        //         Bugsnag.notify(err);
        //         Swal.fire({
        //             title: 'Oups!',
        //             text: t('Profile.NotUpdated'),
        //             icon: 'error',
        //             confirmButtonText: 'Ok',
        //             ...customAlertProps,
        //         });
        //     });
    }

    if (isLoading === true) {
        return <h1 className="font-passionOne text-5xl text-white">LOADING...</h1>
    }

    const copyId = (id) => {
        navigator.clipboard.writeText(id);
        alert("ID copied!");
    };

    return (
        <div className="flex flex-col lg:flex-row gap-5 w-full">
            <div className="flex flex-col w-full rounded-3xl bg-transparent p-6">
                <div className="flex gap-5 items-center">
                    <div className="w-32">
                        <img src={require('../../assets/img/profile.png')} alt="profile" className="w-full p-2 mx-auto"/>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h1 className="font-passionOne text-xl md:text-5xl uppercase text-white">{t('Profile.Title')}</h1>
                        <p className="text-xl text-slate-300 font-passionOne" >
                            Your ID
                            <span onClick={() => copyId(auth.currentUser.uid)} className="bg-primary-white bg-opacity-20 px-3 py-1 rounded-xl ml-2 text-white font-sans text-sm cursor-pointer">
                                {auth.currentUser.uid}
                                <FaRegCopy className="inline ml-3 mb-0.5" />
                            </span>
                        </p>
                        {
                          user &&
                          user.emailVerified
                          && (
                            <div className="flex text-white font-sofiaSans uppercase text-sm px-3 py-2 rounded-full bg-primary-dark gap-2 w-fit">
                                <img src={require('../../assets/img/verified.png')} alt="verified" className="w-5"/>
                                {t('Profile.EmailVerified')}
                            </div>
                          )
                        }
                    </div>
                </div>
                <div className="mt-6">
                    <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                        <Tab.List className="flex">
                            <Tab as={Fragment}>
                                {({selected}) => (
                                  /* Use the `selected` state to conditionally style the selected tab. */
                                    <button
                                        className={
                                            selected
                                              ? 'text-primary-white bg-primary-dark bg-opacity-50 font-passionOne text-xl p-4 rounded-tl-xl w-60 border-b-2 border-white border-opacity-20'
                                              : 'text-primary-white bg-primary-dark bg-opacity-50 text-opacity-20 font-passionOne text-xl p-4 rounded-tl-xl w-60 border-b-2 border-transparent border-opacity-20'
                                        }
                                    >
                                        {t('Profile.PersonalData')}
                                    </button>
                                )}
                            </Tab>
                            <Tab as={Fragment}>
                                {({selected}) => (
                                    /* Use the `selected` state to conditionally style the selected tab. */
                                    <button
                                        className={
                                            selected
                                              ? ('text-primary-white bg-primary-dark bg-opacity-50 font-passionOne text-xl p-4 w-60 border-b-2 border-white border-opacity-20 ' + (user && user.emailVerified ? 'rounded-tr-xl' : ''))
                                              : ('text-primary-white bg-primary-dark bg-opacity-50 text-opacity-20 font-passionOne text-xl p-4 w-60 border-b-2 border-transparent border-opacity-20 ' + (user && user.emailVerified ? 'rounded-tr-xl' : ''))
                                        }
                                    >
                                        {t('Profile.Password.General')}
                                    </button>
                                )}
                            </Tab>
                        </Tab.List>
                        <Tab.Panels className="bg-primary-dark bg-opacity-50 p-5 rounded-2xl rounded-tl-none rounded-tr-none md:rounded-tr-2xl max-w-5xl">
                            <Tab.Panel>
                                <div className="flex flex-col gap-5">
                                    <div className="flex flex-col gap-5">
                                        <div className="flex flex-col w-full">
                                            <Input
                                                id="name"
                                                name="name"
                                                label={t('Profile.Name')}
                                                type="text"
                                                placeholder="John Doe"
                                                value={name}
                                                maxlength={50}
                                                changed={(event) => setName(stringReplaceDoubleSpaceBySimple(event.target.value))}
                                                onBlur={(event) => setName(event.target.value.trim())}
                                            />
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <Input
                                                id="email"
                                                name="email"
                                                label="email"
                                                type="email"
                                                placeholder="john@doe.com"
                                                disabled={true}
                                                value={email}
                                                changed={(event) => setEmail(stringDeleteSpace(event.target.value))}
                                                otherClass={'opacity-50'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-5 mt-5">
                                    <div className="flex flex-row-reverse w-full">
                                        <Button type="button" onClick={saveProfileHandler} blockclass="w-fit">
                                            {t('Profile.UpdateProfile')}
                                        </Button>
                                    </div>
                                </div>
                            </Tab.Panel>

                            <Tab.Panel>
                                <div className="flex flex-col gap-5 py-24">
                                    <div className="flex w-full justify-center">
                                        <Button type="button" onClick={resetPasswordHandler} color="secondary" blockclass="w-fil">
                                            {t('Profile.Password.Send')}
                                        </Button>
                                    </div>
                                </div>
                            </Tab.Panel>

                            <Tab.Panel>
                                <div className="flex flex-col md:flex-row gap-5">
                                    {verifiedEmail && (
                                        <Alert
                                            otherCssClass="bg-secondary-green"
                                            message={t('Profile.EmailVerified')}
                                        />
                                    )}
                                    {!verifiedEmail && (
                                        <div className="flex flex-col w-full">
                                            <Input
                                                id="code"
                                                name="code"
                                                label="Code"
                                                type="text"
                                                placeholder="951753"
                                                value={code}
                                                changed={(event) => setCode(stringDeleteSpace(event.target.value))}
                                            />
                                            {!isSent && (
                                                <div className="flex flex-row-reverse w-full mt-2">
                                                    <button
                                                        type="button"
                                                        onClick={handleSendCode}
                                                        className="text-lg font-passionOne text-center px-4 py-2 bg-primary-orange rounded-2xl text-white"
                                                    >
                                                        {t('Profile.EmailSendCode')}
                                                    </button>
                                                </div>
                                            )}
                                            {isSent && (
                                                <div className="flex flex-row-reverse w-full mt-2 gap-5">
                                                    <button
                                                        type="button"
                                                        onClick={handleCheckCode}
                                                        className="text-lg font-passionOne text-center px-4 py-2 bg-primary-orange rounded-2xl text-white"
                                                    >
                                                        {t('Profile.EmailVerify')}
                                                    </button>

                                                    <button
                                                        type="button"
                                                        onClick={handleSendCode}
                                                        disabled={countdown > 0}
                                                        className="text-lg font-passionOne text-center px-4 py-2 bg-primary-orange rounded-2xl text-white"
                                                    >
                                                        {countdown <= 0 ? t('Profile.EmailResendCode') : `${t('Profile.EmailResendCodeIn')} ${countdown} s`}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </div>
    );
}
